<template>
<a-spin :spinning="data.isTakeDetailsLoading">
    <div class="components_take_html">
        <a-table bordered :data-source="data.takeProductList" :columns="takeProductColumns" :pagination="false" rowKey="id" >
            <template #inlong="{ text }">
                <div class="long">
                    <a-tooltip :title="text">
                        {{text}}
                    </a-tooltip>
                </div>
            </template>
            <template #toTakeNum="{ text,record }">
                <span :style="(record.stockNum-record.appointmentNum)<record.takeNum?'color:#F00;':''">{{text}}</span>
            </template>
        </a-table>
    </div>
    <a-row>
        <a-col :span="6">
            <a-form-item label="客户名称" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-input v-model:value="data.modalData.customer_name" disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item label="客户手机" name="" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input v-model:value="data.modalData.customer_phone" disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item label="客户地址" name="" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input v-model:value="data.modalData.customer_address" disabled/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="6">
            <a-form-item label="货单状态" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-input v-model:value="data.modalData.take_state" disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item label="是否有效" name="" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input v-model:value="data.modalData.is_deleted" disabled/>
            </a-form-item>
        </a-col>
    </a-row>
</a-spin>
</template>
<script>
import { reactive,ref,computed,onMounted,nextTick } from 'vue';
import { takeDetails } from "@/api/components/takeDetails.js"
export default {
    name: "TakeDetails",
    props: {
        //提货单号
        takeDeliveryId:{
            type: String,
            default: ""
        },
    },
    setup(props, context){
        //提货产品列表属性
        const takeProductColumns  = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    ellipsis: true,
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 100,
                    dataIndex: 'model',
                    key: 'model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品尺寸(mm)',
                    width: 140,
                    dataIndex: 'size',
                    key: 'size',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'color',
                    key: 'color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '提货日期',
                    width: 160,
                    dataIndex: 'takeTime',
                    key: 'take_time',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '提货数量',
                    width: 90,
                    dataIndex: 'takeNum',
                    key: 'take_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'toTakeNum',
                    },
                },
                {
                    title: '当前库存',
                    dataIndex: 'stockNum',
                    key: 'stock_num',
                    width: 90,
                    ellipsis: true,
                },
                {
                    title: '当前预约',
                    dataIndex: 'appointmentNum',
                    key: 'appointment_num',
                    width: 90,
                    ellipsis: true,
                },
                {
                    title: '提货备注',
                    dataIndex: 'takeRemarks',
                    key: 'take_remarks',
                    ellipsis: true,
                    
                    slots: {
                        customRender: 'inlong',
                    },
                },
            ]
        })
        
        const data = reactive({
            isTakeDetailsLoading:false,
            takeProductList:[],
            modalData:{
                customer_name:"",
                customer_phone:"",
                customer_address:"",
                take_state:"",
                is_deleted:"",
            }
        })

        //获取数据
        const getData = ()=>{
            data.isTakeDetailsLoading = true;
            takeDetails({takeDeliveryId:props.takeDeliveryId}).then(res =>{
                data.takeProductList = res.data.takeProductList;
                data.modalData.customer_name = res.data.takeDelivery.customerName;
                data.modalData.customer_phone = res.data.takeDelivery.customerPhone;
                data.modalData.customer_address = res.data.takeDelivery.customerAddress;
                let v = "";
                switch(res.data.takeDelivery.takeState){
                    case 0: v = "未审核"; break;
                    case 1: v = "通过"; break;
                    case 2: v = "不通过"; break;
                    case 3: v = "已完成"; break;
                    default:"";      
                }
                data.modalData.take_state = v;
                data.modalData.is_deleted = res.data.takeDelivery.isDeleted?"报废":"有效";
                data.isTakeDetailsLoading = false;
            })
        }

        //渲染后 加载数据
        onMounted(() => {
            nextTick(()=>{
                getData();
            })
        })
        return {
            data,
            takeProductColumns,
            getData,
        }
    }
}
</script>
<style lang='scss' scoped>
.components_take_html{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>