/**
 * 这是下拉接口集合
 */
import service from "@/utils/request.js"
import Qs from 'qs'

//菜单上级下拉列表
export function downSuperiorPermission(data){
    return service.request({
        url:"/permission/downSuperiorPermission",
        method: "post",
        data:Qs.stringify(data),
    });
}

//角色下拉数据
export function downRoleList(data){
    return service.request({
        url:"/role/downRoleList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//产品下拉
export function downProduct(data){
    return service.request({
        url:"/product/downProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//原料下拉
export function downRawMaterial(data){
    return service.request({
        url:"/rawMaterial/downRawMaterial",
        method: "post",
        data:Qs.stringify(data),
    });
}

//用户下拉
export function downUser(data){
    return service.request({
        url:"/user/downUser",
        method: "post",
        data:Qs.stringify(data),
    });
}

//入库质检 修改计划生产数量 全部订单下拉
export function downQualityOrderAll(data){
    return service.request({
        url:"/quality/downQualityOrderAll",
        method: "post",
        data:Qs.stringify(data),
    });
}

//入库质检 订单下拉
export function downQualityOrder(data){
    return service.request({
        url:"/quality/downQualityOrder",
        method: "post",
        data:Qs.stringify(data),
    });
}

//入库质检 订单关联产品的下拉
export function downQualityOrderProduct(data){
    return service.request({
        url:"/quality/downQualityOrderProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//未执行的质检单下拉数据
export function downUnImplementQuality(data){
    return service.request({
        url:"/quality/downUnImplementQuality",
        method: "post",
        data:Qs.stringify(data),
    });
}

//出库质检 提货单下拉
export function downQualityTake(data){
    return service.request({
        url:"/quality/downQualityTake",
        method: "post",
        data:Qs.stringify(data),
    });
}

//出库质检 选择提货单后的下拉产品数据
export function downQualityTakeProduct(data){
    return service.request({
        url:"/quality/downQualityTakeProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增发货单-提货单下拉
export function downDeliveryTake(data){
    return service.request({
        url:"/takeDelivery/downDeliveryTake",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增发货单-选择提货单后获取产品列表
export function downDeliveryTakeProduct(data){
    return service.request({
        url:"/takeDelivery/downDeliveryTakeProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//退货-查询发货单产品下拉
export function downDeliveryReturnProduct(data){
    return service.request({
        url:"/delivery/downDeliveryReturnProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//质检-退货入库-获取退货单下拉
export function downDeliveryReturn(data){
    return service.request({
        url:"/deliveryReturn/downDeliveryReturn",
        method: "post",
        data:Qs.stringify(data),
    });
}

//质检-退货入库-获取对应产品的出库单下拉
export function downDeliveryIoId(data){
    return service.request({
        url:"/delivery/downDeliveryIoId",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增发货单-选择提货单后获取产品列表-再获取出库单
export function downDeliveryTakeProductOutWarehouse(data){
    return service.request({
        url:"/warehouse/downDeliveryTakeProductOutWarehouse",
        method: "post",
        data:Qs.stringify(data),
    });
}

//使用产品编号 获取还有该产品存库的入库单列表
export function downProductInWarehouse(data){
    return service.request({
        url:"/warehouse/downProductInWarehouse",
        method: "post",
        data:Qs.stringify(data),
    });
}



//菜单图标列表
export function downIconPermission(){
    return [
        {id:"chanpin",name:"产品"},
        {id:"danju",name:"单据"},
        {id:"hezuo",name:"合作"},
        {id:"index",name:"首页"},
        {id:"jilu",name:"记录"},
        {id:"neirongchaxun",name:"内容查询"},
        {id:"shenhe",name:"审核"},
        {id:"shuaxin",name:"刷新"},
        {id:"xiaoxi",name:"消息"},
        {id:"xitongshezhi",name:"系统设置"},
        {id:"zhangdan",name:"账单"},
        {id:"fangong",name:"返工"},
        {id:"yinshua",name:"印刷"},
        {id:"liucheng",name:"流程"},
        {id:"fenxiang",name:"分享"},
        {id:"bingtu",name:"饼图"},
        {id:"tuisong",name:"推送"},
        {id:"tijiao",name:"提交"},
        {id:"jifen",name:"积分"},
        {id:"daoru",name:"导入"},
        {id:"xinjian",name:"新建"},
        {id:"yinhangka",name:"银行卡"},
        {id:"renlianshibie",name:"人脸识别"},
        {id:"re",name:"热"},
        {id:"word",name:"表格"},
        {id:"zhanghuqiehuan",name:"账户切换"},
        {id:"shangchuan",name:"上传"},
        {id:"xiazai",name:"下载"},
        {id:"chakan",name:"查看"},
        {id:"anquanfengxian",name:"安全风险"},
        {id:"banben",name:"版本"},
        {id:"dayinji",name:"打印机"},
        {id:"fengxianyujing",name:"风险预警"},
        {id:"fuzhi",name:"复制"},
        {id:"gengduomoban",name:"更多模板"},
        {id:"guzhang",name:"故障"},
    ]
}