<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="用户名">
                    <a-input v-model:value="data.queryData.username" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="用户昵称">
                    <a-input v-model:value="data.queryData.nick_name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="IP">
                    <a-input v-model:value="data.queryData.ip" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="outAllExcel">导出全部</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="logSee">查看</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="outQueryExcel">导出</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
            <cTable
            ref="ctableRef"
            :toTableData="data.tableData"
            :toTableY="data.tableHeight"
            :toColumns="columns"
            :toBordered="true"
            toSize="middle"
            :toTableLoading="data.tableLoading"
            toPageStyle="float: right;"
            :toDataTotal="data.dataTotal"
            @tData="tData"
            @setSortedInfo="setSortedInfo"
            @getSelectedRowKeys="getSelectedRowKeys"
            />
        </div>
    </div>

<!-- 查看 对话框 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
  <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:17}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="用户id" name="user_id">
                <a-input v-model:value="data.modalData.user_id" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="用户名" name="username">
                <a-input v-model:value="data.modalData.username" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="用户昵称" name="nick_name">
                <a-input v-model:value="data.modalData.nick_name" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="操作者IP" name="ip">
                <a-input v-model:value="data.modalData.ip" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="24">
            <a-form-item has-feedback label="方法名称" name="method" :wrapperCol="{ style: 'width: 85.4%' }" :labelCol="{style: 'width: 12.5%'}">
                <a-textarea v-model:value="data.modalData.method" :disabled="data.modalType==='see'" />  
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="24">
            <a-form-item has-feedback label="方法参数" name="params" :wrapperCol="{ style: 'width: 85.4%' }" :labelCol="{style: 'width: 12.5%'}">
                <a-textarea v-model:value="data.modalData.params" :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="24">
            <a-form-item has-feedback label="操作内容" name="content" :wrapperCol="{ style: 'width: 85.4%' }" :labelCol="{style: 'width: 12.5%'}">
                <a-textarea v-model:value="data.modalData.content" :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="使用时间" name="time">
                <a-input v-model:value="data.modalData.time" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="创建时间" name="gmt_create">
                <a-date-picker style="width:100%" v-model:value="data.modalData.gmt_create" :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
</template>
<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { message } from 'ant-design-vue';
import { isOK } from "@/utils/illegal.js"
import { getList,details,outExcel } from "@/api/admin/log.js"
import cTable from "@/components/table"
export default {
   name: "Log",
   components: { cTable },
   setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '用户名',
                    width: 120,
                    dataIndex: 'username',
                    key: 'username',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'username' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '用户昵称',
                    width: 150,
                    dataIndex: 'nickName',
                    key: 'nick_name',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'nick_name' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '操作者IP',
                    width: 120,
                    dataIndex: 'ip',
                    key: 'ip',
                    ellipsis: true,
                },
                {
                    title: '具体操作内容',
                    width: 200,
                    dataIndex: 'content',
                    key: 'content',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '操作用时(ms)',
                    width: 150,
                    dataIndex: 'time',
                    key: 'time',
                    ellipsis: true,
                },
                {
                    title: '操作时间',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                }
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.log,
            queryData:{
                username:"",
                nick_name:"",
                ip:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            modalData:{
                user_id:"",
                username:"",
                nick_name:"",
                ip:"",
                method:"",
                params:"",
                content:"",
                time:"",
                gmt_create:"",
            },
            modalRules:{

                    
            },

        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                username: isOK(cont[0].role_id)?cont[0].role_id:"",
                nick_name: isOK(cont[0].nick_name)?cont[0].nick_name:"",
                ip: isOK(cont[0].ip)?cont[0].ip:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.username = "";
            data.queryData.nick_name = "";
            data.queryData.ip ="";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }
        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });
        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                user_id:"",
                username:"",
                nick_name:"",
                ip:"",
                method:"",
                params:"",
                content:"",
                time:"",
                gmt_create:"",
            };
        }

        //按钮-查看
        const logSee = () =>{
            data.modalTitle = "查看日志";
            data.modalType = "see";
            data.isOkButton = true;
            data.isModalLoading = true;
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isModal = true;
                    data.modalData.user_id = res.data.list.userId;
                    data.modalData.username = res.data.list.username;
                    data.modalData.nick_name = res.data.list.nickName;
                    data.modalData.ip = res.data.list.ip;
                    data.modalData.method = res.data.list.method;
                    data.modalData.params = res.data.list.params;
                    data.modalData.content = res.data.list.content;
                    data.modalData.time = res.data.list.time+"(ms)";
                    data.modalData.gmt_create = res.data.list.gmtCreate;
                    data.isModalLoading = false;
                }).catch((err) => {
                    data.isModalLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //导出全部
        const outAllExcel = ()=>{
            let actionUrl = process.env.VUE_APP_FALG + "/log/outExcel?type=1";
            if(isOK(data.queryData.username)){
                actionUrl = actionUrl + "&username="+data.queryData.username;
            }
            if(isOK(data.queryData.nick_name)){
                actionUrl = actionUrl + "&nick_name="+data.queryData.nick_name;
            }
            if(isOK(data.queryData.ip)){
                actionUrl = actionUrl + "&ip="+data.queryData.ip;
            }
            window.open(actionUrl);
        }

        //按钮-导出
        const outQueryExcel = () =>{
            if(data.selectedRowKeys.length>0){
                let actionUrl = process.env.VUE_APP_FALG + "/log/outExcel?type=2&idList="+data.selectedRowKeys.toString();
                window.open(actionUrl);
            }else{
                message.warning("请选择一条数据");
            }
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            logSee,
            outAllExcel,
            outQueryExcel,
        }
   }
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
</style>