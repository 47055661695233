import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/role/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//获取详情
export function details(data){
    return service.request({
        url:"/role/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增
export function add(data){
    return service.request({
        url:"/role/add",
        method: "post",
        data:Qs.stringify(data),
    });
}

//修改
export function update(data){
    return service.request({
        url:"/role/update",
        method: "post",
        data:Qs.stringify(data),
    });
}

//启停
export function startOrStop(data){
    return service.request({
        url:"/role/startOrStop",
        method: "post",
        data:Qs.stringify(data),
    });
}

//获取角色权限（菜单列表） 只有id
export function onePermissions(data){
    return service.request({
        url:"/role/onePermissions",
        method: "post",
        data:Qs.stringify(data),
    });
}

//获取角色权限（菜单列表） 包含菜单的其他数据
export function onePermissionsAll(data){
    return service.request({
        url:"/role/onePermissionsAll",
        method: "post",
        data:Qs.stringify(data),
    });
}

//角色分配权限
export function authorization(data){
    return service.request({
        url:"/role/authorization",
        method: "post",
        data:Qs.stringify(data),
    });
}