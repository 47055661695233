import axios from "axios";
import { message } from "ant-design-vue";
import { getToken } from "@/utils/app.js";

const service = axios.create({
    baseURL: process.env.VUE_APP_FALG,
    timeout: 60000,
});

// console.log(123123);
// console.log(process.env.VUE_APP_FALG);
// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 如果存在token 将token放入header中 传给后端，这个很重要，后台会使用这个进行获取这个用户的权限
    let token = getToken();
    if(token != null){
        config.headers.common['token'] = token;
    }
    
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const data = response.data;
    //判断接口是否正常访问 20000为正常访问 下面处理非正常响应 
    if(data.code!==20000){
        //失败
        //20002为验证码错误20003为账户密码错误 需要错误处理，所以将信息返回
        if(data.code==20002 || data.code==20003){
            return Promise.reject(data);
        }else if(data.code==30000){//30000为接口成功，处理失败
            message.warning(data.message);
            return Promise.reject(data);
        }else{
            message.error(data.message);
            return Promise.reject(data);
        }
    }else{
        //成功
        return Promise.resolve(data);
    }
}, function (error) {
    // 对响应错误做点什么
    message.error("响应错误");
    return Promise.reject(error);
});

export default service;