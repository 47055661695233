import service from "@/utils/request.js"
//登录获取token
export function login(data){
    return service.request({
        url:"/login",
        method: "post",
        data,
    });
}

//根据token获取用户信息 和 菜单权限
export function userInfoAll(data){
    return service.request({
        url:"/index/infoAll",
        method: "post",
        data,
    });
}

//退出
export function logout(data){
    return service.request({
        url:"/index/logout",
        method: "post",
        data,
    });
}

//get 
// export function User(params){
//     return service.request({
//         url:"/user",
//         method: "post",
//         params,
//     });
// }