import html2Canvas from "html2canvas";
import JsPDF from "jspdf";


export function getPdf(id, title) {
    window.pageYOffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2Canvas(document.querySelector(`#${id}`), {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        dpi: window.devicePixelRatio * 4,
        scale: 4,
      }).then(function(canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28 - 20;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = new Image();
        pageData.setAttribute("crossOrigin", "Anonymous");
        pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        // let PDF = new JsPDF("p", "mm", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 10, 10, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });

    // let w = document.documentElement.clientWidth; //获取屏幕宽度
    // let h = document.documentElement.clientHeight; //获取屏幕高度
    // let scale = 1;
    // //一页pdf显示html页面生成的canvas高度;
    // let canvas = document.createElement(id);
    // canvas.width = w * scale;
    // canvas.height = h * scale;
    // canvas.style.width = w * scale + "px";
    // canvas.style.height = h * scale + "px";
    // // canvas.getContext("2d").scale(scale, scale);
    // let opt = {
    //   scale: scale, //添加scale 参数
    //   canvas: canvas, //自定义canvas
    //   logging: false,
    //   width: w, //canvas 宽度
    //   hieght: h, //canvas 高度
    //   useCORS: true, //开启跨域
    // };
    // html2canvas(document.querySelector(`#${id}`), opt)
    //   .then((canvas) => {
    //     var pdf = new JsPDF("p", "mm", "a4"); //A4纸，纵向
    //     var ctx = canvas.getContext("2d"),
    //       a4w = 190,
    //       a4h = 274, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
    //       imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
    //       renderedHeight = 0;

    //     while (renderedHeight < canvas.height) {
    //       var page = document.createElement("canvas");
    //       page.width = canvas.width;
    //       page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页 //用getImageData剪裁指定区域，并画到前面创建的canvas对象中

    //       page
    //         .getContext("2d")
    //         .putImageData(
    //           ctx.getImageData(
    //             0,
    //             renderedHeight,
    //             canvas.width,
    //             Math.min(imgHeight, canvas.height - renderedHeight)
    //           ),
    //           0,
    //           0
    //         );
    //       pdf.addImage(
    //         page.toDataURL("image/jpeg", 1.0),
    //         "JPEG",
    //         10,
    //         10,
    //         a4w,
    //         Math.min(a4h, (a4w * page.height) / page.width)
    //       ); //添加图像到页面，保留10mm边距
    //       renderedHeight += imgHeight;
    //       if (renderedHeight < canvas.height) {
    //         pdf.addPage(); //如果后面还有内容，添加一个空页
    //       }
    //     }
    //     resolve(pdf.output("datauristring"));
    //     return pdf.output("datauristring");
    //     // document.getElementById("iframe123").src = pdf.output("datauristring");
    //   })
    //   .catch(() => {
    //     resolve(false);
    //   });
}