import service from "@/utils/request.js"
import Qs from 'qs'

//新增
export function addPermission(data){
    return service.request({
        url:"/permission/add",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function detailsPermission(data){
    return service.request({
        url:"/permission/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//修改
export function updataPermission(data){
    return service.request({
        url:"/permission/updata",
        method: "post",
        data:Qs.stringify(data),
    });
}

//删除
export function removePermission(data){
    return service.request({
        url:"/permission/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}

//全部菜单数据
export function permissionAll(data){
    return service.request({
        url:"/permission/unprocessedPermissionAll",
        method: "post",
        data:Qs.stringify(data),
    });
}

//分页查询
export function getList(data){
    return service.request({
        url:"/permission/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}