import service from "@/utils/request.js"
import Qs from 'qs'

//公共控件 获取质检单详情
export function deliveryDetails(data){
    return service.request({
        url:"/delivery/allDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}