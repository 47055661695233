const state = {
    isKey:false,//是否要进行 权限变更判断
    user:"",//用户信息
    roles:"",//角色信息
    permissionList:[],//菜单信息
    tabSelected:"",//选中的tab
    tabList:[],//全部tab列表
    btnList:{//每个模块的按钮权限
        user:{
            see:false,
            add:false,
            update:false,
            remove:false,
            assgin:false,
            start:false,
            stop:false,
            real:false,
        },
        role:{
            see:false,
            add:false,
            update:false,
            remove:false,
            authorization:false,
        },
        menu:{
            see:false,
            add:false,
            update:false,
            remove:false,
        },
        log:{
            see:false,
        },
        productManagement:{
            see:false,
            add:false,
            update:false,
            remove:false,
        },
        orderAll:{
            see:false,
            add:false,
            remove:false,
        },
        orderExecutive:{
            see:false,
            out:false,
            review:false,
        },
        orderFactory:{
            see:false,
            out:false,
            review:false,
        },
        orderChairman:{
            see:false,
            out:false,
            review:false,
        },
        ioWarehouse:{
            see:false,
            in:false,
            out:false,
            back:false,
        },
        stock:{
            see:false,
        },
        orderProduction:{
            see:false,
        },
        takeDelivery:{
            add:false,
            remove:false,
            review:false,
        },
        delivery:{
            see:false,
            add:false,
            update:false,
            return:false,
            withdrawingReturn:false,
            remove:false,
            exportExcel:false,
        },
        deliveryReturn:{
            see:false,
            remove:false,
        },
        qualityInspection:{
            see:false,
            inAdd:false,
            outAdd:false,
            remove:false,
            correct:false,
        },
        rawMaterial:{
            see:false,
            add:false,
            update:false,
            remove:false,
        },
        rawMaterialIoWarehouse:{
            see:false,
            inAndOut:false,
            back:false,
        }
    },
    isHeadBar: JSON.parse(localStorage.getItem("headBar")),
}

const mutations = {
    SET_ISKEY:(state,value)=>{
        state.isKey = value;
    },
    SET_USER:(state,value)=>{
        state.user = value;
    },
    SET_ROLES:(state,value)=>{
        state.roles = value;
    },
    SET_PERMISSIONLIST:(state,value)=>{
        state.permissionList = value;
    },
    SET_TABSELECTED:(state,value)=>{
        state.tabSelected = value;
    },
    COPE_TABLIST:(state,value)=>{
        state.tabList = value;
    },
    ADD_TABLIST:(state,value)=>{
        state.tabList.push(value);
    },
    DEL_ALL_TABLIST:(state,value)=>{
        state.tabList = [];
    },
    SET_ALL_TABLIST:(state,value)=>{
        state.tabList = value;
    },
    SET_HEADBAR:(state,value)=>{
        state.isHeadBar = value;
    },
    //重置按钮是否开启参数
    SET_BTN_RESET:(state)=>{
        state.btnList.user.see = false;
        state.btnList.user.add = false;
        state.btnList.user.update = false;
        state.btnList.user.remove = false;
        state.btnList.user.assgin = false;
        state.btnList.user.start = false;
        state.btnList.user.stop = false;
        state.btnList.user.reset = false;
        state.btnList.user.real = false;

        state.btnList.role.see = false;
        state.btnList.role.add = false;
        state.btnList.role.update = false;
        state.btnList.role.remove = false;
        state.btnList.role.authorization = false;

        state.btnList.menu.see = false;
        state.btnList.menu.add = false;
        state.btnList.menu.update = false;
        state.btnList.menu.remove = false;

        state.btnList.log.see = false;

        state.btnList.productManagement.see = false;
        state.btnList.productManagement.add = false;
        state.btnList.productManagement.update = false;
        state.btnList.productManagement.remove = false;

        state.btnList.orderAll.see = false;
        state.btnList.orderAll.add = false;
        state.btnList.orderAll.remove = false;

        state.btnList.orderExecutive.see = false;
        state.btnList.orderExecutive.out = false;
        state.btnList.orderExecutive.review = false;

        state.btnList.orderFactory.see = false;
        state.btnList.orderFactory.out = false;
        state.btnList.orderFactory.review = false;

        state.btnList.orderChairman.see = false;
        state.btnList.orderChairman.out = false;
        state.btnList.orderChairman.review = false;

        state.btnList.ioWarehouse.see = false;
        state.btnList.ioWarehouse.in = false;
        state.btnList.ioWarehouse.out = false;
        state.btnList.ioWarehouse.back = false;

        state.btnList.stock.see = false;

        state.btnList.orderProduction.see = false;

        state.btnList.takeDelivery.see = false;
        state.btnList.takeDelivery.add = false;
        state.btnList.takeDelivery.remove = false;
        state.btnList.takeDelivery.review = false;

        state.btnList.delivery.see = false;
        state.btnList.delivery.add = false;
        state.btnList.delivery.update = false;
        state.btnList.delivery.return = false;
        state.btnList.delivery.withdrawingReturn = false;
        state.btnList.delivery.remove = false;
        state.btnList.delivery.exportExcel = false;

        state.btnList.deliveryReturn.see = false;
        state.btnList.deliveryReturn.remove = false;
        
        state.btnList.qualityInspection.see = false;
        state.btnList.qualityInspection.inAdd = false;
        state.btnList.qualityInspection.outAdd = false;
        state.btnList.qualityInspection.remove = false;
        state.btnList.qualityInspection.correct = false;

        state.btnList.rawMaterial.see = false;
        state.btnList.rawMaterial.add = false;
        state.btnList.rawMaterial.update = false;
        state.btnList.rawMaterial.remove = false;

        state.btnList.rawMaterialIoWarehouse.see = false;
        state.btnList.rawMaterialIoWarehouse.inAndOut = false;
        state.btnList.rawMaterialIoWarehouse.back = false;
    },
    SET_BTN:(state,value)=>{
        //用户管理页面权限按钮开关
        state.btnList.user.see = value==="user.see"?true:state.btnList.user.see;
        state.btnList.user.add = value==="user.add"?true:state.btnList.user.add;
        state.btnList.user.update = value==="user.update"?true:state.btnList.user.update;
        state.btnList.user.remove = value==="user.remove"?true:state.btnList.user.remove;
        state.btnList.user.assgin = value==="user.assgin"?true:state.btnList.user.assgin;
        state.btnList.user.start = value==="user.start"?true:state.btnList.user.start;
        state.btnList.user.stop = value==="user.stop"?true:state.btnList.user.stop;
        state.btnList.user.reset = value==="user.reset"?true:state.btnList.user.reset;
        state.btnList.user.real = value==="user.real"?true:state.btnList.user.real;
        //角色管理页面权限按钮开关
        state.btnList.role.see = value==="role.see"?true:state.btnList.role.see;
        state.btnList.role.add = value==="role.add"?true:state.btnList.role.add;
        state.btnList.role.update = value==="role.update"?true:state.btnList.role.update;
        state.btnList.role.remove = value==="role.remove"?true:state.btnList.role.remove;
        state.btnList.role.authorization = value==="role.authorization"?true:state.btnList.role.authorization;
        //菜单管理页面权限按钮开关
        state.btnList.menu.see = value==="menu.see"?true:state.btnList.menu.see;
        state.btnList.menu.add = value==="menu.add"?true:state.btnList.menu.add;
        state.btnList.menu.update = value==="menu.update"?true:state.btnList.menu.update;
        state.btnList.menu.remove = value==="menu.remove"?true:state.btnList.menu.remove;
        //日志管理页面权限按钮开关
        state.btnList.log.see = value==="log.see"?true:state.btnList.log.see;
        //产品管理页面权限按钮开关
        state.btnList.productManagement.see = value==="product.see"?true:state.btnList.productManagement.see;
        state.btnList.productManagement.add = value==="product.add"?true:state.btnList.productManagement.add;
        state.btnList.productManagement.update = value==="product.update"?true:state.btnList.productManagement.update;
        state.btnList.productManagement.remove = value==="product.remove"?true:state.btnList.productManagement.remove;
        //订单管理页面权限按钮开关
        state.btnList.orderAll.see = value==="orderAll.see"?true:state.btnList.orderAll.see;
        state.btnList.orderAll.add = value==="orderAll.add"?true:state.btnList.orderAll.add;
        state.btnList.orderAll.remove = value==="orderAll.remove"?true:state.btnList.orderAll.remove;
        //总管审核页面权限按钮开关
        state.btnList.orderExecutive.see = value==="orderExecutive.see"?true:state.btnList.orderExecutive.see;
        state.btnList.orderExecutive.out = value==="orderExecutive.out"?true:state.btnList.orderExecutive.out;
        state.btnList.orderExecutive.review = value==="orderExecutive.review"?true:state.btnList.orderExecutive.review;
        //厂长/副厂长审核页面权限按钮开关
        state.btnList.orderFactory.see = value==="orderFactory.see"?true:state.btnList.orderFactory.see;
        state.btnList.orderFactory.out = value==="orderFactory.out"?true:state.btnList.orderFactory.out;
        state.btnList.orderFactory.review = value==="orderFactory.review"?true:state.btnList.orderFactory.review;
        //董事审核页面权限按钮开关
        state.btnList.orderChairman.see = value==="orderChairman.see"?true:state.btnList.orderChairman.see;
        state.btnList.orderChairman.out = value==="orderChairman.out"?true:state.btnList.orderChairman.out;
        state.btnList.orderChairman.review = value==="orderChairman.review"?true:state.btnList.orderChairman.review;
        //仓库-出入库页面权限按钮开关
        state.btnList.ioWarehouse.see = value==="ioWarehouse.see"?true:state.btnList.ioWarehouse.see;
        state.btnList.ioWarehouse.in = value==="ioWarehouse.in"?true:state.btnList.ioWarehouse.in;
        state.btnList.ioWarehouse.out = value==="ioWarehouse.out"?true:state.btnList.ioWarehouse.out;
        state.btnList.ioWarehouse.back = value==="ioWarehouse.back"?true:state.btnList.ioWarehouse.back;
        //仓库-库存页面权限按钮开关
        state.btnList.stock.see = value==="stock.see"?true:state.btnList.stock.see;
        //仓库-生产订单页面权限按钮开关
        state.btnList.orderProduction.see = value==="orderProduction.see"?true:state.btnList.orderProduction.see;
        //提货管理页面权限按钮开关
        state.btnList.takeDelivery.see = value==="takeDelivery.see"?true:state.btnList.takeDelivery.see;
        state.btnList.takeDelivery.add = value==="takeDelivery.add"?true:state.btnList.takeDelivery.add;
        state.btnList.takeDelivery.remove = value==="takeDelivery.remove"?true:state.btnList.takeDelivery.remove;
        state.btnList.takeDelivery.review = value==="takeDelivery.review"?true:state.btnList.takeDelivery.review;
        //发货管理页面权限按钮开关
        state.btnList.delivery.see = value==="delivery.see"?true:state.btnList.delivery.see;
        state.btnList.delivery.add = value==="delivery.add"?true:state.btnList.delivery.add;
        state.btnList.delivery.update = value==="delivery.update"?true:state.btnList.delivery.update;
        state.btnList.delivery.return = value==="delivery.return"?true:state.btnList.delivery.return;
        state.btnList.delivery.withdrawingReturn = value==="delivery.withdrawingReturn"?true:state.btnList.delivery.withdrawingReturn;
        state.btnList.delivery.remove = value==="delivery.remove"?true:state.btnList.delivery.remove;
        state.btnList.delivery.exportExcel = value==="delivery.exportExcel"?true:state.btnList.delivery.exportExcel;
        //退货管理页面权限按钮开关
        state.btnList.deliveryReturn.see = value==="deliveryReturn.see"?true:state.btnList.deliveryReturn.see;
        state.btnList.deliveryReturn.remove = value==="deliveryReturn.remove"?true:state.btnList.deliveryReturn.remove;
        //质量检测页面权限按钮开关
        state.btnList.qualityInspection.see = value==="qualityInspection.see"?true:state.btnList.qualityInspection.see;
        state.btnList.qualityInspection.inAdd = value==="qualityInspection.inAdd"?true:state.btnList.qualityInspection.inAdd;
        state.btnList.qualityInspection.outAdd = value==="qualityInspection.outAdd"?true:state.btnList.qualityInspection.outAdd;
        state.btnList.qualityInspection.remove = value==="qualityInspection.remove"?true:state.btnList.qualityInspection.remove;
        state.btnList.qualityInspection.correct = value==="qualityInspection.correct"?true:state.btnList.qualityInspection.correct;
        //原料管理原料页面权限按钮开关
        state.btnList.rawMaterial.see = value==="rawMaterial.see"?true:state.btnList.rawMaterial.see;
        state.btnList.rawMaterial.add = value==="rawMaterial.add"?true:state.btnList.rawMaterial.add;
        state.btnList.rawMaterial.update = value==="rawMaterial.update"?true:state.btnList.rawMaterial.update;
        state.btnList.rawMaterial.remove = value==="rawMaterial.remove"?true:state.btnList.rawMaterial.remove;
        //原料管理原料出入库页面权限按钮开关
        state.btnList.rawMaterialIoWarehouse.see = value==="rawMaterialIoWarehouse.see"?true:state.btnList.rawMaterialIoWarehouse.see;
        state.btnList.rawMaterialIoWarehouse.inAndOut = value==="rawMaterialIoWarehouse.inAndOut"?true:state.btnList.rawMaterialIoWarehouse.inAndOut;
        state.btnList.rawMaterialIoWarehouse.back = value==="rawMaterialIoWarehouse.back"?true:state.btnList.rawMaterialIoWarehouse.back;
    },
    //退出系统使用
    OUT_SYSTEM:(state,value)=>{
        state.isKey = false;//是否要进行 权限变更判断
        state.user = "";//用户信息
        state.roles = "";//角色信息
        state.permissionList = [];//菜单信息
        state.tabSelected = "";//选中的tab
        state.tabLis = [];//全部tab列表
        state.btnList = {//每个模块的按钮权限
            user:{
                see:false,
                add:false,
                update:false,
                remove:false,
                assgin:false,
                start:false,
                stop:false,
                real:false,
            },
            role:{
                see:false,
                add:false,
                update:false,
                remove:false,
                authorization:false,
            },
            menu:{
                see:false,
                add:false,
                update:false,
                remove:false,
            },
            log:{
                see:false,
            },
            productManagement:{
                see:false,
                add:false,
                update:false,
                remove:false,
            },
            orderAll:{
                see:false,
                add:false,
                remove:false,
            },
            orderExecutive:{
                see:false,
                out:false,
                review:false,
            },
            orderFactory:{
                see:false,
                out:false,
                review:false,
            },
            orderChairman:{
                see:false,
                out:false,
                review:false,
            },
            ioWarehouse:{
                see:false,
                in:false,
                out:false,
                back:false,
            },
            stock:{
                see:false,
            },
            orderProduction:{
                see:false,
            },
            takeDelivery:{
                add:false,
                remove:false,
                review:false,
            },
            delivery:{
                see:false,
                add:false,
                update:false,
                return:false,
                withdrawingReturn:false,
                remove:false,
                exportExcel:false,
            },
            deliveryReturn:{
                see:false,
                remove:false,
            },
            qualityInspection:{
                see:false,
                inAdd:false,
                outAdd:false,
                remove:false,
                correct:false,
            },
            rawMaterial:{
                see:false,
                add:false,
                update:false,
                remove:false,
            },
            rawMaterialIoWarehouse:{
                see:false,
                inAndOut:false,
                back:false,
            }
        };
    }
}

const actions = {

}

export default {
    namespaced: true,//用于全局引用此文件里的方法是 标识 解决命名冲突问题
    state,
    mutations,
    actions
}