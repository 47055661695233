import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/warehouse/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/warehouse/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//入库
export function inWarehouse(data){
    return service.request({
        url:"/warehouse/inWarehouse",
        method: "post",
        data:Qs.stringify(data),
    });
}

//出库
export function outWarehouse(data){
    return service.request({
        url:"/warehouse/outWarehouse",
        method: "post",
        data:Qs.stringify(data),
    });
}

//获取质检单产品列表
export function getQualityProduct(data){
    return service.request({
        url:"/quality/getQualityProduct",
        method: "post",
        data:Qs.stringify(data),
    });
}

//退回
export function back(data){
    return service.request({
        url:"/warehouse/back",
        method: "post",
        data:Qs.stringify(data),
    });
}
