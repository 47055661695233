<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="产品名称">
                    <a-input v-model:value="data.queryData.name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="stockSee">查看</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 查看对话框 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
  <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:17}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品名称" name="">
                <a-input v-model:value="data.modalData.name" defaultValue="" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="产品型号" name="">
                <a-input v-model:value="data.modalData.model" defaultValue="" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品重量" name="">
                <a-input v-model:value="data.modalData.weight" autocomplete="off" defaultValue="" suffix="kg" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="产品尺寸" name="">
                <a-input v-model:value="data.modalData.size" autocomplete="off" defaultValue="" suffix="mm" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品颜色" name="">
                <a-input style="width:100%" v-model:value="data.modalData.color" defaultValue="" allow-clear :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="计量单位" name="">
                <a-input v-model:value="data.modalData.unit" autocomplete="off" defaultValue="" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="库存数量" name="">
                <a-input style="width:100%" v-model:value="data.modalData.num" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="预约数量" name="">
                <a-input style="width:100%" v-model:value="data.modalData.appointment_num" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row >
        <a-col :span="12">
            <a-form-item has-feedback label="备注" name="">
                <a-input style="width:100%" v-model:value="data.modalData.remarks" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="创建日期" name="gmt_create">
                <a-date-picker style="width:100%" v-model:value="data.modalData.gmt_create" autocomplete="off" allow-clear :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="修改日期" name="gmt_modified">
                <a-date-picker style="width:100%" v-model:value="data.modalData.gmt_modified" autocomplete="off" allow-clear :disabled="data.modalType==='see'" />
            </a-form-item>
        </a-col>
    </a-row>
    
  </a-form>
</a-spin>
</a-modal>
</template>
<script>
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import cTable from "@/components/table"
import { getList,details } from "@/api/product/product.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
    name: "Stock",
    components: { cTable },
    setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 400,
                    dataIndex: 'name',
                    key: 'name',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'name' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '库存数量',
                    width: 160,
                    dataIndex: 'num',
                    key: 'num',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'num' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                },
                {
                    title: '已预约数量',
                    width: 160,
                    dataIndex: 'appointmentNum',
                    key: 'appointment_num',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'appointment_num' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                },
                {
                    title: '可用数量',
                    width: 160,
                    dataIndex: 'appointmentNum',
                    key: 'appointment_num',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.num - text.record.appointmentNum;
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    width: 140,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '型号',
                    width: 140,
                    dataIndex: 'model',
                    key: 'model',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'model' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '重量（千克）',
                    width: 140,
                    dataIndex: 'weight',
                    key: 'weight',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'weight' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                },
                {
                    title: '尺寸',
                    width: 140,
                    dataIndex: 'size',
                    key: 'size',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '颜色',
                    width: 140,
                    dataIndex: 'color',
                    key: 'color',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'color' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                },
                {
                    title: '计量单位',
                    dataIndex: 'unit',
                    key: 'unit',
                    width: 100,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'unit' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                },
                
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 102px)",
            btnMenu: store.state.user.btnList.stock,
            queryData:{
                name:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            modalData:{
                name:"",
                model:"",
                weight:"",
                size:"",
                color:"",
                unit:"",
                num:"",
                appointment_num:"",
                gmt_create:"",
                gmt_modified:"",
                remarks:"",
            },
            modalRules:{
                name:[{ required: true, message: '产品名称不能为空', trigger: 'blur' }],
                model:[{ required: true, message: '产品型号不能为空', trigger: 'blur' }],
                weight:[{ required: true,type:'number', message: '产品重量不能为空', trigger: 'blur' }],
                size:[{ required: true, message: '产品尺寸不能为空', trigger: 'blur' }],
                color:[{ required: true, message: '产品颜色不能为空', trigger: 'blur' }],
                unit:[{ required: true, message: '计量单位不能为空', trigger: 'blur' }],
            },
        })

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                name: isOK(cont[0].name)?cont[0].name:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.name = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                name:"",
                model:"",
                weight:"",
                size:"",
                color:"",
                unit:"",
                num:"",
                appointment_num:"",
                gmt_create:"",
                gmt_modified:"",
                remarks:"",
            };
        }

        //按钮-查看
        const modalRef = ref();
        const stockSee = ()=>{
            data.modalTitle = "查看产品";
            data.modalType = "see";
            data.isOkButton = true;
            data.isModalLoading = true;
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isModalLoading = false;
                    data.modalData.name = res.data.list.name;
                    data.modalData.model = res.data.list.model;
                    data.modalData.weight = res.data.list.weight;
                    data.modalData.size = res.data.list.size;
                    data.modalData.color = res.data.list.color;
                    data.modalData.unit = res.data.list.unit;
                    data.modalData.num = res.data.list.num;
                    data.modalData.appointment_num = res.data.list.appointmentNum;
                    data.modalData.gmt_create = res.data.list.gmtCreate;
                    data.modalData.gmt_modified = res.data.list.gmtModified;
                    data.modalData.remarks = res.data.list.remarks;
                    data.isModal = true;
                }).catch((err) => {
                    data.isModalLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查看多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //对话框-取消
        const handleCloseModal = () =>{
            resetModalData();
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,
            
            modalRef,
            stockSee,
            handleCloseModal,
        }
    }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
</style>