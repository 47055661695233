import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/order/getExecutiveList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/order/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//审核
export function executiveReview(data){
    return service.request({
        url:"/order/executiveReview",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
export function scrapExecutive(data){
    return service.request({
        url:"/order/scrapExecutive",
        method: "post",
        data:Qs.stringify(data),
    });
}