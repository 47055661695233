<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="出入库号">
                    <a-input v-model:value="data.queryData.raw_material_io_id" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="原料名称">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.raw_material_id"
                        :loading="data.isRawMaterialList"
                        placeholder=""
                        style="width:170px;"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.rawMaterialList" :key="index+1" :label="item.name">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="操作类型">
                    <a-select allow-clear
                        v-model:value="data.queryData.type"
                        placeholder=""
                        style="width:170px;"
                    >
                        <a-select-option :value="1">入库</a-select-option>
                        <a-select-option :value="2">出库</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.inAndOut" @click="rawMaterialIoWarehouseInAndOut">出入库</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.back" @click="rawMaterialIoWarehouseBack">撤回</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 出入库 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
  <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="操作类型" name="type">
                <a-select allow-clear
                        v-model:value="data.modalData.type"
                        placeholder=""
                        style="width:100%"
                    >
                        <a-select-option :value="1">入库</a-select-option>
                        <a-select-option :value="2">出库</a-select-option>
                    </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item label="原料名称" name="raw_material_id">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.modalData.raw_material_id"
                        :loading="data.isRawMaterialList"
                        placeholder=""
                        style="width:100%"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.rawMaterialList" :key="index+1" :label="item.name">{{item.name}}</a-select-option>
                    </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="数量重量" name="num">
                <a-input-number style="width:100%" v-model:value="data.modalData.num" :min="1" placeholder="单位:KG" :step="0.0001" :max="9999999"/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="备注" name="remarks">
                <a-input style="width:100%" v-model:value="data.modalData.remarks" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
</template>

<script>
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import cTable from "@/components/table"
import { getList,add,back } from "@/api/rawMaterial/rawMaterialIoWarehouse.js"
import { downRawMaterial } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
   name: "RawMaterialIoWarehouse",
   components: { cTable },
   setup(){
       const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出入库编号',
                    width: 200,
                    dataIndex: 'rawMaterialIoId',
                    key: 'raw_material_io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '原料名称',
                    width: 400,
                    dataIndex: 'rawMaterialName',
                    key: 'raw_material_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '型号',
                    width: 140,
                    dataIndex: 'model',
                    key: 'model',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '重量',
                    width: 140,
                    dataIndex: 'num',
                    key: 'num',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'num' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'rawMaterialIoWarehouseNum',
                    },
                },
                {
                    title: '计量单位',
                    width: 140,
                    dataIndex: 'unit',
                    key: 'unit',
                    ellipsis: true,
                },
                {
                    title: '创建日期',
                    width: 160,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '更新日期',
                    width: 160,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.rawMaterialIoWarehouse,
            queryData:{
                raw_material_io_id:"",
                raw_material_id:"",
                type:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,
            isRawMaterialList:false,
            rawMaterialList:[],
            isType:false,

            //以下是增删改查按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            modalData:{
                type:"",
                raw_material_id:"",
                num:"",
                remarks:"",
            },
            modalRules:{
                type:[{ required: true,type:'number', message: '请选择操作类型', trigger: 'change' }],
                raw_material_id:[{ required: true, message: '请选择原料名称', trigger: 'change' }],
                num:[{ required: true,type:'number', message: '请填写数量重量', trigger: 'blur' }],
            },
        })

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                raw_material_io_id: isOK(cont[0].raw_material_io_id)?cont[0].raw_material_io_id:"",
                raw_material_id: isOK(cont[0].raw_material_id)?cont[0].raw_material_id:"",
                type: isOK(cont[0].type)?cont[0].type:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.raw_material_io_id = "";
            data.queryData.raw_material_id = "";
            data.queryData.type = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //加载原料
        const getRawMaterial = ()=>{
            data.isRawMaterialList = true;
            downRawMaterial().then(res =>{
                data.rawMaterialList = res.data.list;
                data.isRawMaterialList = false;
            })
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);
            //加载原料
            getRawMaterial();

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                type:"",
                raw_material_id:"",
                num:"",
                remarks:"",
            };
        }

        //按钮-出入库
        const modalRef = ref();
        const rawMaterialIoWarehouseInAndOut = ()=>{
            data.modalTitle = "新增原料出入库";
            data.modalType = "add";
            resetModalData();
            getRawMaterial();
            data.isModal = true;
        }

        //对话框-确定
        const handleModal = () =>{
            modalRef.value.validate().then(() => {
                if(data.modalType === "add"){
                    data.isModalLoading = true;
                    add({
                        type:data.modalData.type,
                        rawMaterialId:data.modalData.raw_material_id,
                        num:data.modalData.num,
                        remarks:data.modalData.remarks,
                    }).then(res =>{
                        message.success("新增成功");
                        handleCloseModal();
                        resetTable();
                    }).catch((err) => {
                        data.isModalLoading = false;
                    });
                }
            })
        }

        //对话框-取消
        const handleCloseModal = () =>{
            resetModalData();
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
        }

        //按钮-撤回
        const rawMaterialIoWarehouseBack = ()=>{
            if(data.selectedRowKeys.length==1){
                    Modal.confirm({
                        title: '撤回出入库操作',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定撤回',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            back({id:data.selectedRowKeys[0]}).then(res =>{
                                message.success("撤回成功");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            getRawMaterial,

            rawMaterialIoWarehouseInAndOut,
            modalRef,
            handleModal,
            handleCloseModal,
            rawMaterialIoWarehouseBack,
        }
   }
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
</style>