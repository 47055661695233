export function getStaticRouter(){
    return [
        {
            path: '/',
            // redirect重定向
            redirect: '/login',
            hidden: true,
            meta:{
                title:"登录"
            },
        },
        // 登录 
        {
            path: "/login",
            name: "Login",
            hidden: true,
            meta: {
                title: "登录"
            },
            component: () => import("@/views/acount/Login.vue")
        },
        // {
        //     path: "/:pathMatch(.*)*",
        //     name: "404",
        //     hidden: true,
        //     meta: {
        //         title: "404"
        //     },
        //     component: () => import("@/views/error/404.vue")
        // }
    ]
}