import service from "@/utils/request.js"
import Qs from 'qs'

//图表数据
export function homeData(data){
    return service.request({
        url:"/index/homeData",
        method: "post",
        data:Qs.stringify(data),
    });
}

//获取控制台 排行榜数据 和 柱状图数据
export function homeRankingData(data){
    return service.request({
        url:"/index/homeRankingData",
        method: "post",
        data:Qs.stringify(data),
    });
}