import service from "@/utils/request.js"
import Qs from 'qs'

//修改用户信息
export function upUser(data){
    return service.request({
        url:"/user/upUser",
        method: "post",
        data:Qs.stringify(data)
    });
}

//修改密码
export function upPassword(data){
    return service.request({
        url:"/user/upPassword",
        method: "post",
        data:Qs.stringify(data)
    });
}

//修改头像
export function upAvatar(data){
    return service.request({
        url:"/user/upAvatar",
        method: "post",
        data:Qs.stringify(data)
    });
}

//删除头像
export function deleteAvatar(data){
    return service.request({
        url:"/user/deleteImgFile",
        method: "post",
        data:Qs.stringify(data)
    });
}
