<template>
    <a-spin :spinning="data.isQualityDetailsLoading">
        <!-- 入库质检单详情 -->
        <div class="qualityDetails" v-if="data.isInQualityDetails">
            <a-row>
                <a-col :span="6">
                    <a-form-item label="质检类型" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                        <span v-if="data.qualityData.type==1">生产入库质检</span>
                        <span v-if="data.qualityData.type==2">退回入库质检</span>
                    </a-form-item>
                </a-col>
                <a-col :span="18" >
                    <a-form-item label="订单号" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                        <span>{{data.orderData.orderId+' 制单人:'+data.orderData.producer+' 审核人:'+data.orderData.factoryName+' 要求:'+data.orderData.request}}</span>
                    </a-form-item>
                </a-col>
            </a-row>
            <div class="table">
                <a-table bordered :data-source="data.qualityList" :columns="inColumns" :pagination="false" rowKey="id" >
                        <template #inlong="{ text }">
                            <div class="long">
                                <a-tooltip :title="text">
                                    {{text}}
                                </a-tooltip>
                            </div>
                        </template>
                </a-table>
            </div>
        </div>
        <!-- 出库质检单详情 -->
        <div class="qualityDetails" v-if="data.isOutQualityDetails">
            <a-row>
                <a-col :span="6">
                    <a-form-item label="质检类型" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                        <span v-if="data.qualityData.type==3">出库质检</span>
                    </a-form-item>
                </a-col>
                <a-col :span="18" >
                    <a-form-item label="提货单" name="" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                        <span>{{data.takeData.takeDeliveryId+' 客户:'+data.takeData.customerName}}</span>
                    </a-form-item>
                </a-col>
            </a-row>
            <div class="table">
                <a-table bordered :data-source="data.qualityList" :columns="outColumns" :pagination="false" rowKey="id" >
                        <template #inlong="{ text }">
                            <div class="long">
                                <a-tooltip :title="text">
                                    {{text}}
                                </a-tooltip>
                            </div>
                        </template>
                </a-table>
            </div>
        </div>
    </a-spin>
</template>
<script>
import { reactive,ref,computed,onMounted,nextTick } from 'vue';
import { qualityDetails } from '@/api/components/qualityDetails.js';
export default {
    name: "QualityDetails",
    props: {
        //质检单号
        qualityId:{
            type: String,
            default: ""
        },
    },
    setup(props, context){
        //入库质检制单的产品添加的表格列属性
        const inColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 200,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 140,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '生产人员',
                    width: 140,
                    dataIndex: 'productionPersonnel',
                    key: 'production_personnel',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 120,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 140,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
            ]
        })
        //出库质检制单的产品添加的表格列属性
        const outColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '入库单',
                    width: 180,
                    dataIndex: 'ioWarehouseId',
                    key: 'io_warehouse_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品名称',
                    width: 190,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 110,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 120,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 140,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
            ]
        })

        const data = reactive({
            isQualityDetailsLoading:false,
            isInQualityDetails:false,
            isOutQualityDetails:false,
            qualityData:{
                type:"",
                order_id:"",
                take_delivery_id:"",
            },
            orderData:{},
            takeData:{},
            qualityList:[],
        })

        //获取数据
        const getData = ()=>{
            data.isQualityDetailsLoading = true;
            data.isInQualityDetails = false;
            data.isOutQualityDetails = false;
            qualityDetails({quality_id:props.qualityId}).then(res =>{
                let quality = res.data.quality;
                
                data.qualityData.type = quality.type;
                //判断质检单类型 1生产入库质检 2退回入库质检 3出库质检
                if(quality.type==1 || quality.type==2){
                    data.isInQualityDetails = true;
                    let orders = res.data.orders;
                    data.orderData = orders;
                    data.qualityList = res.data.qualityProductList;
                }
                if(res.data.quality.type==3){
                    data.isOutQualityDetails = true;
                    let takeDelivery = res.data.takeDelivery;
                    data.takeData = takeDelivery;
                    data.qualityList = res.data.qualityProductList;
                }
                data.isQualityDetailsLoading = false;
            })
        }

        //渲染后 加载数据
        onMounted(() => {
            nextTick(()=>{
                getData();
            })
        })

        return {
            data,
            getData,
            inColumns,
            outColumns,
        }
    }
}
</script>
<style lang='scss' scoped>
.qualityDetails{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.table{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>