<template>
<!-- v-if="data.refreshVue" -->
    <router-view v-slot="{ Component }" v-if="data.refreshVue">
        <!-- <transition name="slide-right" > -->
            <!-- <keep-alive> -->
                <component :is="Component"/>
            <!-- </keep-alive> -->
        <!-- </transition> -->
    </router-view>

    <!-- <transition :name="data.transitionName">
        <router-view v-if="data.refreshVue" />
    </transition> -->
</template>
<script>
import { computed, nextTick, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
export default {
    name: "Aside",
    setup(){
        const router = useRouter();
        const data = reactive({
            refreshVue: true,//用于刷新页面
            transitionName:"slide-right",
            oldPath:"",//上一个切换的页面
        });

        const refresh = () => {
            data.refreshVue = false;
            nextTick(() => {
                data.refreshVue = true;
            });
        }

        const toPath = computed(() => {
            return router.currentRoute.value.fullPath
        });

        //回退动画效果切换
        watch(toPath, (newValue, oldValue) => { //直接监听
            // if(newValue===data.oldPath){
            //     data.transitionName = 'slide-left';
            // }else{
            //     data.transitionName = 'slide-right';
            // }
            // data.oldPath = oldValue;
        });

        return {
            data,
            refresh,
            toPath,
        }
    },
}
</script>
<style lang="scss" scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.5s;
  position: absolute;
}
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-40px);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(0px);
}
.slide-left-enter-from {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>