<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="发货单号">
                    <a-input v-model:value="data.queryData.delivery_id" placeholder="" style="width:200px;" allow-clear/>
                </a-form-item>
                <a-form-item label="提货单号">
                    <a-input v-model:value="data.queryData.take_delivery_id" placeholder="" style="width:200px;" allow-clear/>
                </a-form-item>
                <a-form-item label="产品名称">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.product_id"
                        :loading="data.downProductListLoading"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downProductList" :key="index+1" :label="item.name">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="是否报废">
                    <a-select allow-clear
                        v-model:value="data.queryData.is_deleted"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <!-- <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="deliveryManagementSee">查看</a-button> -->
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="deliveryManagementAdd">制单</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.update" @click="deliveryManagementUpdate">修改</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.return" @click="deliveryManagementReturn">退货退款</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.withdrawingReturn" @click="deliveryManagementWithdrawingReturn">撤回退款</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="deliveryManagementRemove">报废</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.exportExcel" @click="deliveryManagementExportExcel" :disabled="data.isExportExcel">导出</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toTableX="true"
                :toColumns="columns"
                :toBordered="false"
                :toInTable="true"
                toInTableName="delivery"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                @tableUserDetails="tableUserDetails"
                @tableDeliveryDetails="tableDeliveryDetails"
                />
        </div>
    </div>

<!-- 制单 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
  :centered="true"
>
<a-spin :spinning="data.isModalLoading">
    <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:8}"
    :wrapperCol="{span:16}"
    >
        <a-row>
            <a-col :span="24">
                <a-form-item label="提货单号" name="takeDeliveryId" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-select
                        v-model:value="data.modalData.takeDeliveryId"
                        show-search
                        optionFilterProp="label"
                        :loading="data.isTakeList"
                        placeholder=""
                        style="width: 100%"
                        @select="getTakeSelect"
                        :disabled="data.tableDeliveryProductList.length>0"
                    >
                        <template v-for="(item,index) in data.takeList" :key="index+1">
                            <a-select-option :value="item.takeDeliveryId" :label="'提货单:'+ item.takeDeliveryId +' 客户名称:'+item.customerName+' 客户地址:'+item.customerAddress+' 客户联系:'+item.customerPhone+' 审核人:'+item.reviewName+' 制单人:'+item.producerName+' 制单时间:'+item.gmtCreate+' 备注:'+item.takeRemarks" :title="'提货单:'+ item.takeDeliveryId +' 客户名称:'+item.customerName+' 客户地址:'+item.customerAddress+' 客户联系:'+item.customerPhone+' 审核人:'+item.reviewName+' 制单人:'+item.producerName+' 制单时间:'+item.gmtCreate+' 备注:'+item.takeRemarks">
                                提货单:{{item.takeDeliveryId}} 客户名称:{{item.customerName}} 客户地址:{{item.customerAddress}} 客户联系:{{item.customerPhone}} 审核人:{{item.reviewName}} 制单人:{{item.producerName}} 制单时间:{{item.gmtCreate}} 备注:{{item.takeRemarks}}
                            </a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-form
        ref="addTableModalRef"
        :model="data.addTableModalData"
        :rules="data.addTableModalRules"
        :labelCol="{span:6}"
        :wrapperCol="{span:18}"
        >
            <a-row>
                <a-col :span="12">
                    <a-form-item label="产品名称" name="productId" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                            <a-select
                                show-search
                                optionFilterProp="label"
                                v-model:value="data.addTableModalData.productId"
                                :loading="data.addDownProductListLoading"
                                placeholder=""
                                style="width: 100%"
                                @select="getOutWarehouseSelect"
                            >
                                <template v-for="(item,index) in data.addDownProductList" :key="index+1" >
                                    <a-select-option :value="item.productId" :label="item.name">{{item.name}} 还需发货 {{item.takeNum-item.deliveryNum}} {{item.unit}}</a-select-option>
                                </template>
                            </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="出库单号" name="outId" :wrapperCol="{ style: 'width: calc(100% - 84px)' }" :labelCol="{style: 'width: 84px'}">
                        <!-- 这里的outId 是出库表id，在这个表中出入库的编号会重复 -->
                        <a-select 
                            v-model:value="data.addTableModalData.outId"
                            :loading="data.downOutWarehouseListLoading"
                            placeholder=""
                            style="width: 100%"
                            @select="getIo"
                        >
                            <a-select-option :value="item.id" v-for="(item,index) in data.downOutWarehouseList" :key="index+1">
                                出库单:{{item.ioId}} 可发货数量：{{item.num-item.useNum}}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="6">
                    <a-form-item label="发货数量" name="productNum" :wrapperCol="{ style: 'width: calc(100% - 84px)' }" :labelCol="{style: 'width: 84px'}">
                        <a-input-number style="width:100%" v-model:value="data.addTableModalData.productNum" :precision="0" :min="1" :max="9999999"/>
                    </a-form-item>
                </a-col>
                <a-col :span="16">
                    <a-form-item label="备注" :wrapperCol="{ style: 'width: calc(100% - 48px)' }" :labelCol="{style: 'width: 48px'}">
                        <a-input v-model:value="data.addTableModalData.deliveryExplain" autocomplete="off"/>
                    </a-form-item>
                </a-col>
                <a-col :span="2">
                    <a-form-item label="" name="" style="text-align: right;" :wrapperCol="{ style: 'width: calc(100% - 0px)' }" :labelCol="{style: 'width: 0px'}">
                        <a-button type="primary" ghost @click="onDeliveryAdd">添加</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <div class="addTable">
            <a-table bordered :data-source="data.tableDeliveryProductList" :columns="addDeliveryColumns" :pagination="false" rowKey="key" >
                    <template #toOperation="{ record }">
                        <a-popconfirm
                            v-if="data.tableDeliveryProductList.length"
                            title="是否确定移除?"
                            @confirm="onDeliveryDelete(record.key)"
                        >
                            <a>移除</a>
                        </a-popconfirm>
                    </template>
                    <template #inlong="{ text }">
                        <div class="long">
                            <a-tooltip :title="text">
                                {{text}}
                            </a-tooltip>
                        </div>
                    </template>
            </a-table>
        </div>
            <a-row>
                <a-col :span="24">
                    <a-form-item label="客户信息" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.customer" autocomplete="off" disabled/>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="6">
                    <a-form-item label="发货方式" name="deliveryMethod" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-select
                            v-model:value="data.modalData.deliveryMethod"
                            placeholder=""
                            style="width: 100%"
                            @select="resetMethodData"
                        >
                            <a-select-option :value="1">物流</a-select-option>
                            <a-select-option :value="2">自提</a-select-option>
                            <a-select-option :value="3">代送</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="物流单号" v-if="data.modalData.deliveryMethod==1" name="logisticsId" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.logisticsId" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                    <a-form-item label="发票单号" v-if="data.modalData.deliveryMethod!=1" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.invoice" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item has-feedback label="发货时间" name="deliveryTime">
                        <a-date-picker v-model:value="data.modalData.deliveryTime" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
                    </a-form-item>
                </a-col>
                <a-col :span="6" v-if="data.modalData.deliveryMethod==1">
                    <a-form-item label="物流名称" name="logisticsName" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.logisticsName" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row v-if="data.modalData.deliveryMethod==1">
                <a-col :span="6">
                    <a-form-item label="物流运费" name="freight" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input-number style="width:100%" v-model:value="data.modalData.freight" :min="0" :max="9999999" :step="0.1" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="车主姓名" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.logisticsOwner" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="车主手机" name="logisticsOwnerPhone" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.logisticsOwnerPhone" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="车主车牌" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.logisticsCarNumber" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="6" v-if="data.modalData.deliveryMethod==1">
                    <a-form-item label="发票单号" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.invoice" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="备注" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.modalData.remarks" autocomplete="off" allow-clear :disabled="data.modalType=='see'"/>
                    </a-form-item>
                </a-col>
            </a-row>
    </a-form>
</a-spin>
</a-modal>
<!-- 退货退款 -->
<a-modal
  title="退货退款"
  v-model:visible="data.isReturnModal"
  :confirm-loading="data.isReturnModalLoading"
  @ok="handleReturnModal"
  @cancel="handleReturnCloseModal"
  :ok-button-props="{ disabled: data.isReturnOkButton }"
  :cancel-button-props="{ disabled: data.isReturnCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="400px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isReturnModalLoading">
    <a-form
    ref="returnModalRef"
    :model="data.returnModalData"
    :rules="data.returnModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
        <a-row>
            <a-col :span="24">
                <a-form-item label="退回方式" name="isRefunds">
                    <a-select 
                        v-model:value="data.returnModalData.isRefunds"
                        placeholder=""
                        style="width: 100%"
                    >
                        <a-select-option :value="2">退款</a-select-option>
                        <a-select-option :value="3">退货</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row v-if="data.returnModalData.isRefunds==3">
            <a-col :span="24">
                <a-form-item label="产品名称" name="productId">
                    <a-select 
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.returnModalData.productId"
                        placeholder=""
                        style="width: 100%"
                        :loading="data.downReturnProductListLoading"
                        @select="getReturnProduct"
                    >
                        <a-select-option :value="item.productId" v-for="(item,index) in data.downReturnProductList" :key="index+1" :label="item.productName">{{item.productName}} 最多退回 {{item.productNum - item.returnNum}} {{item.productUnit}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row v-if="data.returnModalData.isRefunds==2">
            <a-col :span="24">
                <a-form-item label="金额" name="refundsMoney">
                    <a-input-number style="width:100%" v-model:value="data.returnModalData.refundsMoney" :min="0.1" :max="9999999" :step="0.1" allow-clear/>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row v-if="data.returnModalData.isRefunds==3">
            <a-col :span="24">
                <a-form-item label="出库单" name="ioId">
                    <a-select 
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.returnModalData.ioId"
                        placeholder=""
                        style="width: 100%"
                        :loading="data.downReturnIoIdListLoading"
                        
                    >
                        <a-select-option :value="item.ioId" v-for="(item,index) in data.downReturnIoIdList" :key="index+1" :label="item.ioId">{{item.ioId}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row v-if="data.returnModalData.isRefunds==3">
            <a-col :span="24">
                <a-form-item label="件数" name="productNum">
                    <a-input-number style="width:100%" v-model:value="data.returnModalData.productNum" :precision="0" :min="1" :max="9999999" :step="0" allow-clear/>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
</a-spin>
</a-modal>
<!-- 修改 -->
<a-modal
  title="修改"
  v-model:visible="data.isUpdateModal"
  :confirm-loading="data.isUpdateModalLoading"
  @ok="handleUpdateModal"
  @cancel="handleUpdateCloseModal"
  :ok-button-props="{ disabled: data.isUpdateOkButton }"
  :cancel-button-props="{ disabled: data.isUpdateCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="800px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isUpdateModalLoading">
    <a-form
    ref="updateModalRef"
    :model="data.updateModalData"
    :rules="data.updateModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
            <a-row>
                <a-col :span="12">
                    <a-form-item label="发货方式" name="delivery_method" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-select
                            v-model:value="data.updateModalData.delivery_method"
                            placeholder=""
                            style="width: 100%"
                            @select="resetUpdateModalData"
                        >
                            <a-select-option :value="1">物流</a-select-option>
                            <a-select-option :value="2">自提</a-select-option>
                            <a-select-option :value="3">代送</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="物流单号" v-if="data.updateModalData.delivery_method==1" name="logistics_id" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.logistics_id" autocomplete="off" allow-clear />
                    </a-form-item>
                    <a-form-item label="发票单号" v-if="data.updateModalData.delivery_method!=1" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.invoice" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row >
                <a-col :span="12">
                    <a-form-item has-feedback label="发货时间" name="delivery_time" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-date-picker style="width:100%" v-model:value="data.updateModalData.delivery_time" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
                    </a-form-item>
                </a-col>
                <a-col :span="12" v-if="data.updateModalData.delivery_method==1">
                    <a-form-item label="物流名称" name="logistics_name" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.logistics_name" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row v-if="data.updateModalData.delivery_method==1">
                <a-col :span="12">
                    <a-form-item label="物流运费" name="freight" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input-number style="width:100%" v-model:value="data.updateModalData.freight" :min="0" :max="9999999" :step="0.1" allow-clear />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="车主姓名" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.logistics_owner" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row v-if="data.updateModalData.delivery_method==1">
                <a-col :span="12">
                    <a-form-item label="车主手机" name="logistics_owner_phone" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.logistics_owner_phone" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="车主车牌" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.logistics_car_number" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12" v-if="data.updateModalData.delivery_method==1">
                    <a-form-item label="发票单号" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.invoice" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="备注" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                        <a-input v-model:value="data.updateModalData.remarks" autocomplete="off" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
    </a-form>
</a-spin>
</a-modal>

<!-- 用户详情 -->
<a-modal
  title="用户详情"
  v-model:visible="data.isUserDetails"
  @cancel="handleCloseTableUserDetails"
  :maskClosable="false"
  :confirm-loading="data.isUserDetailsLoading"
  :footer="footer"
  width="220px"
>
<a-spin :spinning="data.isUserDetailsLoading">
    <a-form
        ref=""
        :model="data.UserDetailsData"
    >
        <a-row>
            真实姓名：{{data.UserDetailsData.real_name}}
        </a-row>
        <a-row>
            用户昵称：{{data.UserDetailsData.nick_name}}
        </a-row>
        <a-row>
            联系手机：{{data.UserDetailsData.phone}}
        </a-row>
    </a-form>
</a-spin>
    <template #footer>
        <a-button key="back" @click="handleCloseTableUserDetails">取消</a-button>
    </template>
</a-modal>

<!-- 发货单追溯 -->
<a-modal
  title="发货单追溯"
  v-model:visible="data.isAllDeliveryDetails"
  @cancel="handleCloseAllDeliveryDetails"
  :maskClosable="false"
  :footer="footer"
  width="98%"
>
    
    <DeliveryDetails :deliveryId="data.deliveryIdDetails" v-if="data.isAllDeliveryDetails" ref="deliveryDetailsRef"/>

    <template #footer>
        <a-button key="back" @click="handleCloseAllDeliveryDetails">取消</a-button>
        <a-button key="pdf" type="primary" @click="handlePDF('deliveryDetailsPDF','发货单追溯')">导出PDF</a-button>
    </template>
</a-modal>
</template>
<script>
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import cTable from "@/components/table"
import DeliveryDetails from "@/components/DeliveryDetails"
import { getList,details,add,deliveryReturn,userDetails,remove,update,withdrawingReturn,deliveryExportExcel } from "@/api/delivery/deliveryManagement.js"
import { getYyyyddmm } from "@/utils/time.js"
import { downDeliveryTake,downProduct,downUser,downOrder,downOrderProduct,downDeliveryTakeProduct,downDeliveryTakeProductOutWarehouse,downDeliveryReturnProduct,downDeliveryIoId } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getPdf } from "@/utils/htmlToPdf.js"
export default {
    name: "DeliveryManagement",
    components: { cTable,DeliveryDetails },
    setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
    //             {
    //                 title: '序号',
    //                 width: 60,
    //                 align:"center",
    //                 customRender: (text) => {
    //                     return text.index + 1;
    //                 },
    //             },
                {
                    title: '发货单号',
                    width: 250,
                    dataIndex: 'deliveryId',
                    key: 'delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'deliveryDetails',
                    },
                },
                {
                    title: '提货单号',
                    width: 250,
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户名称',
                    dataIndex: 'customerName',
                    key: 'customer_name',
                    ellipsis: true,
                    width: 180,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户地址',
                    dataIndex: 'customerAddress',
                    key: 'customer_address',
                    ellipsis: true,
                    width: 180,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户联系方式',
                    dataIndex: 'customerPhone',
                    key: 'customer_phone',
                    ellipsis: true,
                    width: 120,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '发货方式',
                    width: 120,
                    dataIndex: 'deliveryMethod',
                    key: 'delivery_method',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.deliveryMethod==1?"物流":text.record.deliveryMethod==2?"自提":(text.record.deliveryMethod==3?"代送":"");
                    },
                },
                {
                    title: '制单人',
                    width: 120,
                    dataIndex: 'consignorName',
                    key: 'consignor_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'consignorDetails',
                    },
                },
                {
                    title: '物流单号',
                    dataIndex: 'logisticsId',
                    key: 'logistics_id',
                    ellipsis: true,
                    width: 140,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '物流名称',
                    dataIndex: 'logisticsName',
                    key: 'logistics_name',
                    ellipsis: true,
                    width: 120,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '运费',
                    dataIndex: 'freight',
                    key: 'freight',
                    ellipsis: true,
                    width: 120,
                    customRender: (text) => {
                        return text.record.freight!==""?text.record.freight+"元":"";
                    },
                },
                {
                    title: '退款金额',
                    dataIndex: 'refundsMoney',
                    key: 'refunds_money',
                    ellipsis: true,
                    width: 120,
                    customRender: (text) => {
                        return text.record.refundsMoney!==""?text.record.refundsMoney+"元":"";
                    },
                },
                {
                    title: '退货/退款',
                    width: 120,
                    dataIndex: 'isRefunds',
                    key: 'is_refunds',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isRefunds==1?"正常":text.record.isRefunds==2?"退款":(text.record.isRefunds==3?"退货":"");
                    },
                },
                {
                    title: '发货时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'delivery_time' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'deliveryTime',
                    key: 'delivery_time',
                    ellipsis: true,
                },
                {
                    title: '创建日期',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    width: 90,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    slots: {
                        customRender: 'isDeleted',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    width: 180,
                },
            ];
        });
        const addDeliveryColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出库单',
                    width: 110,
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '发货数量',
                    width: 120,
                    dataIndex: 'productNum',
                    key: 'product_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '备注',
                    width: 140,
                    dataIndex: 'deliveryExplain',
                    key: 'delivery_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    ellipsis: true,
                    slots: {
                        customRender: 'toOperation',
                    },
                },
            ]
        })
        const data = reactive({
            styleTableHeight:"height:calc(100% - 102px)",
            btnMenu: store.state.user.btnList.delivery,
            downProductList:[],
            addDownProductList:[],
            downUserList:[],
            downOrderList:[],
            downOrderProductList:[],
            downProductListLoading:false,
            addDownProductListLoading:false,
            downUserListLoading:false,
            downOrderListLoading:false,
            downOrderProductListLoading:false,
            downOutWarehouseList:[],
            downOutWarehouseListLoading:false,
            isTakeList:false,
            takeList:[],
            queryData:{
                take_delivery_id:"",
                order_id:"",
                product_id:"",
                io_warehouse_id:"",
                is_deleted:0,
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            isExportExcel:false,//防止用户不停的点导出，规定为10秒 等待时间

            //以下是制单按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            modalData:{
                takeDeliveryId:"",
                customer:"",
                deliveryMethod:1,
                deliveryTime:"",

                logisticsId:"",
                logisticsName:"",
                logisticsOwner:"",
                logisticsCarNumber:"",
                logisticsOwnerPhone:"",
                freight:"",
                invoice:"",
                remarks:"",
            },
            modalRules:{
                takeDeliveryId:[{ required: true, message: '请选择提货单', trigger: 'change' }],
                deliveryMethod:[{ required: true,type:'number', message: '请选择发货方式', trigger: 'change' }],
                deliveryTime:[{ required: true, message: '请选择发货时间', trigger: 'change' }],
                logisticsName:[{ required: true, message: '物流名称不能为空', trigger: 'blur' }],
                logisticsId:[{ required: true, message: '物流单号不能为空', trigger: 'blur' }],
                freight:[{ required: true,type:'number', message: '物流运费不能为空', trigger: 'blur' }],
                logisticsOwnerPhone:[
                    { required: false},
                            { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                            {
                                validator:(rule,value,callback)=>{
                                    const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                                    if(!regMobile.test(value) && value!=""){
                                        callback(new Error('手机号码不正确'))
                                    }else{
                                        callback( )
                                    }
                                },
                                trigger:'blur'
                            }
                ],
            },
            //制单里的表格
            tableDeliveryProductList:[],
            takeDataSelect:{},//选中的提货单数据
            productDataSelect:{},//选中的产品
            outDataSelect:{},//选中的出库单数据
            addTableModalData:{
                productId:"",
                outId:"",
                productNum:"",
                deliveryExplain:"",
            },
            addTableModalRules:{
                productId:[{ required: true, message: '请选择产品', trigger: 'change' }],
                outId:[{ required: true, message: '请选择出库单', trigger: 'change' }],
                productNum:[{ required: true,type:'number', message: '发货数量不能为空', trigger: 'blur' },
                {
                    validator:(rule,value,callback)=>{
                        if(value>data.maxDeliveryNum){
                            callback(new Error('最多发货 '+data.maxDeliveryNum))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
            },
            maxDeliveryNum:"",

            //以下为退回参数
            isReturnModal: false,
            isReturnModalLoading: false,
            isReturnOkButton: false,
            isReturnCancelButton: false,
            downReturnProductListLoading: false,
            downReturnIoIdListLoading:false,
            downReturnProductList:[],
            downReturnIoIdList:[],
            maxReturnProductNum:0,//记录退货的最大件数
            maxReturnProductUnit:"",//单位
            deliveryIdSelect:"",//当前选中的记录的发货单编号
            returnModalData:{
                isRefunds:2,
                productId:"",
                refundsMoney:"",
                productNum:"",
                ioId:"",
            },
            returnModalRules:{
                isRefunds:[{ required: true,type:'number', message: '请选择退回方式', trigger: 'change' }],
                productId:[{ required: true, message: '请选择产品', trigger: 'change' }],
                refundsMoney:[{ required: true,type:'number', message: '金额不能为空', trigger: 'blur' }],
                productNum:[{ required: true,type:'number', message: '件数不能为空', trigger: 'blur' },
                {
                    validator:(rule,value,callback)=>{
                        if(value>data.maxReturnProductNum){
                            callback(new Error('最多退回 '+data.maxReturnProductNum + ' '+data.maxReturnProductUnit))
                        }else if(value==0){
                            callback(new Error('不能为0'))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
                ioId:[{ required: true, message: '请选择出库单', trigger: 'change' }],
            },

            //以下为修改
            isUpdateModal:false,
            isUpdateModalLoading:false,
            isUpdateOkButton:false,
            isUpdateCancelButton:false,
            updateModalData:{
                delivery_method:"",
                logistics_id:"",
                logistics_name:"",
                logistics_owner:"",
                logistics_car_number:"",
                logistics_owner_phone:"",
                delivery_time:"",
                freight:"",
                invoice:"",
                remarks:"",
            },
            updateModalRules:{
                delivery_method:[{ required: true,type:'number', message: '请选择发货方式', trigger: 'change' }],
                delivery_time:[{ required: true, message: '请选择发货时间', trigger: 'change' }],
                logistics_name:[{ required: true, message: '物流名称不能为空', trigger: 'blur' }],
                logistics_id:[{ required: true, message: '物流单号不能为空', trigger: 'blur' }],
                freight:[{ required: true,type:'number', message: '物流运费不能为空', trigger: 'blur' }],
                logistics_owner_phone:[
                    { required: false},
                            { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                            {
                                validator:(rule,value,callback)=>{
                                    const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                                    if(!regMobile.test(value) && value!=""){
                                        callback(new Error('手机号码不正确'))
                                    }else{
                                        callback( )
                                    }
                                },
                                trigger:'blur'
                            }
                ],
            },


            //以下为用户详情
            isUserDetails:false,
            isUserDetailsLoading:false,
            UserDetailsData:{
                real_name:"",
                nick_name:"",
                phone:"",
            },

            //以下为发货单追溯
            isAllDeliveryDetails:false,
            deliveryIdDetails:"",
        })

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                delivery_id: isOK(cont[0].delivery_id)?cont[0].delivery_id:"",
                take_delivery_id: isOK(cont[0].take_delivery_id)?cont[0].take_delivery_id:"",
                product_id: isOK(cont[0].product_id)?cont[0].product_id:"",
                is_deleted: isOK(cont[0].is_deleted)?cont[0].is_deleted:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.delivery_id = "";
            data.queryData.take_delivery_id = "";
            data.queryData.product_id = "";
            data.queryData.is_deleted = 0;
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                takeDeliveryId:"",
                customer:"",
                deliveryMethod:1,
                deliveryTime:"",

                logisticsId:"",
                logisticsName:"",
                logisticsOwner:"",
                logisticsCarNumber:"",
                logisticsOwnerPhone:"",
                freight:"",
                invoice:"",
                remarks:"",
            };
        }

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //产品下拉
        const getDownProduct = ()=>{
            data.downProductListLoading = true;
            downProduct().then(res =>{
                data.downProductList = res.data.list;
                data.downProductListLoading = false;
            })
        }
        
        //获取提货单下拉数据
        const getDownTake = ()=>{
            data.isTakeList = true;
            downDeliveryTake().then(res =>{
                data.takeList = res.data.list;
                data.isTakeList = false;
            })
        }

        //获取订单下拉数据
        // const getDownOrder = ()=>{
        //     data.downOrderListLoading = true;
        //     downOrder().then(res =>{
        //         data.downOrderList = res.data.list;
        //         data.downOrderListLoading = false;
        //     })
        // }

        //制单 产品选择后获取出库单
        const getOutWarehouseSelect = (productId)=>{
            data.downOutWarehouseListLoading = true;
            downDeliveryTakeProductOutWarehouse({
                take_delivery_id:data.modalData.takeDeliveryId,
                product_id:productId,
            }).then(res =>{
                data.downOutWarehouseList = res.data.list;
                data.downOutWarehouseListLoading = false;
            })
            for(let i = 0;i<data.downProductList.length;i++){
                if(productId===data.downProductList[i].id){
                    data.productDataSelect = data.downProductList[i];
                    console.log(data.productDataSelect)
                }
            }
        }

        //制单 选择出库单后
        const getIo = (outId)=>{
            for(let i = 0;i<data.downOutWarehouseList.length;i++){
                if(data.downOutWarehouseList[i].id===outId){
                    data.outDataSelect = data.downOutWarehouseList[i];
                    data.maxDeliveryNum = data.downOutWarehouseList[i].num - data.downOutWarehouseList[i].useNum;
                    data.addTableModalData.productNum = data.maxDeliveryNum;
                }
            }
        }

        //制单对话框-选择提货单后
        const getTakeSelect = (takeDeliveryId)=>{
            data.downProductListLoading = true;
            downDeliveryTakeProduct({takeDeliveryId:takeDeliveryId}).then(res =>{
                data.addDownProductList = res.data.list;
                data.addDownProductListLoading = false;
            })
            for(let i = 0;i<data.takeList.length;i++){
                if(takeDeliveryId===data.takeList[i].takeDeliveryId){
                    data.takeDataSelect = data.takeList[i];
                    data.modalData.customer = "客户名称："+data.takeList[i].customerName + " 客户地址："+data.takeList[i].customerAddress +" 客户号码："+data.takeList[i].customerPhone
                }
            }
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);
            //加载产品下拉
            getDownProduct();
            
            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //按钮-制单
        const deliveryManagementAdd = ()=>{
            //加载提货单下拉
            getDownTake();

            data.modalTitle = "发货制单";
            data.modalType = "add";
            data.isModal = true;
        }

        //制单对话框-添加产品
        const addTableModalRef = ref();
        const onDeliveryAdd = ()=>{
            addTableModalRef.value.validate().then(() => {
                const newData = {
                    key: Date.now()+"",
                    productId: data.productDataSelect.id,
                    productName: data.productDataSelect.name,
                    outId: data.outDataSelect.id,
                    ioId: data.outDataSelect.ioId,
                    productNum: data.addTableModalData.productNum,
                    deliveryExplain:data.addTableModalData.deliveryExplain,
                };
                console.log(newData);
                for(let i = 0;i<data.tableDeliveryProductList.length;i++){
                    if(data.tableDeliveryProductList[i].outId==newData.outId){
                        return message.warning("不可重复使用出库单");
                    }
                }
                data.tableDeliveryProductList.push(newData);
                resetAddTableData();
            })
        }

        //制单对话框-删除产品
        const onDeliveryDelete = key => {
            data.tableDeliveryProductList = data.tableDeliveryProductList.filter(item => item.key !== key);
        };
        //重置添加的数据
        const resetAddTableData = ()=>{
            data.addTableModalData={
                productId:"",
                outId:"",
                productNum:"",
                deliveryExplain:"",
            }
        }

        //按钮-制单-确定
        const modalRef = ref();
        const handleModal = () =>{
            if(data.modalType==="add"){
                if(data.tableDeliveryProductList.length>0){
                    modalRef.value.validate().then(() => {
                        data.isModalLoading = true;
                        data.isOkButton = true;
                        add({
                            deliveryProductList:data.tableDeliveryProductList,
                            takeDeliveryId:data.modalData.takeDeliveryId,
                            deliveryMethod:data.modalData.deliveryMethod,
                            deliveryTime:data.modalData.deliveryTime,
                            logisticsId:data.modalData.logisticsId,
                            logisticsName:data.modalData.logisticsName,
                            logisticsOwner:data.modalData.logisticsOwner,
                            logisticsCarNumber:data.modalData.logisticsCarNumber,
                            logisticsOwnerPhone:data.modalData.logisticsOwnerPhone,
                            freight:parseFloat(data.modalData.freight).toFixed(1),
                            invoice:data.modalData.invoice,
                            remarks:data.modalData.remarks,
                        }).then(res =>{
                            message.success("制单成功");
                            handleCloseModal();
                            resetTable();
                        }).catch((err) => {
                            data.isModalLoading = false;
                            data.isOkButton = false;
                        });
                    })
                }else{
                    message.warning("请添加发货的产品");
                }
            }
        }
        //选择发货方式重置部分数据
        const resetMethodData = ()=>{
            data.modalData.logisticsId = "";
            data.modalData.logisticsName = "";
            data.modalData.logisticsOwner = "";
            data.modalData.logisticsCarNumber = "";
            data.modalData.logisticsOwnerPhone = "";
            data.modalData.freight = "";
            data.modalData.invoice = "";
            data.modalData.remarks = "";
        }
        
        
        //按钮-制单-取消
        const handleCloseModal = ()=>{
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            data.isTakeList = false;
            data.takeList = [];
            data.addDownProductListLoading = false;
            data.addDownProductList = [];
            data.downOutWarehouseListLoading = false;
            data.downOutWarehouseList = [];
            data.modalData={
                takeDeliveryId:"",
                customer:"",
                deliveryMethod:1,

                logisticsId:"",
                logisticsName:"",
                logisticsOwner:"",
                logisticsCarNumber:"",
                logisticsOwnerPhone:"",
                freight:"",
                invoice:"",
                remarks:"",
            };
            //制单里的表格
            data.tableDeliveryProductList=[];
            data.takeDataSelect={};//选中的提货单数据
            data.productDataSelect={};//选中的产品
            data.outDataSelect={};//选中的出库单数据
            data.addTableModalData={
                productId:"",
                outId:"",
                productNum:"",
                deliveryExplain:"",
            };
            data.maxDeliveryNum="";
            //重置表单校验
            modalRef.value.resetFields();
            addTableModalRef.value.resetFields();
        }

        //按钮-修改
        const deliveryManagementUpdate = ()=>{
            if(data.selectedRowKeys.length==1){
                data.isUpdateModal = true;
                data.isUpdateModalLoading = true;
                data.isUpdateOkButton = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.updateModalData={
                        id:data.selectedRowKeys[0],
                        delivery_method:res.data.list.deliveryMethod,
                        logistics_id:res.data.list.logisticsId || "",
                        logistics_name:res.data.list.logisticsName || "",
                        logistics_owner:res.data.list.logisticsOwner || "",
                        logistics_car_number:res.data.list.logisticsCarNumber || "",
                        logistics_owner_phone:res.data.list.logisticsOwnerPhone || "",
                        delivery_time:res.data.list.deliveryTime || "",
                        freight:res.data.list.freight || "",
                        invoice:res.data.list.invoice || "",
                        remarks:res.data.list.remarks || "",
                    }
                    data.isUpdateModalLoading = false;
                    data.isUpdateOkButton = false;
                })
                // .catch((err) => {
                //     data.isUpdateModalLoading = false;
                //     data.isUpdateOkButton = false;
                // });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时处理多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //重置修改参数
        const resetUpdateModalData = ()=>{
            data.updateModalData.logistics_id = "";
            data.updateModalData.logistics_name = "";
            data.updateModalData.logistics_owner = "";
            data.updateModalData.logistics_car_number = "";
            data.updateModalData.logistics_owner_phone = "";
            data.updateModalData.delivery_time = "";
            data.updateModalData.freight = "";
            data.updateModalData.invoice = "";
            data.updateModalData.remarks = "";
        }

        //按钮-修改-确定
        const updateModalRef = ref();
        const handleUpdateModal = ()=>{
            updateModalRef.value.validate().then(() => {
                update(data.updateModalData).then(res =>{
                    message.success("修改成功");
                    handleUpdateCloseModal();
                    resetTable();
                }).catch((err) => {
                    data.isUpdateModalLoading = false;
                    data.isUpdateOkButton = false;
                });
            })
        }

        //按钮-修改-取消
        const handleUpdateCloseModal = ()=>{
            data.isUpdateModal = false;
            data.isUpdateModalLoading = false;
            data.isUpdateOkButton = false;
            data.isUpdateCancelButton = false;
            data.updateModalData={
                delivery_method:"",
                logistics_id:"",
                logistics_name:"",
                logistics_owner:"",
                logistics_car_number:"",
                logistics_owner_phone:"",
                delivery_time:"",
                freight:"",
                invoice:"",
                remarks:"",
            }
        }

        //按钮-退回
        const deliveryManagementReturn = ()=>{
            if(data.selectedRowKeys.length==1){
                data.deliveryIdSelect = "";
                for(let i = 0;i<data.tableData.length;i++){
                    if(data.selectedRowKeys[0]===data.tableData[i].id){
                        data.deliveryIdSelect = data.tableData[i].deliveryId;
                    }
                }
                data.downReturnProductListLoading = true;
                downDeliveryReturnProduct({delivery_id:data.deliveryIdSelect}).then(res =>{
                    data.downReturnProductList = res.data.list;
                    data.downReturnProductListLoading = false;
                })
                data.isReturnModal = true;
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时处理多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //退货退款-选择产品后的方法
        const getReturnProduct = (product_id)=>{
            for(let i = 0;i<data.downReturnProductList.length;i++){
                if(product_id===data.downReturnProductList[i].productId){
                    data.maxReturnProductNum = data.downReturnProductList[i].productNum - data.downReturnProductList[i].returnNum;
                    data.maxReturnProductUnit = data.downReturnProductList[i].productUnit
                    data.returnModalData.productNum = data.maxReturnProductNum;
                }
            }
            data.downReturnIoIdListLoading = true;
            downDeliveryIoId({delivery_id:data.deliveryIdSelect,product_id:product_id}).then(res =>{
                data.downReturnIoIdList = res.data.list;
                data.downReturnIoIdListLoading = false;
            })
        }

        //按钮-退回-确定
        const returnModalRef = ref();
        const handleReturnModal = ()=>{
            data.isReturnModalLoading = true;
            data.isReturnOkButton = true;
            deliveryReturn({
                id:data.selectedRowKeys[0],
                isRefunds:data.returnModalData.isRefunds,
                productId:data.returnModalData.productId,
                refundsMoney:data.returnModalData.refundsMoney,
                productNum:data.returnModalData.productNum,
                ioId:data.returnModalData.ioId,
            }).then(res =>{
                message.success("退回处理成功");
                handleReturnCloseModal();
                resetTable();
            }).catch((err) => {
                data.isReturnModalLoading = false;
                data.isReturnOkButton = false;
            });
        }

        //按钮-退回-取消
        const handleReturnCloseModal = ()=>{
            data.isReturnModal = false;
            data.isReturnModalLoading = false;
            data.isReturnOkButton = false;
            data.isReturnCancelButton = false;
            data.maxReturnProductNum = 0;//记录退货的最大件数
            data.maxReturnProductUnit = "";//单位
            data.returnModalData = {
                isRefunds:2,
                productId:"",
                refundsMoney:"",
                productNum:"",
                ioId:"",
            };
            //重置表单校验
            returnModalRef.value.resetFields();
        }

        //表格-用户详情
        const tableUserDetails = (userId)=>{
            data.isUserDetailsLoading = true;
            userDetails({id:userId}).then(res =>{
                data.isUserDetailsLoading = false;
                data.UserDetailsData.real_name = res.data.list.realName;
                data.UserDetailsData.nick_name = res.data.list.nickName;
                data.UserDetailsData.phone = res.data.list.phone;

                data.isUserDetails = true;
            }).catch((err) => {
                data.isUserDetailsLoading = false;
            });
        }

        //表格-用户详情-取消
        const handleCloseTableUserDetails = ()=>{
            data.isUserDetails = false;
            data.isUserDetailsLoading = false;
            data.UserDetailsData = {
                real_name:"",
                nick_name:"",
                phone:"",
            }
        }

        //表格-发货单追溯详情
        const tableDeliveryDetails = (deliveryId)=>{
            console.log(deliveryId)
            data.deliveryIdDetails = deliveryId;
            data.isAllDeliveryDetails = true;
        }

        //表格-发货单追溯详情-取消
        const handleCloseAllDeliveryDetails = ()=>{
            data.isAllDeliveryDetails = false;
            data.deliveryIdDetails = "";
        }

        //按钮-报废
        const deliveryManagementRemove = ()=>{
            for(let i = 0;i<data.tableData.length;i++){
                if(data.tableData[i].id===data.selectedRowKeys[0]){
                    if(data.tableData[i].isDeleted || data.tableData[i].isRefunds!=1){
                        return message.warning("已报废、退货、退款的发货单不可报废");
                    }
                }
            }
            if(data.selectedRowKeys.length==1){
                Modal.confirm({
                    title: '报废发货单',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定报废发货单',
                    okText: '确认',
                    cancelText: '取消',
                    //确定
                    onOk() {
                        remove({id:data.selectedRowKeys[0]}).then(res =>{
                            message.success("报废成功");
                            resetTable();
                        })
                    },
                    // 取消
                    onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时处理多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-撤回退款
        const deliveryManagementWithdrawingReturn = ()=>{
            for(let i = 0;i<data.tableData.length;i++){
                if(data.tableData[i].id===data.selectedRowKeys[0]){
                    if(data.tableData[i].isDeleted){
                        return message.warning("已报废，不可撤回操作");
                    }
                    if(data.tableData[i].refundsMoney<=0.0){
                        return message.warning("不存在退款，无需撤回操作");
                    }
                }
            }
            if(data.selectedRowKeys.length==1){
                Modal.confirm({
                    title: '撤回退款',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定撤回退款操作',
                    okText: '确认',
                    cancelText: '取消',
                    //确定
                    onOk() {
                        withdrawingReturn({id:data.selectedRowKeys[0]}).then(res =>{
                            message.success("撤回退款成功");
                            resetTable();
                        })
                    },
                    // 取消
                    onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时处理多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //详情-导出PDF
        const deliveryDetailsRef = ref();
        const handlePDF = (id,name)=>{
            deliveryDetailsRef.value.handlePDF(id,name);
        }

        //按钮-导出
        const deliveryManagementExportExcel = ()=>{
            // deliveryExportExcel()
            // window.open("/erp/exportExcel/deliveryExportExcel", "_blank");

            if(data.selectedRowKeys.length>1){
                Modal.confirm({
                    title: '导出发货单',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定要导出操作',
                    okText: '确认',
                    cancelText: '取消',
                    //确定
                    onOk() {
                        data.isExportExcel = true;

                        let deliveryIdList = "";
                        for(let i = 0 ;i<data.selectedRowKeys.length;i++){
                            for(let ii = 0 ;ii<data.tableData.length;ii++){
                                if(data.selectedRowKeys[i]===data.tableData[ii].id){
                                    deliveryIdList = deliveryIdList + data.tableData[ii].deliveryId + ",";
                                }
                            }
                        }
                        deliveryIdList = deliveryIdList.substring(0,deliveryIdList.length-1);

                        let url = "/erp/exportExcel/deliveryExportExcel?deliveryIdList="+deliveryIdList;
                        let aLink = document.createElement("a");
                        aLink.style.display = "none";
                        aLink.href = url;
                        aLink.setAttribute("download", "excel.xls");
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        setTimeout(function() {
                            data.isExportExcel = false;
                        },10000);
                    },
                    // 取消
                    onCancel() {},
                });
            }else{
                message.warning("请选择一条数据");
            }
        }

        return {
            setSortedInfo,
            columns,
            addDeliveryColumns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            getTime,
            modalRef,

            getDownProduct,
            getDownTake,
            // getDownOrder,
            getTakeSelect,
            getOutWarehouseSelect,
            getIo,

            deliveryManagementAdd,
            deliveryManagementReturn,
            getReturnProduct,

            tableUserDetails,
            handleCloseTableUserDetails,
            tableDeliveryDetails,
            handleCloseAllDeliveryDetails,

            addTableModalRef,
            onDeliveryAdd,
            onDeliveryDelete,
            resetAddTableData,
            handleModal,
            handleCloseModal,
            resetMethodData,

            deliveryManagementUpdate,
            resetUpdateModalData,

            returnModalRef,
            handleReturnModal,
            handleReturnCloseModal,

            updateModalRef,
            handleUpdateModal,
            handleUpdateCloseModal,

            deliveryManagementRemove,

            deliveryManagementWithdrawingReturn,

            deliveryDetailsRef,
            handlePDF,
            deliveryManagementExportExcel,
        }
    }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.STYLE7 {
    font-size: 16px; 
    font-family: "宋体";
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTable{
    width: 100%;
}
.addTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>