import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/user/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/user/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增
export function add(data){
    return service.request({
        url:"/user/add",
        method: "post",
        data:Qs.stringify(data),
    });
}

//启停
export function startOrStop(data){
    return service.request({
        url:"/user/startOrStop",
        method: "post",
        data:Qs.stringify(data),
    });
}

//分配角色
export function roleDistribution(data){
    return service.request({
        url:"/user/roleDistribution",
        method: "post",
        data:Qs.stringify(data),
    });
}

//修改真实姓名
export function upRealName(data){
    return service.request({
        url:"/user/upRealName",
        method: "post",
        data:Qs.stringify(data),
    });
}

//重置密码
export function resetPassword(data){
    return service.request({
        url:"/user/resetPassword",
        method: "post",
        data:Qs.stringify(data),
    });
}
