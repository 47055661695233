<template>
    <a-tabs v-model:activeKey="selectKey" @change="change" :tabBarGutter="1" :style="{ marginRight: '2px' }">
        <a-tab-pane v-for="item in tabList" :key="item.path" class="tabOne" :tab="item.name">

        <!-- <template #tab>
            <span>
                <span class="icons-syncOutlined" v-if="item.path===selectKey"><SyncOutlined @click="toRefresh" /></span>
                <span>{{item.name}}</span>
                <span class="icons-closeOutlined" v-if="item.path!=='/home'"><CloseOutlined @click="toClose(item.path)"/></span>
            </span>
        </template> -->
        </a-tab-pane>
        <template #tabBarExtraContent>
            <a-tooltip placement="bottom">
                <template #title>
                <span>关闭所有</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:4px;" @click="closeAll">
                    <template #icon><RestOutlined  /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip placement="bottom">
                <template #title>
                <span>{{isHeadBar?'打开消息栏':'隐藏消息栏'}}</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:4px;" @click="toHeadBar">
                    <template #icon>
                        <VerticalAlignTopOutlined v-if="!isHeadBar" />
                        <VerticalAlignBottomOutlined v-else />
                    </template>
                </a-button>
            </a-tooltip>
            <a-tooltip placement="bottom">
                <template #title>
                <span>刷新当前页</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:4px;" @click="toRefresh">
                    <template #icon><SyncOutlined  /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip placement="bottom">
                <template #title>
                <span>关闭左边所有页面</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:4px;" @click="toCloseLeft">
                    <template #icon><VerticalRightOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip placement="bottomRight">
                <template #title>
                <span>关闭右边所有页面</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:4px;" @click="toCloseRight">
                    <template #icon><VerticalLeftOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip placement="bottomRight">
                <template #title>
                <span>关闭当前页</span>
                </template>
                <a-button type="dashed" size="small" style="margin-right:15px;" @click="toClose">
                    <template #icon><CloseOutlined /></template>
                </a-button>
            </a-tooltip>
        </template>
    </a-tabs>
</template>
<script>
import { computed,reactive,ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { SyncOutlined, CloseOutlined,VerticalRightOutlined,VerticalLeftOutlined,VerticalAlignTopOutlined,VerticalAlignBottomOutlined,RestOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
export default {
    name: "Tabs",
    components:{ SyncOutlined,CloseOutlined,VerticalRightOutlined,VerticalLeftOutlined,VerticalAlignTopOutlined,VerticalAlignBottomOutlined,RestOutlined },
    setup(props,ctx){
        const store = useStore();
        const router = useRouter();
        const data = reactive({
            // isHeadBar: JSON.parse(localStorage.getItem("headBar")),//消息栏是否隐藏
        });

        // 创建一个计算属性
        const selectKey = computed({
            // 取值函数
            get: () => store.state.user.tabSelected,
            // 赋值函数
            set: val => store.commit('user/SET_TABSELECTED', val),
        })
        const tabList = computed({
            // 取值函数
            get: () => store.state.user.tabList,
            // 赋值函数
            set: val => store.commit('user/SET_ALL_TABLIST', val)
        })
        const isHeadBar = computed({
            // 取值函数
            get: () => store.state.user.isHeadBar,
            // 赋值函数
            set: val => store.commit('user/SET_HEADBAR', val)
        })
        //点击
        const change = (val) =>{
            router.push(val);
        }
        //关闭 router.currentRoute.value.fullPath为获取当前打开的路径
        const toClose = () =>{
            // console.log("---------------------------------------")
            //判断关闭是否是当前打开的
            let newList = [];
            let newPath = "";
            // if(router.currentRoute.value.fullPath===path){
            //控制台不能删除
            if(router.currentRoute.value.fullPath!="/home"){
                for(let i = 0;i<store.state.user.tabList.length;i++){
                    if(router.currentRoute.value.fullPath===store.state.user.tabList[i].path){
                        newPath = store.state.user.tabList[i - 1].path;
                    }else{
                        newList.push(store.state.user.tabList[i])
                    }
                }
                //跳转到对应的前一个路径 并删除这个tab
                router.push(newPath)
                setTimeout(() =>{
                    store.commit('user/SET_ALL_TABLIST', newList);
                    localStorage.setItem("tabList", JSON.stringify(newList));
                },350);
            }else{
                message.warning("控制台不可关闭");
            }
                
            // }else{
            //     for(let ii = 0;ii<store.state.user.tabList.length;ii++){
            //         if(path!==store.state.user.tabList[ii].path){
            //             newList.push(store.state.user.tabList[ii])
            //         }
            //     }
            //     store.commit('user/SET_ALL_TABLIST', newList);
            //     localStorage.setItem("tabList", JSON.stringify(newList));
            // }
        }
        //刷新页面
        const toRefresh = () =>{
            ctx.emit("refreshHandle");
        };
        //是否隐藏消息栏
        const toHeadBar = () =>{
            store.commit('user/SET_HEADBAR', !store.state.user.isHeadBar);
            //存入全局
            ctx.emit("headBarHandle",store.state.user.isHeadBar);
        };
        //关闭所有页面
        const closeAll = () =>{
            let newList = [];
            for(let i = 0;i<store.state.user.tabList.length;i++){
                if("/home"===store.state.user.tabList[i].path){
                    newList.push(store.state.user.tabList[i]);
                    break;
                }
            }
            router.push("/home")
            setTimeout(() =>{
                store.commit('user/SET_ALL_TABLIST', newList);
                localStorage.setItem("tabList", JSON.stringify(newList));
            },350);
        };
        //关闭左边所有页面
        const toCloseLeft = () =>{
            let newList = [];
            let str = false;//是否开始加入tabs
            for(let i = 0;i<store.state.user.tabList.length;i++){
                if(router.currentRoute.value.fullPath===store.state.user.tabList[i].path){
                    newList.push(store.state.user.tabList[i]);
                    str = true;
                    break;
                }else{
                    if("/home"===store.state.user.tabList[i].path || str){
                        newList.push(store.state.user.tabList[i]);
                    }
                }
            }
            store.commit('user/SET_ALL_TABLIST', newList);
            localStorage.setItem("tabList", JSON.stringify(newList));
        }
        //关闭右边所有页面
        const toCloseRight = () =>{
            let newList = [];
            let str = true;//是否开始加入tabs
            for(let i = 0;i<store.state.user.tabList.length;i++){
                if(router.currentRoute.value.fullPath===store.state.user.tabList[i].path){
                    newList.push(store.state.user.tabList[i]);
                    str = false;
                    break;
                }else{
                    if("/home"===store.state.user.tabList[i].path || str){
                        newList.push(store.state.user.tabList[i]);
                    }
                }
            }
            store.commit('user/SET_ALL_TABLIST', newList);
            localStorage.setItem("tabList", JSON.stringify(newList));
        }

        return {
            data,
            change,
            tabList,
            selectKey,
            isHeadBar,
            toRefresh,
            toClose,
            toHeadBar,
            closeAll,
            toCloseLeft,
            toCloseRight,
        }
    }
}
</script>
<style lang="scss" scoped>
.icons-syncOutlined ::v-deep(.anticon) {
    margin-right: 7px;
    font-size: 12px;
    transition: all 1s;
}
.icons-syncOutlined:hover,.icons-closeOutlined:hover{
    color: #0a2f8d;
}
.icons-closeOutlined ::v-deep(.anticon) {
    margin-left: 6px;
    margin-right: 0px;
    font-size: 12px;
    transition: all 1s;
}

</style>