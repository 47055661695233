import service from "@/utils/request.js"
import Qs from 'qs'

//查看还有多少条未读消息
export function unMessageTotal(data){
    return service.request({
        url:"/message/unMessageTotal",
        method: "post",
        data:Qs.stringify(data),
    });
}
//获取最新3条消息
export function messageList3(data){
    return service.request({
        url:"/message/getMessage3",
        method: "post",
        data:Qs.stringify(data),
    });
}
//获取用户全部消息
export function messageAll(data){
    return service.request({
        url:"/message/getMessageAll",
        method: "post",
        data:Qs.stringify(data),
    });
}
//已读功能
export function upUnMessage(data){
    return service.request({
        url:"/message/upUnMessage",
        method: "post",
        data:Qs.stringify(data),
    });
}