import { createRouter, createWebHashHistory,createWebHistory } from "vue-router";
import { getStaticRouter } from "./staticRouter.js";
const routes = getStaticRouter();
// const routes = [
//   {
//     path: '/',
//     // redirect重定向
//     redirect: '/login',
//     hidden: true,
//     meta:{
//         title:"登录"
//     }
//   },
//   // 登录 
//   {
//     path: "/login",
//     name: "Login",
//     hidden: true,
//     meta: {
//       title: "登录"
//     },
//     component: () => import("@/views/acount/Login.vue")
//   },
  // // 注册 
  // {
  //   path: "/register",
  //   name: "Register",
  //   hidden: true,
  //   meta: {
  //     title: "注册",
  //     hidden: true,
  //   },
  //   component: () => import("../views/acount/Register.vue")
  // },
  // // 忘记密码 
  // {
  //   path: "/forget",
  //   name: "Forget",
  //   hidden: true,
  //   meta: {
  //     title: "忘记密码"
  //   },
  //   component: () => import("../views/acount/Forget.vue")
  // },
  // 管理后台 - 首页
  // {
  //   path: "/index",
  //   name: "Index",
  //   meta: {
  //     title: "控制台",
  //     icon: "index"
  //   },
  //   // redirect重定向
  //   redirect: '/home',
  //   component: () => import("../views/layout/Index.vue"),
  //   children:[
  //     {
  //       path:"/home",
  //       name:"Home",
  //       meta:{
  //         title:"首页",
  //         icon:"home"
  //       },
  //       component: () => import("../views/home/Index.vue")
  //     }
  //   ]
  // },
  // 产品管理 
  // {
  //   path: "/product",
  //   name: "Product",
  //   meta: {
  //     title: "产品管理",
  //     icon: "chanpin"
  //   },
  //   component: () => import("../views/layout/Index.vue"),
  //   children: [
  //     {
  //       path: "/enterWarehouse",
  //       name: "EnterWarehouse",
  //       meta: {
  //         title: "入库",
  //       },
  //       component: () => import("../views/product/EnterWarehouse.vue"),
  //     },
  //     {
  //       path: "/outWarehouse",
  //       name: "OutWarehouse",
  //       meta: {
  //         title: "出库",
  //       },
  //       component: () => import("../views/product/OutWarehouse.vue")
  //     },
  //   ]
  // },
  // // 管理后台 - 管理总台
  // {
  //   path: "/adminIndex",
  //   name: "AdminIndex",
  //   meta: {
  //     title: "系统设置",
  //     icon: "xitongshezhi"
  //   },
  //   component: () => import("../views/layout/Index.vue"),
  //   children: [
  //     {
  //       path: "/admin/role",
  //       name: "Role",
  //       meta: {
  //         title: "角色管理",
  //       },
  //       component: () => import("../views/admin/Role.vue"),
  //     },
  //     {
  //       path: "/admin/user",
  //       name: "User",
  //       meta: {
  //         title: "用户管理",
  //       },
  //       component: () => import("../views/admin/User.vue")
  //     },
  //   ]
  // }
// ];

const router = createRouter({
  history: createWebHashHistory(),
  routes: getStaticRouter()
});

export default router;
