import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/takeDelivery/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//制单
export function add(data){
    return service.request({
        url:"/takeDelivery/add",
        contentType: "application/json",
        method: "post",
        data:data,
    });
}

//审核
export function review(data){
    return service.request({
        url:"/takeDelivery/review",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
export function remove(data){
    return service.request({
        url:"/takeDelivery/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}


//订单详情
export function orderDetails(data){
    return service.request({
        url:"/takeDelivery/orderDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//产品详情
export function productDetails(data){
    return service.request({
        url:"/takeDelivery/productDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//用户详情
export function userDetails(data){
    return service.request({
        url:"/takeDelivery/userDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}