<template>
    <div class="html">
        <!-- tree -->
        <div class="tree">
            <div class="tree_top">
                菜单内容
            </div>
            <div class="tree_cont">
                <a-spin tip="加载中..." :spinning="data.isTree"> 
                    <a-tree defaultExpandAll v-if="data.treeData.length"
                        class="draggable-tree"
                        v-model:selectedKeys="selectedKeys"
                        :tree-data="data.treeData"
                        @select="treeCheck"
                    />
                </a-spin>
            </div>
        </div>
        <!-- 内容 -->
        <div class="cont" ref="contRef">
            <!-- 查询条件 -->
            <div class="query" ref="queryRef">
                <a-form layout="inline" :model="data.queryData">
                    <a-form-item label="菜单名称">
                        <a-input v-model:value="data.queryData.name" placeholder="" style="width:170px;" allow-clear/>
                    </a-form-item>
                    <a-form-item :wrapper-col="{span: 14, offset: 4}">
                        <a-button type="primary" @click="tData(1)">搜索</a-button>
                    </a-form-item>
                    <a-form-item :wrapper-col="{span: 14, offset: 4}">
                        <a-button @click="resetTable">重置</a-button>
                    </a-form-item>
                </a-form>
            </div>
            <!-- 功能按钮 -->
            <div class="btn" ref="btnRef">
                <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="add">新增</a-button>
                <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.update" @click="update">修改</a-button>
                <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="remove">删除</a-button>
            </div>
            <!-- 表格 -->
            <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <a-table
                    rowKey="id"
                    ref="ctableRef"
                    :dataSource="data.tableData" 
                    :scroll="{scrollToFirstRowOnChange:false, y:'calc(100vh - ' + data.tableY + 'px)'}" 
                    :columns="columns" 
                    :row-selection="rowSelection"
                    :custom-row="customRow"
                    bordered 
                    size="middle" 
                    :pagination="false" 
                    :loading="data.tableLoading"
                    @change="handleTableChange"
                />
            </div>
            <!-- 翻页控件 -->
            <div class="table_pagination">
                <a-pagination 
                :page-size-options="dataPageSizeOptions" 
                :defaultPageSize="dataDefaultPageSize" 
                style="float: right;" 
                :total="dataTotal" 
                :show-total="total => `共 ${total} 条`" 
                :current="dataCurrent"
                :pageSize="dataPageSize"
                @change="pageChange"
                @showSizeChange="onShowSizeChange"
                show-size-changer 
                show-quick-jumper 
                >
                    <template #buildOptionText="props">
                        <span>{{ props.value }} 条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>

<!-- 新增对话框 -->
<a-modal
  :title="data.title"
  v-model:visible="data.isAdd"
  :confirm-loading="data.addLoading"
  @ok="handleAdd"
  @cancel="handleCloseAdd"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.addLoading">
  <a-form
    ref="addRef"
    :model="data.addData"
    :rules="data.addRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:17}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="菜单名称" name="name">
                <a-input v-model:value="data.addData.name" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="上级菜单" name="pid">
                <a-select
                    v-model:value="data.addData.pid"
                    show-search
                    placeholder=""
                    @change="toComponent"
                    :loading="data.isPidList"
                >
                <a-select-option v-for="item in data.pidList" :key="item" :value="item.id">
                    {{ item.name }}
                </a-select-option>

                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="菜单类型" name="type">
                <a-select v-model:value="data.addData.type" placeholder="">
                    <template v-for="(item,index) in data.typeList" :key="index+1">
                        <a-select-option :value="item.id">{{item.name}}</a-select-option>
                    </template>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="权限词条" name="permissionValue">
                <a-input v-model:value="data.addData.permissionValue" placeholder="user.see" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12" v-if="data.addData.type==1">
            <a-form-item has-feedback label="页面地址" name="component">
                <a-input v-model:value="data.addData.component" placeholder="/admin/User.vue" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
        <a-col :span="12" v-if="data.addData.type==1">
            <a-form-item has-feedback label="跳转路径" name="path">
                <a-input v-model:value="data.addData.path" placeholder="/user" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12" v-if="data.addData.type==1 && data.addData.pid==='0'">
            <a-form-item has-feedback label="菜单图标" name="icon">
                <a-select v-model:value="data.addData.icon" placeholder="">
                    <template v-for="(item,index) in data.iconList" :key="index+1">
                        <a-select-option :value="item.id">
                            <svg-icon :iconName="item.id" className="icon_svg"></svg-icon>
                            {{item.name}}
                        </a-select-option>
                    </template>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
</template>

<script>
import { useStore } from 'vuex'
import { reactive,watch,ref,onMounted,computed,createVNode,nextTick } from 'vue'
import { addPermission,updataPermission,detailsPermission,removePermission,permissionAll,getList } from "@/api/admin/menu.js"
import { downSuperiorPermission,downIconPermission } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { getTrees } from "@/utils/tree.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
   name: "Menu",
   components: { },
   setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 66 ) + "px)";
                    data.tableY = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 15;
                }else{
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 66 ) + "px)";
                    data.tableY = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 15 + 60;
                }
            }
        )
        const selectedKeys = ref([]);//tree选中的值

        const dataDefaultPageSize = ref(15);//默认每页显示的条数
        const dataPageSizeOptions = ref(['15',  '30', '50']);//可以选择每页显示的条数
        const dataCurrent = ref(1);//当前第几页
        const dataPageSize = ref(15);//每页显示的条数
        const dataTotal = ref(0);//总共几条

        const sortedInfo = ref();//排序样式是否开启
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '菜单名称',
                    width: 150,
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: '类型',
                    width: 130,
                    dataIndex: 'type',
                    key: 'type',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'type' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.type==1?"菜单":text.record.type==2?"按钮":"";
                    }
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '修改时间',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                },
            ];
        });
        const data = reactive({
            styleTableHeight:"height: calc(100% - 138px)",
            btnMenu: store.state.user.btnList.menu,
            isTree: false,
            treeData:[],
            queryData:{
                id:"",
                name:"",
                sort:"",
                desc:"",
            },
            tableData:[],
            selectedRowKeys: [],
            tableLoading: false,
            tableY:0,
            tableX:0,

            // 一下参数用于新增修改对话框
            title:"",
            modelType: 1,//1为新增 2为修改
            isAdd: false,
            addLoading: false,
            isPidList:false,
            pidList:[],//上级下拉列表
            typeList:[{id:1,name:"菜单"},{id:2,name:"按钮"}],//类型下拉列表
            iconList:downIconPermission(),//图标列表
            addData:{
                name:"",
                pid: "",
                type: "",
                icon:"",
                path:"",
                component:"",
                permissionValue:"",
            },
            addRules:{
                name:[{ required: true, message: '菜单名称不能为空', trigger: 'blur' }],
                pid:[{ required: true, message: '上级菜单不能为空', trigger: 'change' }],
                type:[{ required: true, type:'number', message: '菜单类型不能为空', trigger: 'change' }],
                icon:[{ required: true, message: '菜单图标不能为空', trigger: 'blur' }],
                path:[{ required: true, message: '菜单路径不能为空', trigger: 'blur' }],
                component:[{ required: true, message: '页面地址不能为空', trigger: 'blur' }],
                permissionValue:[{ required: true, message: '权限词条不能为空', trigger: 'blur' }],
            },
        });

        //加载数据 tree
        const treeData = ()=>{
            data.isTree = true;
            permissionAll().then(res =>{
                let tree = [];
                for(let i = 0;i < res.data.list.length;i++){
                    tree.push(
                        {
                            id:res.data.list[i].id,
                            key:res.data.list[i].id,
                            title:res.data.list[i].name,
                            pid:res.data.list[i].pid
                        }
                    );
                }
                data.treeData = getTrees(tree,0);
                data.isTree = false;
            }).catch((err) => {
                data.isTree = false;
            });
        }

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const contRef = ref();
        const queryRef = ref();
        const btnRef = ref();

        //加载表格数据value 为1 新的搜索 
        const tData = (value)=>{
            data.tableLoading = true;
            if(value==1){//这是新的搜索
                dataCurrent.value = 1;
                dataPageSize.value = 15;
                selectedKeys.value = [];
                sortedInfo.value = null;//清除排序显示样式
                data.queryData.sort = "";
                data.queryData.desc = "";
            }
            getList({
                id:data.queryData.id,
                page:dataCurrent.value,
                limit:dataPageSize.value,
                name:data.queryData.name,
                sort:data.queryData.sort,
                desc:data.queryData.desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                dataTotal.value = res.data.total;
            })
        };

        //渲染后 加载数据
        onMounted(() => {
            treeData();
            tData(1);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 66 ) + "px)";
                        //表格内容滚动高度
                        data.tableY = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 15;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 66 ) + "px)";
                        //表格内容滚动高度
                        data.tableY = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 15 + 60;
                    }
                },1);
            })
        });

        //点击tree触发的事件
        const treeCheck = (id)=>{
            data.queryData.id = id[0]+"";
            tData(1);
        }

        //复选框 点击行选中行
        const selectRow = (record) => {
            // const selectedRowKeys = [...data.selectedRowKeys];
            const selectedRowKeys = [];
            if (selectedRowKeys.indexOf(record.id) >= 0) {
                selectedRowKeys.splice(selectedRowKeys.indexOf(record.id), 1);
            } else {
                selectedRowKeys.push(record.id);
            }
            data.selectedRowKeys = selectedRowKeys;
        };
        const rowSelection = computed(() => {
            return {
                selectedRowKeys: data.selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    data.selectedRowKeys = selectedRowKeys;
                },
            };
        });
        const customRow = (record) => {
            return {
                onClick: () => {
                    selectRow(record);
                },
            };
        };

        //重置按钮
        const resetTable = ()=>{
            //清除勾选
            data.selectedRowKeys = [];
            data.queryData.id = "";
            data.queryData.name = "";
            selectedKeys.value = [];
            data.queryData.sort = "";
            data.queryData.desc = "";
            sortedInfo.value = null;//清除排序显示样式
            dataCurrent.value = 1;
            dataPageSize.value = 15;
            tData();
        }

        //排序
        const handleTableChange = (pag, filters, sorter) => {
            sortedInfo.value = sorter;//将选择的数据传给sortedInfo
            data.queryData.sort = sorter.columnKey;
            if(sorter.order==="descend"){//当前排序
                data.queryData.desc = "desc";
            }else if(sorter.order==="ascend"){
                data.queryData.desc = "asc";
            }else{
                //重置
                data.queryData.sort = "";
                data.queryData.desc = "";
            }
            
            tData();
        };

        //翻页
        const pageChange = (current, size) =>{
          dataCurrent.value = current;
          dataPageSize.value = size;
          tData();
        }

        //修改每页显示的条数
        const onShowSizeChange = (current, pageSize) => {
            dataCurrent.value = current;
            dataPageSize.value = pageSize;
            tData();
        };

        //获取菜单下拉
        const getDownSuperiorPermission = ()=>{
            data.pidList = [];
            data.isPidList = true;
            downSuperiorPermission().then(res =>{
                data.pidList.push({id:"0",name:"没有上级"});
                for(let i = 0;i<res.data.list.length;i++){
                    data.pidList.push(res.data.list[i]);
                }
                data.isPidList = false;
            });
        }

        //新增
        const addRef = ref();
        const add = () =>{
            getDownSuperiorPermission();
            data.title = "新增菜单";
            data.modelType = 1;
            
            data.isAdd = true;

            console.log()
        }
        const toComponent = () =>{
            //这个方法是用于 选择没有上级的时候给页面地址赋值，因为这个是固定的值 /layout/Index.vue
            if(data.addData.pid==0){
                data.addData.component = "/layout/Index.vue";
            }else{
                data.addData.component = "";
            }
        }
        const handleAdd = () =>{
            addRef.value.validate().then(() => {
                if(data.modelType==1){
                    data.addLoading = true;
                    //为按钮的时候 不需要这个值
                    if(data.addData.type==2){
                        data.addData.component = "";
                    }
                    addPermission(data.addData).then(res =>{
                        message.success("新增成功");
                        handleCloseAdd();
                        resetTable();
                        treeData();
                    }).catch((err) => {
                        data.addLoading = false;
                    });
                }
                if(data.modelType==2){
                    data.addLoading = true;
                    updataPermission(data.addData).then(res =>{
                        message.success("修改成功");
                        handleCloseAdd();
                        resetTable();
                    }).catch((err) => {
                        data.addLoading = false;
                    });
                }
            })
        }
        const handleCloseAdd = () =>{
            //关闭窗口
            data.isAdd = false;
            //取消加载状态
            data.addLoading = false;
            //重置数据
            data.addData={
                name:"",
                pid:"",
                type:"",
                icon:"",
                path:"",
                component:"",
                permissionValue:"",
            }
            data.isPidList = false;
            //重置表单校验
            addRef.value.resetFields();
        }

        //修改
        const update = () =>{
            if(data.selectedRowKeys.length==1){
                getDownSuperiorPermission();
                data.title = "修改菜单";
                data.modelType = 2;
                data.addLoading = true;
                detailsPermission({id:data.selectedRowKeys[0]}).then(res =>{
                    if(isOK(res.data.permission)){
                        data.addData={
                            id:data.selectedRowKeys[0],
                            name: res.data.permission.name,
                            pid: res.data.permission.pid,
                            type: res.data.permission.type,
                            icon: res.data.permission.icon,
                            path: res.data.permission.path,
                            component: res.data.permission.component,
                            permissionValue: res.data.permission.permissionValue,
                        }
                    }
                    data.addLoading = false;
                })

                data.isAdd = true;
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }

            
        }

        //删除
        const remove = () =>{
            if(data.selectedRowKeys.length>0){
                Modal.confirm({
                    title: '是否删除菜单',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定删除菜单',
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        removePermission({idList:data.selectedRowKeys.toString()}).then(res => {
                            message.success("删除"+res.data.num+"条数据");
                            data.selectedRowKeys = [];
                            tData();
                        });
                    },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onCancel() {},
                });
            }else{
                message.warning("请选择一条数据");
            }
        }

  
        return {
            selectedKeys,
            dataDefaultPageSize,
            dataPageSizeOptions,
            dataCurrent,
            dataPageSize,
            dataTotal,
            sortedInfo,
            columns,
            tableRef,
            contRef,
            queryRef,
            btnRef,
            treeCheck,
            customRow,
            rowSelection,
            data,
            treeData,
            tData,
            pageChange,
            resetTable,
            onShowSizeChange,
            handleTableChange,

            addRef,
            add,
            getDownSuperiorPermission,
            toComponent,
            handleAdd,
            handleCloseAdd,

            update,
            remove,
        }
   }
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.tree{
    float: left;
    width: 246px;
    height: calc(100% - 44px);
    border-radius: 2px;
    margin: 20px;
    border: 2px solid #3f74fe;
}
.tree_top{
    width: 100%;
    height: 34px;
    background-color: #3f74fe;
    text-align: center;
    color: #fff;
    line-height: 34px;
}
.tree_cont{
    margin-left: 10px;
    width: calc(100% - 10px);
    height: calc(100% - 34px);
    overflow-x: hidden;
    overflow-y: auto;
}
/*滚动条样式*/
  .tree_cont::-webkit-scrollbar {/*滚动条整体样式*/
    width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  }
  .tree_cont::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    /*border-radius: 2px;*/
    -webkit-box-shadow: inset 0 0 0px rgba(63, 116, 254,0.5);
    background: rgba(63, 116, 254,0.5);
  }
  .tree_cont::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,.1);
    border-radius: 0;
    background: rgba(0,0,0,.1);
  }
.cont{
    float: left;
    width: calc(100% - 310px);
    height: calc(100% - 40px);
    margin: 20px 0px;
}
.query{
    width: 100%;
    height: auto;
}
.btn{
    width: 100%;
    height: auto;
    margin: 10px 0;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: 100%;
    // height: calc(100% - 138px);
    overflow: hidden;
}
.table_pagination{
    width: 100%;
    height: 32px;
    margin-top: 15px;
}
/* svg 图标尺寸 */
.icon_svg {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: -3px;
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>