<template>
  <a-config-provider :locale="zh_CN">
    <router-view />
  </a-config-provider>
</template>
<script>
//antd控件英文修改为中文显示
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  setup() {

    return {
      zh_CN,
    };
  },
}
</script>
<style>
/* 下面是为了统一表格自动化高度 */
.ant-table-wrapper{
  height: 100%;
}
.ant-table{
  height: 100%;
}
.ant-spin-nested-loading{
  height: 100%;
}
.ant-spin-container{
  height: 100%;
}
.ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 0px rgba(230, 230, 230, 0.8);
  background: rgba(230, 230, 230, 0.8);
}
.ant-table-body::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}

</style>
<style lang="scss">
//校验提示居中
// .ant-form-explain{
//   text-align: center;
// }
#app {
  width: 100%;
  height: 100%;
}
</style>
