<template>
<div class="deliveryDetails" >
    <a-spin :spinning="data.isDeliveryDetailsLoading">
        <div id="deliveryDetailsPDF">
            <a-timeline>
                <a-timeline-item v-if="data.deliveryReturnList.length>0">
                    <div class="title">退货详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.deliveryReturnList" :columns="deliveryReturnColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">发货单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.delivery" :columns="deliveryColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                    <div class="title">发货产品详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.deliveryProductList" :columns="deliveryProductColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">出货单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.outWarehouseList" :columns="outWarehouseColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">出库质检单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.outQualityList" :columns="outQualityColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                    <div class="title">出库质检产品单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.outQualityProductList" :columns="outQualityProductColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">提货单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.takeDelivery" :columns="takeDeliveryColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                    <div class="title">提货产品单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.takeProductList" :columns="takeProductColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">入库单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.inWarehouseList" :columns="inWarehouseColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">入库质检单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.inQualityList" :columns="inQualityColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                    <div class="title">入库质检产品单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.inQualityProductList" :columns="inQualityProductColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
                <a-timeline-item>
                    <div class="title">订单详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.ordersList" :columns="orderColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                    <div class="title">订单产品详情</div>
                    <div class="table">
                        <a-table bordered :data-source="data.orderProductList" :columns="orderProductColumns" :pagination="false" rowKey="id" >
                                <template #long="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="text">
                                            {{text}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #productLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.productList.find(item => {return item.id == text}).name">
                                            {{data.productList.find(item => {return item.id == text}).name}}
                                        </a-tooltip>
                                    </div>
                                </template>
                                <template #userLong="{ text }">
                                    <div class="long">
                                        <a-tooltip :title="data.userList.find(item => {return item.id == text}).realName">
                                            {{data.userList.find(item => {return item.id == text}).realName}}
                                        </a-tooltip>
                                    </div>
                                </template>
                        </a-table>
                    </div>
                </a-timeline-item>
            </a-timeline>
        </div>
    </a-spin>
</div>
</template>
<script>
import { reactive,ref,computed,onMounted,nextTick } from 'vue';
import { deliveryDetails } from '@/api/components/deliveryDetails.js';
import { downUser,downProduct } from '@/api/down.js';
import { getPdf } from "@/utils/htmlToPdf.js"
export default {
    name: "DeliveryDetails",
    props: {
        //发货单号
        deliveryId:{
            type: String,
            default: ""
        },
    },
    setup(props, context){
        const data = reactive({
            userList:[],
            productList:[],
            isDeliveryDetailsLoading:false,
            deliveryReturnList:[],
            delivery:[],
            deliveryProductList:[],
            outWarehouseList:[],
            outQualityList:[],
            outQualityProductList:[],
            inWarehouseList:[],
            inQualityList:[],
            inQualityProductList:[],
            ordersList:[],
            orderProductList:[],
            takeDelivery:[],
            takeProductList:[],
        })
        //退货单表格列表
        const deliveryReturnColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '发货单号',
                    dataIndex: 'deliveryId',
                    key: 'delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '出库单',
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '退回数量',
                    dataIndex: 'productNum',
                    key: 'product_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '操作人员',
                    dataIndex: 'returnUid',
                    key: 'return_uid',
                    ellipsis: true,
                    slots: {
                        customRender: 'userLong',
                    },
                },
                {
                    title: '创建日期',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '更新日期',
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '是否入库',
                    dataIndex: 'inNum',
                    key: 'in_num',
                    ellipsis: true,
                    customRender: (text) => {
                        if(text.record.productNum<=text.record.inNum){
                            return '全部入库';
                        }
                        return text.record.inNum>0?'部分入库':'未入库';
                    }
                },
            ];
        });
        //发货单表格列表
        const deliveryColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    },
                },
                {
                    title: '发货单号',
                    dataIndex: 'deliveryId',
                    key: 'delivery_id',
                    width: 210,
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货单号',
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    width: 210,
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '发货方式',
                    dataIndex: 'deliveryMethod',
                    key: 'delivery_method',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.deliveryMethod==1?"物流":text.record.deliveryMethod==2?"自提":(text.record.deliveryMethod==3?"代送":"");
                    },
                },
                {
                    title: '制单人',
                    dataIndex: 'consignorId',
                    key: 'consignor_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'userLong',
                    },
                },
                {
                    title: '退货/退款',
                    dataIndex: 'isRefunds',
                    key: 'is_refunds',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isRefunds==1?"正常":text.record.isRefunds==2?"退款":(text.record.isRefunds==3?"退货":"");
                    },
                },
                {
                    title: '退款金额',
                    dataIndex: 'refundsMoney',
                    key: 'refunds_money',
                    ellipsis: true,
                },
                {
                    title: '创建日期（发货）',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '更新日期',
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted?"报废":"正常";
                    },
                },
            ];
        });
        //发货产品单表格列表
        const deliveryProductColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出库单',
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '发货数量',
                    dataIndex: 'productNum',
                    key: 'product_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'deliveryExplain',
                    key: 'delivery_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ]
        })
        //出货单表格列表
        const outWarehouseColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出入库单号',
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        switch(text.record.type){
                            case 1:
                                return "生产入库";
                            case 2:
                                return "退回入库";
                            case 3:
                                return "提货出库";
                            case 4:
                                return "报废出库";
                            default:
                                return "";
                        }
                    }
                },
                {
                    title: '数量',
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '质检单号',
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建时间',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        //出货质检单表格列表
        const outQualityColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '质检编号',
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'qualityDetails',
                    },
                },
                {
                    title: '订单编号',
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货单号',
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '质检类型',
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.type){
                            case 1: v = "生产入库质检"; break;
                            case 2: v = "退货入库质检"; break;
                            case 3: v = "出库质检"; break;
                            default:"";      
                        }
                        return v;
                    }
                },
                {
                    title: '是否执行',
                    dataIndex: 'isImplement',
                    key: 'is_implement',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isImplement?"已执行":"未执行";
                    }
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"是":text.record.isDeleted==0?"否":"";
                    }
                },
                {
                    title: '创建日期',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '更新日期',
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                }
            ];
        });
        //出库质检产品表格列表
        const outQualityProductColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '入库单',
                    dataIndex: 'ioWarehouseId',
                    key: 'io_warehouse_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '检测总数',
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '合格数量',
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '检测说明',
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ]
        })
        //提货单表格列表
        const takeDeliveryColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    },
                },
                {
                    title: '提货单号',
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户名称',
                    dataIndex: 'customerName',
                    key: 'customer_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户地址',
                    dataIndex: 'customerAddress',
                    key: 'customer_address',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户联系号码',
                    dataIndex: 'customerPhone',
                    key: 'customer_phone',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建日期',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '制单人',
                    dataIndex: 'producerName',
                    key: 'producer_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '审核人员',
                    dataIndex: 'reviewName',
                    key: 'review_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货单状态',
                    dataIndex: 'takeState',
                    key: 'take_state',
                    ellipsis: true,
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.takeState){
                            case 0: v = "未审核"; break;
                            case 1: v = "通过"; break;
                            case 2: v = "不通过"; break;
                            case 3: v = "已完成"; break;
                            default:"";      
                        }
                        return v;
                    },
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    customRender: (text) => {
                        return text.record.isDeleted?"是":"否";
                    },
                    ellipsis: true,
                },
            ];
        });
        //提货产品列表属性
        const takeProductColumns  = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    ellipsis: true,
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '提货数量',
                    dataIndex: 'takeNum',
                    key: 'take_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货日期',
                    dataIndex: 'takeTime',
                    key: 'take_time',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货备注',
                    dataIndex: 'takeRemarks',
                    key: 'take_remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ]
        })
        //出货单表格列表
        const inWarehouseColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出入库单号',
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        switch(text.record.type){
                            case 1:
                                return "生产入库";
                            case 2:
                                return "退回入库";
                            case 3:
                                return "提货出库";
                            case 4:
                                return "报废出库";
                            default:
                                return "";
                        }
                    }
                },
                {
                    title: '数量',
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '质检单号',
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建时间',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        //入库质检单表格列表
        const inQualityColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '质检编号',
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'qualityDetails',
                    },
                },
                {
                    title: '订单编号',
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货单号',
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '质检类型',
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.type){
                            case 1: v = "生产入库质检"; break;
                            case 2: v = "退货入库质检"; break;
                            case 3: v = "出库质检"; break;
                            default:"";      
                        }
                        return v;
                    }
                },
                {
                    title: '是否执行',
                    dataIndex: 'isImplement',
                    key: 'is_implement',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isImplement?"已执行":"未执行";
                    }
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"是":text.record.isDeleted==0?"否":"";
                    }
                },
                {
                    title: '创建日期',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '更新日期',
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                }
            ];
        });
        //入库产品列表
        const inQualityProductColumns = computed(() =>{
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    dataIndex: 'productId',
                    key: 'product_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'productLong',
                    },
                },
                {
                    title: '生产人员',
                    dataIndex: 'productionPersonnel',
                    key: 'production_personnel',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '检测总数',
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '合格数量',
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '入库数量',
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '检测说明',
                    width: 140,
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ]
        })
        //订单表格列表
        const orderColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '订单编号',
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '制单人',
                    dataIndex: 'producer',
                    key: 'producer',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '订单状态',
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.status){
                            case 0: v = "厂长审核中"; break;
                            case 1: v = "生产中"; break;
                            case 2: v = "完成"; break;
                            default:"";      
                        }
                        return v;
                    },
                    ellipsis: true,
                },
                {
                    title: '创建时间',
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"报废":text.record.isDeleted==0?"有效":"";
                    }
                },
                {
                    title: '要求或建议',
                    dataIndex: 'request',
                    key: 'request',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        //订单产品表格列表
        const orderProductColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品型号',
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '尺寸',
                    dataIndex: 'productSize',
                    key: 'product_size',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品重量(kg)',
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品颜色',
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计量单位',
                    dataIndex: 'productUnit',
                    key: 'product_unit',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划生产数',
                    dataIndex: 'planQuantities',
                    key: 'plan_quantities',
                    ellipsis: true,
                },
                {
                    title: '计划完成日期',
                    dataIndex: 'planDate',
                    key: 'plan_date',
                    ellipsis: true,
                },
            ]
        })
        
        
        //获取用户数据
        const getUserList = ()=>{
            downUser().then(res =>{
                data.userList = res.data.list;
            })
        }

        //获取产品名称数据
        const getProductList = ()=>{
            downProduct().then(res =>{
                data.productList = res.data.list;
            })
        }

        //获取数据
        const getData = ()=>{
            data.isDeliveryDetailsLoading = true;
            deliveryDetails({delivery_id:props.deliveryId}).then(res =>{
                data.deliveryReturnList = res.data.deliveryReturnList;
                data.delivery = [res.data.delivery];
                data.deliveryProductList = res.data.deliveryProductList;
                data.outWarehouseList = res.data.outWarehouseList;
                data.outQualityList = res.data.outQualityList;
                data.outQualityProductList = res.data.outQualityProductList;
                data.inWarehouseList = res.data.inWarehouseList;
                data.inQualityList = res.data.inQualityList;
                data.inQualityProductList = res.data.inQualityProductList;
                data.ordersList = res.data.ordersList;
                data.orderProductList = res.data.orderProductList;
                data.takeDelivery = [res.data.takeDelivery];
                data.takeProductList = res.data.takeProductList;
                data.isDeliveryDetailsLoading = false;
            })
        }

        //渲染后 加载数据
        onMounted(() => {
            nextTick(()=>{
                getUserList();
                getProductList();
                getData();
            })
        })

        //详情-导出PDF
        const handlePDF = (id,name)=>{
            getPdf(id,name);
        }

        return {
            data,
            getUserList,
            getProductList,
            getData,

            deliveryReturnColumns,
            deliveryColumns,
            deliveryProductColumns,
            outWarehouseColumns,
            outQualityColumns,
            outQualityProductColumns,
            takeDeliveryColumns,
            takeProductColumns,
            inWarehouseColumns,
            inQualityColumns,
            inQualityProductColumns,
            orderColumns,
            orderProductColumns,

            handlePDF,
        }
    }
}
</script>
<style lang='scss' scoped>
.deliveryDetails{
    width: 100%;
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}
.deliveryDetails::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.deliveryDetails::-webkit-scrollbar-thumb {
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 0px rgba(198, 199, 202, 0.7);
  background: rgba(198, 199, 202, 0.7);
}
.deliveryDetails::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 1);
}
.title{
    width: 100%;
    height: 24px;
    line-height: 15px;
    font-size: 15px;
    padding-top: 7px;
    color: #3f74fe;
}
.table{
    width: calc(100% - 20px);
    height: auto;
    margin-top: 4px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>