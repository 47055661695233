import cookie from "cookie_js";

const tokenName = "user_toKen";

export function setToKen(token){
    cookie.set(tokenName, token);
}

export function getToken(){
    return cookie.get(tokenName);
}

export function removeToken() {
    return cookie.remove(tokenName);
  }