import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/deliveryReturn/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//撤回
export function remove(data){
    return service.request({
        url:"/deliveryReturn/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}
