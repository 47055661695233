<template>
    <div class="html" v-if="data.rolesData[0]!=='超级管理员' && data.rolesData[0]!=='董事'">欢迎光临</div>
    <div class="html" v-if="data.rolesData[0]==='超级管理员' || data.rolesData[0]==='董事'">
        <div class="top" ref="top">
            <div class="top_one">
                <div class="top_one_in">
                    <a-skeleton active :loading="data.isSkeletonTop1">
                        <div class="top_one_in_top">
                            <div class="top_one_in_top_left">年总产件数</div>
                            <a-tooltip placement="top">
                                <template #title>
                                <span>单位：件</span>
                                </template>
                                <div class="top_one_in_top_right"><InfoCircleOutlined /></div>
                            </a-tooltip>
                        </div>
                        <div class="top_one_in_money"><GoldOutlined style=" font-size: 22px" /> {{data.yaerData.yaerTotalProduction}}</div>
                        <div class="top_one_in_bi" style="overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">
                            同时比 {{((data.yaerData.yaerTotalProduction/(data.yaerData.lastYearTotalProduction==0?1:data.yaerData.lastYearTotalProduction))*100).toFixed(2)}}% 
                            <span style="color:#f00;" v-if="(data.yaerData.yaerTotalProduction/(data.yaerData.lastYearTotalProduction==0?1:data.yaerData.lastYearTotalProduction))>=1"><CaretUpOutlined /></span>
                            <span style="color:#0f0;" v-if="(data.yaerData.yaerTotalProduction/(data.yaerData.lastYearTotalProduction==0?1:data.yaerData.lastYearTotalProduction))<1"><CaretDownOutlined /></span>
                            <!-- <span style="padding-left:20px">占年计划 11%</span> -->
                            <!-- <span style="color:#0f0;"><CaretDownOutlined /></span> -->
                        </div>
                        <div class="top_one_in_foot">日均产件 {{Math.ceil(data.yaerData.yaerTotalProduction/Math.ceil(( new Date() - new Date(new Date().getFullYear().toString()))/(24*60*60*1000)))}}</div>
                    </a-skeleton>
                </div>
            </div>
            <div class="top_one">
                <div class="top_one_in">
                    <a-skeleton active :loading="data.isSkeletonTop2">
                        <div class="top_one_in_top">
                            <div class="top_one_in_top_left">年订单总单数</div>
                            <a-tooltip placement="top">
                                <template #title>
                                <span>单位：件</span>
                                </template>
                                <div class="top_one_in_top_right"><InfoCircleOutlined /></div>
                            </a-tooltip>
                        </div>
                        <div class="top_one_in_money"><ShoppingCartOutlined style=" font-size: 22px"/> {{data.yaerData.yaerTotalOrderNum}}</div>
                        <div class="top_one_in_bi" id="echartTop1">
                            
                        </div>
                        <div class="top_one_in_foot">{{data.maxMonthOrderNum}}月为最多订单月</div>
                    </a-skeleton>
                </div>
            </div>
            <div class="top_one">
                <div class="top_one_in">
                    <a-skeleton active :loading="data.isSkeletonTop3">
                        <div class="top_one_in_top">
                            <div class="top_one_in_top_left">未发货总数</div>
                            <a-tooltip placement="top">
                                <template #title>
                                <span>单位：件</span>
                                </template>
                                <div class="top_one_in_top_right"><InfoCircleOutlined /></div>
                            </a-tooltip>
                        </div>
                        <div class="top_one_in_money"><TagsOutlined style=" font-size: 22px" /> {{data.yaerData.yaerUndeliveredNum}}</div>
                        <div class="top_one_in_bi" id="echartTop2">
                            
                        </div>
                        <div class="top_one_in_foot">占总需发货 {{data.yaerData.yaerTotalProduction!=0?(data.yaerData.yaerUndeliveredNum/data.yaerData.yaerTotalProduction*100).toFixed(2):0}}%</div>
                    </a-skeleton>
                </div>
            </div>
            <div class="top_one" style="margin-right: 0px;">
                <div class="top_one_in">
                    <a-skeleton active :loading="data.isSkeletonTop4">
                        <div class="top_one_in_top">
                            <div class="top_one_in_top_left">已发货总数</div>
                            <a-tooltip placement="top">
                                <template #title>
                                <span>单位：件</span>
                                </template>
                                <div class="top_one_in_top_right"><InfoCircleOutlined /></div>
                            </a-tooltip>
                        </div>
                        <div class="top_one_in_money"><SendOutlined style=" font-size: 22px" /> {{data.yaerData.yaerDeliveredNum}}</div>
                        <div class="top_one_in_bi" id="echartTop3">
                            
                        </div>
                        <div class="top_one_in_foot">占总需发货 {{data.yaerData.yaerTotalProduction!=0?(data.yaerData.yaerDeliveredNum/data.yaerData.yaerTotalProduction*100).toFixed(2):0}}%</div>
                    </a-skeleton>
                </div>
            </div>
        </div>
        <div class="cont">
            <div class="cont_in" ref="contIn">
                <a-skeleton active :loading="data.isSkeletonCont" style="width: 100%;height: 100%">
                    <a-tabs v-model:activeKey="data.activeKey" size="large" style="width: 100%;height: 100%" >
                        <a-tab-pane key="1" tab="订单产件量" style="width: 100%;height: 100%">
                            <div class="cont_in_left" ref="echartCont1Ref" id="echartCont1" :style="'height:'+data.rankingHeight+'px;'" ></div>
                            <div class="cont_in_right" :style="'height:'+data.rankingHeight+'px;'">
                                <div class="cont_in_right_title">订单总需件量排行榜</div>
                                <template v-for="(item,index) in data.rankingData.rankingCont" :key="index">
                                <div class="cont_in_right_one" :style="index==0?'margin-top:10px;':''">
                                    <div class="cont_in_right_one_icon">{{index+1}}</div>
                                    <div class="cont_in_right_one_right">{{item.realName}}<span style="float: right;">{{item.totalProduction}} 件</span></div>
                                </div>
                                </template>
                            </div>
                        </a-tab-pane>
                        <!-- <a-tab-pane key="2" tab="月销售额">
                            <div class="cont_in_left" ref="echartCont2Ref" id="echartCont2"></div>
                            <div class="cont_in_right">
                                <div class="cont_in_right_title">销售额排行榜</div>
                                <template v-for="(item,index) in data.rankingList" :key="index">
                                <div class="cont_in_right_one" :style="index==0?'margin-top:10px;':''">
                                    <div class="cont_in_right_one_icon">{{index+1}}</div>
                                    <div class="cont_in_right_one_right">{{item.cont}}<span style="float: right;">￥ {{item.money}}</span></div>
                                </div>
                                </template>
                            </div>
                        </a-tab-pane> -->
                        <template #tabBarExtraContent>
                            <a-radio-group v-model:value="data.rankingType" >
                                <a-radio-button value="month" @click="rankingData(2)">本月</a-radio-button>
                                <a-radio-button value="year" @click="rankingData(1)">本年</a-radio-button>
                            </a-radio-group>
                            <a-range-picker
                                :placeholder="['开始日期','结束日期']" 
                                style="margin-left:10px;width:250px" 
                                v-model:value="data.rankingDate"
                                value-format="YYYY-MM-DD" format="YYYY-MM-DD"
                                @change="handleRanking"
                            />
                        </template>
                    </a-tabs>
                </a-skeleton>
            </div>
        </div>
    </div>
</template>
<script>
//引入图表插件 ecahrt
import * as echarts from 'echarts'
import { reactive,onMounted, ref,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import { InfoCircleOutlined,CaretUpOutlined,CaretDownOutlined,TagsOutlined,SendOutlined,GoldOutlined,ShoppingCartOutlined } from '@ant-design/icons-vue';
import { homeData,homeRankingData } from "@/api/home/home.js"
import { isOK } from "@/utils/illegal.js"
import { getMax } from "@/utils/arrayIsEqual.js"
import { message } from 'ant-design-vue';
export default {
    name: "Layout",
    components:{ InfoCircleOutlined,CaretUpOutlined,CaretDownOutlined,TagsOutlined,SendOutlined,GoldOutlined,ShoppingCartOutlined },
    setup(props){
        const store = useStore();
        // watch(
        //     () => contIn.value.offsetHeight,
        //     (count, prevCount) => {
        //         //动态获取排行榜区域的高度
        //         data.rankingHeight = contIn.value.offsetHeight -72;
        //     }
        // )
        const data = reactive({
            rolesData:store.state.user.roles,
            isSkeletonTop1: false,//控制台 头部方块1 （页面预览状态）
            isSkeletonTop2: false,//控制台 头部方块2 （页面预览状态）
            isSkeletonTop3: false,//控制台 头部方块3 （页面预览状态）
            isSkeletonTop4: false,//控制台 头部方块4 （页面预览状态）
            isSkeletonCont: false,//控制台 图表区域 （页面预览状态）

            yaerData:{
                lastYearTotalProduction:0,
                yaerTotalOrderNum:0,
                yaerTotalProduction:0,
                yaerUndeliveredNum:0,
                yaerDeliveredNum:0
            },

            activeKey: "1",
            rankingType: "year",
            maxMonthOrderNum:0,
            rankingHeight:0,
            rankingDate:[],
            rankingData:{
                xCont:[],
                yCont:[],
                rankingCont:[],
            },

            rankingList:[
                {cont:"销售部：张三",money:"152581.5"},
                {cont:"销售部：李四",money:"156580.5"},
                {cont:"销售部：王五",money:"156220.5"},
                {cont:"销售部：阿六",money:"145238.5"},
                {cont:"销售部：大七",money:"135658.5"},
                {cont:"销售部：歪八",money:"125358.5"},
                {cont:"销售部：最九",money:"115658.5"},
                {cont:"销售部：丁一",money:"105658.5"},
                {cont:"销售部：中二",money:"95658.5"},
                {cont:"销售部：十一",money:"85658.5"},
                {cont:"销售部：啊十二",money:"85650"},
            ]
        });

        //四个区域图表
        const echartInit = (monthTotalOrderNum,monthUndeliveredNum,monthDeliveredNum) =>{
            //年订单总单数 图表
            let myChartTop1 = echarts.init(document.getElementById('echartTop1'));
            // 指定图表的配置项和数据
            let option1 = {
                color: [ '#478ef9'],
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'cross',
                    //     label: {
                    //         backgroundColor: '#6a7985'
                    //     }
                    // }
                },
                legend: {
                    data: [ '月订单单数'],
                    show: false,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                grid: {
                    left: '0%',
                    top:'0%',
                    bottom:'10%',
                    right:'0%',
                    containLabel: false
                },
                xAxis: [
                    {
                        axisLine: { //关键设置，不显示X轴线条
                            show: false
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        splitLine:{
                    　　　　show:false
                    　　}
                    }
                ],
                series: [
                    {
                        name: '月订单总金额',
                        type: 'line',
                        stack: '总量',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(0, 221, 255)'
                            }, {
                                offset: 1,
                                color: 'rgba(77, 119, 255)'
                            }])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: monthTotalOrderNum
                    }
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChartTop1.setOption(option1,true);
            

            //未发货 图表
            let myChartTop2 = echarts.init(document.getElementById('echartTop2'));
            // 指定图表的配置项和数据
            let option2 = {
                color: [ '#478ef9'],
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'cross',
                    //     label: {
                    //         backgroundColor: '#6a7985'
                    //     }
                    // }
                },
                legend: {
                    data: [ '月未发货数'],
                    show: false,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                grid: {
                    left: '0%',
                    top:'0%',
                    bottom:'10%',
                    right:'0%',
                    containLabel: false
                },
                xAxis: [
                    {
                        axisLine: { //关键设置，不显示X轴线条
                            show: false
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        splitLine:{
                    　　　　show:false
                    　　}
                    }
                ],
                series: [
                    {
                        name: '月未发货数',
                        type: 'line',
                        stack: '总量',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(0, 221, 255)'
                            }, {
                                offset: 1,
                                color: 'rgba(77, 119, 255)'
                            }])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: monthUndeliveredNum
                    }
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChartTop2.setOption(option2,true);

            //已发货总数 图表
            let myChartTop3 = echarts.init(document.getElementById('echartTop3'));
            // 指定图表的配置项和数据
            let option3 = {
                color: [ '#478ef9'],
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'cross',
                    //     label: {
                    //         backgroundColor: '#6a7985'
                    //     }
                    // }
                },
                legend: {
                    data: [ '月已发货数'],
                    show: false,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                grid: {
                    left: '0%',
                    top:'0%',
                    bottom:'10%',
                    right:'0%',
                    containLabel: false
                },
                xAxis: [
                    {
                        axisLine: { //关键设置，不显示X轴线条
                            show: false
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                        },
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                        },
                        splitLine:{
                    　　　　show:false
                    　　}
                    }
                ],
                series: [
                    {
                        name: '月已发货数',
                        type: 'line',
                        stack: '总量',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(0, 221, 255)'
                            }, {
                                offset: 1,
                                color: 'rgba(77, 119, 255)'
                            }])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: monthDeliveredNum
                    }
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChartTop3.setOption(option3,true);
        }

        //内容图表
        const echartCont1 = () =>{
            //年订单总额 图表
            let myChartCont1 = echarts.init(document.getElementById('echartCont1'));
            // 指定图表的配置项和数据
            let optionCont1 = {
                color: ['#478ef9'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '2%',
                    top:'0%',
                    containLabel: false
                },
                xAxis: [
                    {
                        axisLine: { //关键设置，不显示X轴线条
                            show: true,
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        type: 'category',
                        data: data.rankingData.xCont,
                        axisTick: {
                            show: false,
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        axisLine: { //关键设置，不显示X轴线条
                            show: false,
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '产件',
                        type: 'bar',
                        barWidth: '60%',
                        data: data.rankingData.yCont
                    }
                ]
            };
            // 使用刚指定的配置项和数据显示图表。
            myChartCont1.setOption(optionCont1);
        }

        // const echartCont2 = () =>{
        //     //年订单总额 图表
        //         let myChartCont2 = echarts.init(document.getElementById('echartCont2'));
        //         // 指定图表的配置项和数据
        //         let optionCont2 = {
        //             color: ['#478ef9'],
        //             tooltip: {
        //                 trigger: 'axis',
        //                 axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //                     type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        //                 }
        //             },
        //             grid: {
        //                 left: '0%',
        //                 right: '0%',
        //                 bottom: '2%',
        //                 top:'0%',
        //                 containLabel: false
        //             },
        //             xAxis: [
        //                 {
        //                     axisLine: { //关键设置，不显示X轴线条
        //                         show: true,
        //                     },
        //                     axisTick: { //关键设置，坐标轴刻度也不显示
        //                         show: false,
        //                     },
        //                     splitLine: {
        //                         show: false,
        //                     },
        //                     axisLabel: {
        //                         show: false,
        //                     },
        //                     type: 'category',
        //                     data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月','12月'],
        //                     axisTick: {
        //                         show: false,
        //                         alignWithLabel: true
        //                     }
        //                 }
        //             ],
        //             yAxis: [
        //                 {
        //                     axisLine: { //关键设置，不显示X轴线条
        //                         show: false,
        //                     },
        //                     axisTick: { //关键设置，坐标轴刻度也不显示
        //                         show: false,
        //                     },
        //                     splitLine: {
        //                         show: false,
        //                     },
        //                     axisLabel: {
        //                         show: false,
        //                     },
        //                     type: 'value'
        //                 }
        //             ],
        //             series: [
        //                 {
        //                     name: '销售额',
        //                     type: 'bar',
        //                     barWidth: '60%',
        //                     data: [50420, 70500, 72400, 52040, 75200, 82100, 22050, 72400, 52040, 75200, 52100, 52050]
        //                 }
        //             ]
        //         };
        //         // 使用刚指定的配置项和数据显示图表。
        //         myChartCont2.setOption(optionCont2);
        // }

        const top = ref();
        const contIn = ref();
        const echartCont1Ref = ref();
        onMounted(()=>{
            console.log(data.rolesData)
            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    homeData().then(res =>{
                        data.yaerData={
                            lastYearTotalProduction:res.data.total.lastYearTotalProduction || 0,
                            yaerTotalOrderNum:res.data.total.yaerTotalOrderNum || 0,
                            yaerTotalProduction:res.data.total.yaerTotalProduction || 0,
                            yaerUndeliveredNum:res.data.total.yaerUndeliveredNum || 0,
                            yaerDeliveredNum:res.data.total.yaerDeliveredNum || 0,
                        }
                        if(isOK(res.data.monthTotalOrderNum)){
                            //表示是几月订单最多
                            data.maxMonthOrderNum = getMax(res.data.monthTotalOrderNum)+1;
                        }else{
                            data.maxMonthOrderNum = "";
                        }
                        
                        echartInit(res.data.monthTotalOrderNum,res.data.monthUndeliveredNum,res.data.monthDeliveredNum);
                    })
                    
                    //动态获取排行榜区域的高度
                    data.rankingHeight = contIn.value.offsetHeight -72;

                    // echartCont1();
                    rankingData(1);
                },1);
                
                
                // 绑定监听事件 用于控制窗口大小变化时 图表也重新加载
                window.addEventListener("resize", () => {
                    echarts.init(document.getElementById('echartTop1')).resize();
                    echarts.init(document.getElementById('echartTop2')).resize();
                    echarts.init(document.getElementById('echartTop3')).resize();
                });
            });
    	});

        //排行榜tab数据查询
        const rankingData = (type)=>{
            if(type==1){
                homeRankingData({type:1}).then(res =>{
                    data.rankingData.xCont = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
                    data.rankingData.yCont = res.data.list || [];
                    data.rankingData.rankingCont = res.data.ranking || [];
                    echartCont1();
                })
            }
            if(type==2){
                homeRankingData({type:2}).then(res =>{
                    data.rankingData.xCont = res.data.xCont  || [];
                    data.rankingData.yCont = res.data.list  || [];
                    data.rankingData.rankingCont = res.data.ranking  || [];
                    echartCont1();
                })
            }
        }

        //排行榜查询
        const handleRanking = value=>{
            if(isOK(value)){
                homeRankingData({type:3,strDate:value[0],endDate:value[1]}).then(res =>{
                    data.rankingData.xCont = res.data.xCont  || [];
                    data.rankingData.yCont = res.data.list  || [];
                    data.rankingData.rankingCont = res.data.ranking  || [];
                    echartCont1();
                })
            }else{
                message.warning("请选择时间段");
            }
        }

        //排行榜切换tab
        // const rankingChange = (activeKey)=>{
        //     if(activeKey==2){
        //         //延迟加载
        //         setTimeout(() =>{
        //             echartCont2();
        //         },200);
        //     }
        // }

        return {
            data,
            echartInit,
            rankingData,
            handleRanking,
            // rankingChange,
            echartCont1,
            // echartCont2,
            top,
            contIn,
            echartCont1Ref,
        }
    }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #f0f2f5;
}
.top{
    width: 100%;
    height: 182px;
}
.top_one{
    width: calc((100% - 60px)/4);
    height: 182px;
    float: left;
    border-radius:2px;
    background-color: #fff;
    margin-right: 20px;
    overflow: hidden;
}
.top_one_in{
    margin: 15px;
    width: calc(100% - 30px);
    height: 152px;
}
.top_one_in_top{
    width: 100%;
    height: 14px;
}
.top_one_in_top_left{
    width: calc(100% - 14px);
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    color: #7c7c7c;
    float: left;
}
.top_one_in_top_right{
    width: 14px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    color: #7c7c7c;
    float: left;
}
.top_one_in_money{
    width: 100%;
    height: 60px;
    font-size: 26px;
    line-height: 60px;
    color: #595959;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.top_one_in_bi{
    width: 100%;
    height: 44px;
    font-size: 14px;
    line-height: 44px;
    color: #7c7c7c;
    border-bottom: solid 1px #eee;
    margin-top: 5px;
}
.top_one_in_foot{
    width: 100%;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    color: #7c7c7c;
    margin-top: 14px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.cont{
    width: 100%;
    height: calc(100% - 202px);
    margin-top: 20px;
    border-radius:2px;
    background-color: #fff;
    overflow: hidden;
}
.cont_in{
    margin: 15px 30px;
    width: calc(100% - 60px);
    height: calc(100% - 30px);;
}
.cont_in_left{
    float: left;
    width: calc(100% - 414px);
    height: 500px;
}
.cont_in_right{
    float: left;
    width: 379px;
    height: 520px;
    margin-left: 35px;
    overflow-x: hidden;
    overflow-y: auto;
}
.cont_in_right_title{
    width: 100%;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
}
.cont_in_right_one{
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    margin-top:21px;
}
.cont_in_right_one_icon{
    width: 20px;
    height: 20px;
    float: left;
    border-radius: 50%;
    background-color: #478ef9;
    text-align: center;
    color: #fff;
}
.cont_in_right_one_right{
    width: calc(100% - 40px);
    height: 20px;
    float: left;
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
}

/*滚动条样式*/
  .cont_in_right::-webkit-scrollbar {/*滚动条整体样式*/
    width: 0px;     /*高宽分别对应横竖滚动条的尺寸*/
  }
  .cont_in_right::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    /*border-radius: 2px;*/
    -webkit-box-shadow: inset 0 0 0px rgba(63, 116, 254,0.5);
    background: rgba(63, 116, 254,0.5);
  }
  .cont_in_right::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,.1);
    border-radius: 0;
    background: rgba(0,0,0,.1);
  }
</style>