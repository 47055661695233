import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/order/getChairmanList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/order/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//修改订单产品预计完成时间
export function upOneOrderProductPlanDate(data){
    return service.request({
        url:"/order/upOneOrderProductPlanDate",
        method: "post",
        data:Qs.stringify(data),
    });
}

//通过
export function chairmanReview(data){
    return service.request({
        url:"/order/chairmanReview",
        method: "post",
        data:Qs.stringify(data),
    });
}

//驳回
export function chairmanReject(data){
    return service.request({
        url:"/order/chairmanReject",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
export function scrapChairman(data){
    return service.request({
        url:"/order/scrapChairman",
        method: "post",
        data:Qs.stringify(data),
    });
}