import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/quality/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//入库质检制表
export function inAdd(data){
    return service.request({
        url:"/quality/inAdd",
        method: "post",
        contentType: "application/json",
        data
    });
}

//出库质检制表
export function outAdd(data){
    return service.request({
        url:"/quality/outAdd",
        method: "post",
        contentType: "application/json",
        data
    });
}

//报废
export function remove(data){
    return service.request({
        url:"/quality/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}
