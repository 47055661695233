<template>
      <div id="header_left" @click="colapsedButton">
          <MenuUnfoldOutlined v-if="data.isClose" />
          <MenuFoldOutlined v-else />
      </div>
      <div id="header_right">
        <!-- 用户 -->
        <div class="header_right_username">
          <a-dropdown placement="bottomRight">
              <div class="header_right_username_in">
              <div class="header_right_username_in_left">
                  <a-avatar style="float: left;" size="small" :src="data.user.salt" />
              </div>
              <div class="header_right_username_in_right">{{data.user.nickName}}</div>
              </div>
              
              <!-- <div style="float: left;"><a class="ant-dropdown-link" @click="e => e.preventDefault()"> Teng </a></div> -->
              <template #overlay>
              <a-menu @click="onMenuClick" class="dropdown">
                  <a-menu-item key="0" @click="toUpNickname">
                  <UserOutlined /> 修改信息
                  </a-menu-item>
                  <a-menu-item key="1" @click="toAvatar">
                  <CodeOutlined /> 修改头像
                  </a-menu-item>
                  <a-menu-item key="2" @click="toUpPassword">
                  <UnlockOutlined /> 修改密码
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="3" @click="outSystem">
                  <PoweroffOutlined /> 退出系统
                  </a-menu-item>
              </a-menu>
              </template>
          </a-dropdown>
        </div>
          <!-- 消息 -->
            <a-dropdown placement="bottomCenter" :trigger="['click']" v-model:visible="data.isMes">
              <div class="header_right_ld" @click="toMessage">
                <a-badge :count="data.unCountTotal" :overflow-count="99" class="top_style" >
                    <BellOutlined />
                </a-badge>
              </div>
              
              <template #overlay>
                <div class="mes_cont">
                  <a-skeleton active :loading="data.isSkeletonMessage">
                  <template v-for="(item, index) in data.messageList3" :key="index+1">
                    <div class="mes_cont_one">
                      <div class="mes_cont_one_img" v-if="item.toName==='系统'" style="background-color: #3f74fe;"><RobotOutlined style="color:#fff" /></div>
                      <div class="mes_cont_one_img" v-if="item.toName!=='系统'" style="background-color: #fe5d58;"><MessageOutlined style="color:#fff" /></div>
                      <div class="mes_cont_one_text">
                        <div class="mes_cont_one_text_top">{{item.cont}}</div>
                        <div class="mes_cont_one_text_foot">{{item.gmtCreate}} {{item.toName}}</div>
                      </div>
                    </div>
                  </template>
                  </a-skeleton>
                  <div class="mes_cont_foot"><a-button type="primary" block @click="toMessageAll">历史消息</a-button></div>
                </div>
              </template>
            </a-dropdown>
      </div>
<!-- 修改密码对话框 -->
<a-modal
  title="修改密码"
  v-model:visible="data.isUpPassword"
  :confirm-loading="data.upPasswordLoading"
  @ok="handleUpPassword"
  @cancel="handleCloseUpPassword"
  okText = "确认"
  cancelText= "取消"
  width="420px"
  :maskClosable="false"
>
  <a-form
    ref="upPasswordRef"
    :model="data.upPasswordData"
    :rules="data.upPasswordRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:16}"
  >

      <a-form-item has-feedback label="原密码" name="oldPassword">
        <a-input v-model:value="data.upPasswordData.oldPassword" autocomplete="off" allow-clear />
      </a-form-item>

      <a-form-item has-feedback label="新密码" name="newPassword">
        <a-input v-model:value="data.upPasswordData.newPassword" autocomplete="off" allow-clear />
      </a-form-item>

      <a-form-item has-feedback label="确认密码" name="againPassword">
        <a-input v-model:value="data.upPasswordData.againPassword" autocomplete="off" allow-clear />
      </a-form-item>

  </a-form>
</a-modal>
<!-- 修改用户昵称对话框 -->
<a-modal
  title="修改信息"
  v-model:visible="data.isUpNickname"
  :confirm-loading="data.upNicknameLoading"
  @ok="handleUpNickname"
  @cancel="handleCloseUpNickname"
  okText = "确认"
  cancelText= "取消"
  width="420px"
  :maskClosable="false"
>
  <a-form
    ref="upNicknameRef"
    :model="data.upNicknameData"
    :rules="data.upNicknameRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:16}"
  >

      <a-form-item has-feedback label="用户昵称" name="nickname">
        <a-input v-model:value="data.upNicknameData.nickname" autocomplete="off" allow-clear />
      </a-form-item>
      <a-form-item has-feedback label="手机号码" name="phone">
        <a-input v-model:value="data.upNicknameData.phone" autocomplete="off" allow-clear />
      </a-form-item>
  </a-form>
</a-modal>
<!-- 更换头像 :footer="null" -->
<a-modal
  title="更换头像"
  v-model:visible="data.isAvatar"
  width="216px"
  @ok="handleUpAvatar"
  @cancel="handleCloseUpAvatar"
  okText = "确定更换"
  cancelText= "取消更换"
  :maskClosable="false"
>
  <div class="avatar_uploader">
    <a-upload
      v-model:file-list="data.fileList"
      name="avatar"
      list-type="picture-card"
      
      :show-upload-list="false"
      action="/erp/user/uploadImgFile"
      :headers="data.headers"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <img v-if="data.avatarUrl" :src="data.avatarUrl" alt="头像" width="94" height="94" />
      <div v-else>
        <loading-outlined v-if="data.avatarLoading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">上传头像</div>
      </div>
    </a-upload>
  </div>
</a-modal>
<!-- 查看历史消息 -->
<a-modal
  title="历史消息"
  v-model:visible="data.isMessageAll"
  width="690px"
  :footer="null"
  :maskClosable="false"
>
  <a-list 
  item-layout="horizontal" 
  :data-source="data.messageTableData" 
  :loading="data.messageTableLoading"
  :locale="{emptyText: '暂无数据'}"
  >
    <template #renderItem="{ item }">
      <a-list-item>
        <a-list-item-meta
          :description="item.gmtCreate+' '+item.toName"
        >
          <template #title>
            <span>{{ item.cont }}</span>
          </template>
          <template #avatar>
            <div class="mes_cont_one_img" v-if="item.toName==='系统'" style="background-color: #3f74fe;margin: 4px 0px 0px 0px;"><RobotOutlined style="color:#fff" /></div>
            <div class="mes_cont_one_img" v-if="item.toName!=='系统'" style="background-color: #fe5d58;margin: 4px 0px 0px 0px;"><MessageOutlined style="color:#fff" /></div>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
  <div class="page_size">
    <a-pagination
      :total="data.messageTableTotal"
      :show-total="total => `共 ${total} 条`"
      :page-size="data.messageTablePageSize"
      v-model:current="data.messageTableCurrent"
      class="page_size_in"
      size="middle"
      @change="onChange"
    />
  </div>
</a-modal>
</template>
<script>
import {
  MenuFoldOutlined,MenuUnfoldOutlined,
  UserOutlined,
  CodeOutlined,
  PoweroffOutlined,
  BellOutlined,
  UnlockOutlined,
  RobotOutlined,
  MessageOutlined,
} from '@ant-design/icons-vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { logout } from "@/api/login.js";
import { upPassword,upUser,upAvatar,deleteAvatar } from "@/api/head.js";
import { unMessageTotal,messageList3,messageAll,upUnMessage } from "@/api/message.js"
import { removeToken,getToken } from "@/utils/app.js";
import { reactive,ref, getCurrentInstance,createVNode, onMounted } from 'vue'
import { ExclamationCircleOutlined,PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { Modal,message } from 'ant-design-vue';
// 语言
// import { useI18n } from 'vue-i18n';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
    name: "Aside",
    components:{
        MenuFoldOutlined,
        MenuUnfoldOutlined,
        UserOutlined,
        CodeOutlined,
        PoweroffOutlined,
        BellOutlined,
        UnlockOutlined,
        LoadingOutlined,
        PlusOutlined,
        RobotOutlined,
        MessageOutlined,
    },
    setup(props){

        const { emit } = getCurrentInstance();
        const store = useStore();
        const router = useRouter();
        // 扩展语言
        // const { locale } = useI18n({ useScope: 'global' });
        //校验规则
        let validatePass2 = async (rule, value) => {
          if (value === '') {
            return Promise.reject('确认密码不能为空');
          } else if (value !== data.upPasswordData.newPassword) {
            return Promise.reject("和新密码不一致");
          } else {
            return Promise.resolve();
          }
        };
        const data = reactive({
          headers: {
            'token': getToken()
          },
          lang: [
            { label: "中文", value: "ch" },
            { label: "En", value: "en" },
            { label: "日文", value: "jp" },
          ],
          lang_current: "ch",
          isClose: JSON.parse(localStorage.getItem("isClose")),
          user: store.state.user.user,
          

          //以下为修改密码所需参数
          isUpPassword: false,
          upPasswordLoading: false,
          //修改密码表单数据
          upPasswordData:{
            oldPassword:"",
            newPassword:"",
            againPassword:"",
          },
          //修改密码验证
          upPasswordRules:{
            oldPassword: [{ required: true, message: '原密码不能为空', trigger: 'blur' },{ min: 6, max: 11, message: '原密码长度6到11位', trigger: 'blur' }],
            newPassword: [{ required: true, message: '新密码不能为空', trigger: 'blur' },{ min: 6, max: 11, message: '新密码长度6到11位', trigger: 'blur' },],
            againPassword: [{ required: true,validator: validatePass2, trigger: 'blur' }],
          },

          //以下为修改昵称所需参数
          isUpNickname: false,
          upNicknameLoading: false,
          upNicknameData:{
            nickname:"",
            phone:"",
          },
          upNicknameRules:{
            nickname:[{ required: true, message: '用户昵称不能为空', trigger: 'blur' },{ min: 2, max: 10, message: '用户昵称长度2到10位', trigger: 'blur' },],
            phone:[
                    { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                    {
                        validator:(rule,value,callback)=>{
                            const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                            if(!regMobile.test(value)){
                                callback(new Error('手机号码不正确'))
                            }else{
                                callback( )
                            }
                        },
                        trigger:'blur'
                    }
            ],
          },

          //以下更换头像所需参数
          isAvatar: false,
          fileList: [],
          avatarLoading: false,
          avatarUrl: "",
          ossAvatarUrl: "",

          //消息
          unCountTotal:0,
          isMes:false,
          messageList3:[],//消息列表（3条）
          isSkeletonMessage: true,//默认是打开代码演示效果
          isMessageAll: false,//历史消息对话框
          messageTableLoading: false,//历史消息表格加载状态
          messageTableData: [],//历史消息表格数据
          messageTableCurrent: 1,
          messageTablePageSize: 6,
          messageTableTotal: 0,

        });

        //加载时获取当前用户还有多少未读消息
        const toUnMessageTotal = () =>{
          unMessageTotal().then(res =>{
             data.unCountTotal = res.data.total;
          });
        }
        onMounted(() => {
          toUnMessageTotal();
        })

        // 设置语言
        // const toggleLang = (lang) => {
        //     locale.value = lang;
        //     data.lang_current = lang;
        // }

        const colapsedButton = () => {
            data.isClose = !data.isClose;
            localStorage.setItem("isClose",data.isClose)
            emit("colapsed",data.isClose)
        }

        const onMenuClick = () =>{

        }
        //退出系统提示窗口
        const outSystem = () => {
          Modal.confirm({
            title: '退出系统',
            icon: createVNode(ExclamationCircleOutlined),
            content: '您是否确定退出系统',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              logout().then(data => {
                //退出 清除token 
                removeToken();
                //删除全部store和localStorage内容
                store.commit('user/OUT_SYSTEM');
                //清除全部缓存
                localStorage.clear();
                router.push("/login");
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCancel() {},
          });
        };

        //修改昵称-------------------
        const upNicknameRef = ref();
        const toUpNickname = () =>{
          data.isUpNickname = true;
          data.upNicknameData.nickname = data.user.nickName;
          data.upNicknameData.phone = data.user.phone;
        }
        const handleUpNickname = () =>{
          upNicknameRef.value.validate().then(() => {
            data.upNicknameLoading = true;
            upUser({ nickname:data.upNicknameData.nickname,phone:data.upNicknameData.phone }).then(res => {
              data.user.nickName = data.upNicknameData.nickname;
              data.user.phone = data.upNicknameData.phone;
              message.success("修改成功");
              handleCloseUpNickname();

            }).catch((err) => {
              data.upNicknameLoading = false;
            });
          });
        }
        const handleCloseUpNickname = () =>{
          //关闭窗口
          data.isUpNickname = false;
          //取消按钮加载状态
          data.upNicknameLoading = false;
          //重置数据
          data.upNicknameData={
            nickname:"",
            phone:"",
          }
          //重置表单校验
          upNicknameRef.value.resetFields();
        }

        //修改密码---------------------
        const upPasswordRef = ref();
        const toUpPassword = () =>{
          data.isUpPassword = true;
        }
        const handleUpPassword = () =>{
          upPasswordRef.value.validate().then(() => {
            data.upPasswordLoading = true;
            upPassword({ oldPassword:data.upPasswordData.oldPassword,newPassword:data.upPasswordData.newPassword }).then(res => {
              message.success("修改成功");
              handleCloseUpPassword();
              //成功之后退出系统重新登录
              logout().then(data => {
                //退出 清除token 
                removeToken();
                //删除全部store和localStorage内容
                store.commit('user/OUT_SYSTEM');
                //清除全部缓存
                localStorage.clear();
                router.push("/login");
              });
            }).catch((err) => {
              data.upPasswordLoading = false;
            });
          });
        }
        const handleCloseUpPassword = () =>{
          //关闭窗口
          data.isUpPassword = false;
          //取消按钮加载状态
          data.upPasswordLoading = false;
          //重置数据
          data.upPasswordData={
            oldPassword:"",
            newPassword:"",
            againPassword:"",
          }
          //重置表单校验
          upPasswordRef.value.resetFields();
        }

        //更换头像------------------------------------
        const toAvatar = () =>{
          data.isAvatar = true;
        }
        const handleChange = (info) => {
          if (info.file.status === 'uploading') {
            data.avatarLoading = true;
            return;
          }
          if (info.file.status === 'done') {
            //判断回调是否成功  info.file.response.data.url 为oss的地址 data.user.salt为旧头像地址
            if(info.file.response.code===20000){
              data.ossAvatarUrl = info.file.response.data.url;
              //更改控件头像
              getBase64(info.file.originFileObj, (base64Url) => {
                data.avatarUrl = base64Url;
                data.avatarLoading = false;
              });
              
            }else{
              message.error('上传失败');
              data.avatarLoading = false;
            }

            // console.log(info.file.response)
            // console.log(info.file.response.data.url)
            // data.avatarUrl = info.file.response.data.url
            // Get this url from response in real world.
            // getBase64(info.file.originFileObj, (base64Url) => {
            //   data.avatarUrl = base64Url;
            //   data.avatarLoading = false;
            // });
          }
          if (info.file.status === 'error') {
            data.avatarLoading = false;
            message.error('上传失败');
          }
        };
        const beforeUpload = (file) => {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
          if (!isJpgOrPng) {
            message.warning('请上传图片文件(jpg/jpeg/png)');
          }
          const isLt50kb = file.size / 1024  < 50;
          if (!isLt50kb) {
            message.warning('图片不能大于50KB');
          }
          return isJpgOrPng && isLt50kb;
        };
        const handleUpAvatar = () =>{
          //记录原来的头像 用于删除oss上的对应头像
          let oldAvatar = data.user.salt;
          upAvatar({ url:data.ossAvatarUrl }).then(res => {
            //更改头像显示
            data.user.salt = data.ossAvatarUrl;
            message.success("更换成功");

            //关闭对话框 并清除内容
            data.isAvatar = false;
            data.fileList = [];
            data.avatarLoading = false;
            data.avatarUrl = "";
            data.ossAvatarUrl = "";
          });
          deleteAvatar({ url:oldAvatar }).then(res => {

          });
        }
        const handleCloseUpAvatar = () =>{
          data.isAvatar = false;
          data.fileList = [];
          data.avatarLoading = false;
          data.avatarUrl = "";
          //判断是否已经上传过头像
          if(data.ossAvatarUrl!=""){
            //上传过，将其删除
            deleteAvatar({ url:data.ossAvatarUrl }).then(res => {
              data.ossAvatarUrl = "";
            });
          }
        }

        //消息-----------------
        const toMessage = () =>{
          messageList3().then(res => {
            data.messageList3 = res.data.list;
            data.isSkeletonMessage = false;
            let idList = "";
            for(let i = 0;i< res.data.list.length;i++){
              if(res.data.list[i].type==0){
                idList = idList + "," + res.data.list[i].id;
              }
            }
            if(idList !== ""){
              idList = idList.substr(1);
              upUnMessage({idList:idList}).then(resIn =>{
                toUnMessageTotal();
              });
            }
          });
        }

        //打开历史记录对话框
        const toMessageAll = () =>{
          data.messageTableCurrent = 1;
          data.messageTablePageSize = 6;
          data.messageTableTotal = 0;
          data.isMes = false;
          data.isMessageAll = true;
          data.messageTableLoading = true;
          messageAll({page:data.messageTableCurrent,limit:data.messageTablePageSize}).then(res =>{
            data.messageTableData = res.data.list;
            data.messageTableTotal = res.data.total;
            data.messageTableLoading = false;
          })
        }
        //修改每页显示条数
        const onChange = (current, size) =>{
          data.messageTableCurrent = current;
          data.messageTableLoading = true;
          messageAll({page:data.messageTableCurrent,limit:data.messageTablePageSize}).then(res =>{
            data.messageTableData = res.data.list;
            data.messageTableTotal = res.data.total;
            data.messageTableLoading = false;
          })
        }

        return {
            data,
            // toggleLang,
            colapsedButton,
            onMenuClick,
            outSystem,

            upNicknameRef,
            toUpNickname,
            handleUpNickname,
            handleCloseUpNickname,

            upPasswordRef,
            toUpPassword,
            handleUpPassword,
            handleCloseUpPassword,
            
            toAvatar,
            handleChange,
            beforeUpload,
            handleUpAvatar,
            handleCloseUpAvatar,

            toUnMessageTotal,
            toMessage,
            toMessageAll,
            onChange,
        }
    }
}
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
<style lang="scss" scoped>
#header_left{
    float: left;
    width: auto;
    height: 20px;
    font-size: 20px;
    text-align: center;
    color: #032121;
    cursor: pointer;
    margin-left: 22px;
  }
  .header_left:hover{
    color: #1890ff;
  }
  #header_right{
    float: right;
  }
  .header_right_out{
    width: 44px;
    height: 60px;
    float: right;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: #032121;
    margin-right: 6px;
    cursor: pointer;
  }
  .header_right_out:hover{
    background-color: #f9f9f9;
  }
  .header_right_username{
    float: right;
    width: auto;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    margin-right: 6px;
  }
  .header_right_username:hover{
    background-color: #f9f9f9;
  }
  .header_right_username_in{
    height: 60px;
    font-size: 16px;
    line-height: 60px;
    color: #032121;
    .header_right_username_in_left{
      float: left;
      width: 24px;
      height: 24px;
      margin: 18px 6px 18px 10px;
    }
    .header_right_username_in_right{
      float: left;
      width: auto;
      height: 60px;
      line-height: 60px;
      text-align: left;
      margin-right: 10px;
      color: #565656;
      font-size: 15px;
      // font-weight: bold;
      // font-family: '-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol';
    }
  }
  .header_right_ld{
    width: 44px;
    height: 60px;
    float: right;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: #032121;
    cursor: pointer;
  }
  .header_right_ld:hover{
    background-color: #f9f9f9;
  }
  .dropdown{
    width: 140px;
    height: auto;
  }
  .top_style{
    font-size: 18px;
    color: #565656;
  }

  .avatar_uploader{
    width: 102px;
    height: 102px;
    margin: 0 auto;
  }

  .mes_cont{
    padding:10px;
    background: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
  .mes_cont_one{
    width: 300px;
    height: 60px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
  }
  .mes_cont_one_img{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    border-radius: 20px;
    margin: 10px 10px 10px 0px;
    float: left;
  }
  .mes_cont_one_text{
    float: left;
    width: calc(100% - 50px);
    height: 50px;
    margin: 5px 0px;
  }
  .mes_cont_one_text_top{
    width: 100%;
    height: 25px;
    color: rgba(0,0,0,.75);
    font-size: 13px;
    line-height: 25px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .mes_cont_one_text_foot{
    width: 100%;
    height: 25px;
    color: rgba(0,0,0,.45);
    font-size: 12px;
    line-height: 25px;
  }
  .mes_cont_foot{
    width: 300px;
    height: 32px;
  }

  .page_size{
    width: 100%;
    height: 32px;
    margin-top: 15px;
  }
  .page_size_in{
    float: right;
    padding-right: 0;
  }
</style>