<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="提货单号">
                    <a-input v-model:value="data.queryData.take_delivery_id" placeholder="" style="width: 200px" allow-clear/>
                </a-form-item>
                <a-form-item label="产品名称">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.product_id"
                        :loading="data.downProductListLoading"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downProductList" :key="index+1" :label="item.name">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="审核人员">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.review_id"
                        :loading="data.downUserListLoading"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downUserList" :key="index+1" :label="item.realName">{{item.realName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label="发货人">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="children"
                        v-model:value="data.queryData.consignor_id"
                        :loading="data.downUserListLoading"
                        placeholder=""
                        style="width: 140px"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downUserList" :key="index+1">{{item.realName}}</a-select-option>
                    </a-select>
                </a-form-item> -->
                <a-form-item label="制单人">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.producer_id"
                        :loading="data.downUserListLoading"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downUserList" :key="index+1" :label="item.realName">{{item.realName}}</a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label="操作类型">
                    <a-select allow-clear
                        v-model:value="data.queryData.execute"
                        placeholder=""
                        style="width: 140px"
                    >
                        <a-select-option :value="0">未执行</a-select-option>
                        <a-select-option :value="1">已执行</a-select-option>
                    </a-select>
                </a-form-item> -->
                <a-form-item label="是否报废">
                    <a-select allow-clear
                        v-model:value="data.queryData.is_deleted"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="takeDeliveryAdd">制单</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="takeDeliveryRemove">报废</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.review" @click="takeDeliveryReview">审核</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toTableX="false"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                @tableUserDetails="tableUserDetails"
                @tableTakeDetails="tableTakeDetails"
                />
        </div>
    </div>

<!-- 制单 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
  <a-form
    ref="takeModalRef"
    :model="data.takeModalData"
    :rules="data.takeModalRules"
    :labelCol="{span:8}"
    :wrapperCol="{span:16}"
  >
    <!-- <a-row>
        <a-col :span="24">
            <a-form-item label="订单号" name="orderId" :wrapperCol="{ style: 'width: calc(100% - 84px)' }" :labelCol="{style: 'width: 84px'}">
                <a-select 
                        show-search
                        v-model:value="data.modalData.orderId"
                        :loading="data.downOrderListLoading"
                        placeholder=""
                        style="width: 100%"
                        @select="getOrderListSelect"
                    >
                    <a-select-option :value="item.orderId" v-for="(item,index) in data.downOrderList" :key="index+1">
                        {{item.orderId+' 制单人:'+item.producer+' 审核人:'+item.executiveName+','+item.factoryName+","+item.chairmanName+' 要求:'+item.request}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row> -->
    <a-row>
        <a-col :span="24">
            <!-- 这里因为产品可能是重复的所以不能使用产品的id，这里使用了orderProduct表的id，然后循环获取出产品id，防止下拉异常 -->
            <a-form-item label="产品名称" name="productId" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-select allow-clear
                    show-search
                    optionFilterProp="label"
                    v-model:value="data.takeModalData.productId"
                    :loading="data.downProductListLoading"
                    placeholder=""
                    style="width: 100%"
                    @select="getMaxTakeNum"
                >
                    <a-select-option :value="item.id" v-for="(item,index) in data.downProductList" :key="index+1" :label="item.name + ' 共 '+item.num+' '+item.unit + ' 已预约 '+item.appointmentNum+' ' + item.unit + ' 型号:' + item.model +' 尺寸:'+item.size + 'mm 颜色:'+item.color">
                        {{item.name + " 共 "+item.num+" "+item.unit + " 已预约 "+item.appointmentNum+" " + item.unit + " 型号:" + item.model +" 尺寸:"+item.size + "mm 颜色:"+item.color}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="6">
            <a-form-item label="提货数量" name="takeNum" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-input-number style="width:100%" v-model:value="data.takeModalData.takeNum" :precision="0" :min="1" :max="9999999"/>
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item label="提货日期" name="takeTime" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-date-picker :disabled-date="disabledDate" style="width: 100%" v-model:value="data.takeModalData.takeTime" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
            </a-form-item>
        </a-col>
        <a-col :span="10">
            <a-form-item label="提货备注" name="takeRemarks" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input style="width:100%" v-model:value="data.takeModalData.takeRemarks" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
        <a-col :span="2">
            <a-form-item label="" name="" style="text-align: right;" :wrapperCol="{ style: 'width: calc(100% - 0px)' }" :labelCol="{style: 'width: 0px'}">
                <a-button type="primary" ghost @click="onAdd">添加</a-button>
            </a-form-item>
        </a-col>
    </a-row>
    <div class="qualityProductTable">
        <a-table bordered :data-source="data.takeProductList" :columns="takeProductColumns" :pagination="false" rowKey="productName" >
            <template #toOperation="{ record }">
                    <a-popconfirm
                        v-if="data.takeProductList.length"
                        title="是否确定移除?"
                        @confirm="onDelete(record.key)"
                    >
                        <a>移除</a>
                    </a-popconfirm>
            </template>
            <template #inlong="{ text }">
                <div class="long">
                    <a-tooltip :title="text">
                        {{text}}
                    </a-tooltip>
                </div>
            </template>
        </a-table>
    </div>
    <!-- <a-row>
        <a-col :span="12">
            <a-form-item label="提货日期" name="takeTime">
                <a-date-picker style="width: 100%" v-model:value="data.takeModalData.takeTime" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
            </a-form-item>
        </a-col>
    </a-row> -->
    <!-- <a-row>
        <a-col :span="12">
            <a-form-item label="提货日期" name="takeTime">
                <a-date-picker style="width: 100%" v-model:value="data.modalData.takeTime" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item label="提货仓库" name="takeWarehouse">
                <a-input style="width:100%" v-model:value="data.modalData.takeWarehouse" autocomplete="off" allow-clear />
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item label="审核人员" name="reviewId">
                <a-select 
                    show-search
                    v-model:value="data.modalData.reviewId"
                    :loading="data.downUserListLoading"
                    placeholder=""
                    style="width: 100%"
                >
                    <a-select-option :value="item.id" v-for="(item,index) in data.downUserList" :key="index+1">{{item.realName}}</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item label="发货人员" name="consignorId">
                <a-select 
                    show-search
                    v-model:value="data.modalData.consignorId"
                    :loading="data.downUserListLoading"
                    placeholder=""
                    style="width: 100%"
                >
                    <a-select-option :value="item.id" v-for="(item,index) in data.downUserList" :key="index+1">{{item.realName}}</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="备注" name="remarks">
                <a-input style="width:100%" v-model:value="data.modalData.remarks" autocomplete="off" allow-clear/>
            </a-form-item>
        </a-col>
    </a-row> -->
  </a-form>
  <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:8}"
    :wrapperCol="{span:16}"
  >
  <a-row>
        <a-col :span="6">
            <a-form-item label="客户名称" name="customer_name" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-input v-model:value="data.modalData.customer_name" allow-clear/>
            </a-form-item>
        </a-col>
        <a-col :span="6">
            <a-form-item label="客户手机" name="customer_phone" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input v-model:value="data.modalData.customer_phone" allow-clear />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item label="客户地址" name="customer_address" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-input v-model:value="data.modalData.customer_address" allow-clear/>
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
<!-- 用户详情 -->
<a-modal
  title="用户详情"
  v-model:visible="data.isUserDetails"
  @cancel="handleCloseTableUserDetails"
  :maskClosable="false"
  :confirm-loading="data.isUserDetailsLoading"
  :footer="footer"
  width="220px"
>
<a-spin :spinning="data.isUserDetailsLoading">
    <a-form
        ref=""
        :model="data.UserDetailsData"
    >
        <a-row>
            真实姓名：{{data.UserDetailsData.real_name}}
        </a-row>
        <a-row>
            用户昵称：{{data.UserDetailsData.nick_name}}
        </a-row>
        <a-row>
            联系手机：{{data.UserDetailsData.phone}}
        </a-row>
    </a-form>
</a-spin>
    <template #footer>
        <a-button key="back" @click="handleCloseTableUserDetails">取消</a-button>
    </template>
</a-modal>
<!-- 审核 -->
<a-modal
  title="审核提货单"
  v-model:visible="data.isReview"
  width="1100px"
  @cancel="handleCloseReview"
  :maskClosable="false"
  :footer="footer"
>
    <takeDetails :takeDeliveryId="data.takeDeliveryId" v-if="data.isReview" />

    <template #footer>
        <a-button key="cancel" @click="handleCloseReview">取消</a-button>
        <a-popconfirm
            title="您是否确定驳回?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="takeDeliveryReviewReject"
        >
            <a-button key="scrap" type="primary" :loading="data.isReviewLoading">驳回</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定通过?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="takeDeliveryReviewPass"
        >
            <a-button key="pass" type="primary" :loading="data.isReviewLoading">通过</a-button>
        </a-popconfirm>
    </template>
</a-modal>

<!-- 提货单详情 -->
<a-modal
  title="审核提详情"
  v-model:visible="data.isTakeDetails"
  width="1100px"
  @cancel="handleCloseTakeDetails"
  :maskClosable="false"
  :footer="footer"
>
    <div id="takeDetailsPDF">
    <takeDetails  :takeDeliveryId="data.takeDeliveryIdDetails" v-if="data.isTakeDetails" />
    </div>
    <template #footer>
        <a-button key="cancel" @click="handleCloseTakeDetails">取消</a-button>
        <a-button key="pdf" type="primary" @click="handlePDF('takeDetailsPDF','提货单详情')">导出PDF</a-button>
    </template>
</a-modal>
</template>
<script>
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import cTable from "@/components/table"
import takeDetails from "@/components/takeDetails/TakeDetails.vue"
import { getList,userDetails,add,review,remove } from "@/api/take/takeDelivery.js"
import { getYyyyddmm } from "@/utils/time.js"
import { downProduct,downUser,downOrder,downOrderProduct } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getPdf } from "@/utils/htmlToPdf.js"
import moment from 'moment';
export default {
    name: "TakeDelivery",
    components: { cTable,takeDetails },
    setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    },
                },
                {
                    title: '提货单号',
                    width: 200,
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'takeDetails',
                    },
                },
                {
                    title: '客户名称',
                    width: 140,
                    dataIndex: 'customerName',
                    key: 'customer_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户地址',
                    width: 140,
                    dataIndex: 'customerAddress',
                    key: 'customer_address',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户联系号码',
                    width: 140,
                    dataIndex: 'customerPhone',
                    key: 'customer_phone',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建日期',
                    width: 170,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '制单人',
                    width: 92,
                    dataIndex: 'producerName',
                    key: 'producer_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'producerDetails',
                    },
                },
                {
                    title: '审核人员',
                    width: 100,
                    dataIndex: 'reviewName',
                    key: 'review_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'reviewDetails',
                    },
                },
                {
                    title: '提货单状态',
                    width: 140,
                    dataIndex: 'takeState',
                    key: 'take_state',
                    ellipsis: true,
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.takeState){
                            case 0: v = "未审核"; break;
                            case 1: v = "通过"; break;
                            case 2: v = "不通过"; break;
                            case 3: v = "已完成"; break;
                            default:"";      
                        }
                        return v;
                    },
                },
                {
                    title: '是否报废',
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    slots: {
                        customRender: 'isDeleted',
                    },
                    ellipsis: true,
                },
            ];
        });
        //提货产品列表属性
        const takeProductColumns  = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    ellipsis: true,
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 120,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品尺寸(mm)',
                    width: 140,
                    dataIndex: 'productSize',
                    key: 'product_size',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '提货数量',
                    width: 90,
                    dataIndex: 'takeNum',
                    key: 'take_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '提货日期',
                    width: 140,
                    dataIndex: 'takeTime',
                    key: 'take_time',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '提货备注',
                    dataIndex: 'takeRemarks',
                    key: 'take_remarks',
                    ellipsis: true,
                    width: 160,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    ellipsis: true,
                    slots: {
                        customRender: 'toOperation',
                    },
                },
            ]
        })
        
        const data = reactive({
            styleTableHeight:"height:calc(100% - 102px)",
            btnMenu: store.state.user.btnList.takeDelivery,
            downProductList:[],
            downUserList:[],
            downOrderList:[],
            downOrderProductList:[],
            downProductListLoading:false,
            downUserListLoading:false,
            downOrderListLoading:false,
            downOrderProductListLoading:false,
            queryData:{
                take_delivery_id:"",
                product_id:"",
                review_id:"",
                // consignor_id:"",
                producer_id:"",
                // execute:"",
                is_deleted:0,
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下是制单按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            oneProductData:{},//当前选中的产品信息
            takeProductList:[],//提货产品列表
            maxTakeNum:0,//提货单最大提货数量
            maxTakeUnit:"",//最大提货数量单位
            takeModalData:{
                productId:"",
                takeNum:"",
                takeTime:"",
                takeRemarks:"",
            },
            takeModalRules:{
                productId:[{ required: true, message: '请选择产品', trigger: 'change' }],
                takeNum:[{ required: true,type:'number', message: '提货数量不能为空', trigger: 'blur' },
                {
                    validator:(rule,value,callback)=>{
                        // if(value>data.maxTakeNum){
                        //     callback(new Error('最多提货'+data.maxTakeNum+data.maxTakeUnit))
                        // }else 
                        if(value<=0){
                            callback(new Error('数量有误'))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
                takeTime:[{ required: true,message: '提货日期不能为空', trigger: 'change' }],
            },
            modalData:{
                customer_name:"",
                customer_address:"",
                customer_phone:"",
            },
            modalRules:{
                customer_name:[{ required: true, message: '客户名称不能为空', trigger: 'blur' }],
                customer_phone:[
                    { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    { min: 11, max: 11, message: '手机号码为11位数字', trigger: 'blur' },
                    {
                        validator:(rule,value,callback)=>{
                            const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                            if(!regMobile.test(value)){
                                callback(new Error('手机号码不正确'))
                            }else{
                                callback( )
                            }
                        },
                        trigger:'blur'
                    }
                ],
                customer_address:[{ required: true, message: '客户地址不能为空', trigger: 'blur' }],
            },

            //以下为审核 和 提货单详情参数
            takeDeliveryId:"",
            isReviewLoading:false,

            //一下为提货单详情
            takeDeliveryIdDetails:"",
            isTakeDetails:false,

            //以下为用户详情
            isUserDetails:false,
            isUserDetailsLoading:false,
            UserDetailsData:{
                real_name:"",
                nick_name:"",
                phone:"",
            },
        })

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            console.log(selectedRowKeys)
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                take_delivery_id: isOK(cont[0].take_delivery_id)?cont[0].take_delivery_id:"",
                product_id: isOK(cont[0].product_id)?cont[0].product_id:"",
                review_id: isOK(cont[0].review_id)?cont[0].review_id:"",
                // consignor_id: isOK(cont[0].consignor_id)?cont[0].consignor_id:"",
                producer_id: isOK(cont[0].producer_id)?cont[0].producer_id:"",
                // execute: isOK(cont[0].execute)?cont[0].execute:"",
                is_deleted: isOK(cont[0].is_deleted)?cont[0].is_deleted:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.take_delivery_id = "";
            data.queryData.product_id = "";
            data.queryData.review_id = "";
            // data.queryData.consignor_id = "";
            data.queryData.producer_id = "";
            // data.queryData.execute = "";
            data.queryData.is_deleted = 0;
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //产品下拉
        const getDownProduct = ()=>{
            data.downProductListLoading = true;
            downProduct().then(res =>{
                data.downProductList = res.data.list;
                data.downProductListLoading = false;
            })
        }

        //用户下拉
        const getDownUser = ()=>{
            data.downUserListLoading = true;
            downUser().then(res =>{
                data.downUserList = res.data.list;
                data.downUserListLoading = false;
            })
        }

        //新增提货-订单下拉
        // const getDownOrder = ()=>{
        //     data.downOrderListLoading = true;
        //     downOrder().then(res =>{
        //         data.downOrderList = res.data.list;
        //         data.downOrderListLoading = false;
        //     })
        // }

        //制单提货-产品选择后的方法
        const getMaxTakeNum = (id)=>{
            for(let i = 0;i < data.downProductList.length;i++){
                if(data.downProductList[i].id===id){
                    data.maxTakeNum = data.downProductList[i].num;
                    data.maxTakeUnit = data.downProductList[i].unit;
                    data.oneProductData = data.downProductList[i];
                }
            }
            data.takeModalData.takeNum = "";
            data.takeModalData.takeTime = "";
            data.takeModalData.takeRemarks = "";
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);
            //加载产品下拉
            getDownProduct();
            //加载用户下拉
            getDownUser();
            
            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.takeModalData = {
                productId:"",
                takeNum:"",
                takeTime:"",
                takeRemarks:"",
            };
        }

        //按钮-制单
        const takeModalRef = ref();
        const takeDeliveryAdd = ()=>{
            data.modalTitle = "提货单制单";
            data.modalType = "add";
            data.isModal = true;
            //加载产品下拉
            getDownProduct();
            // getDownOrder();
            // getDownUser();
        }

        //按钮-制单-添加
        const onAdd = ()=>{
            takeModalRef.value.validate().then(() => {
                //查询添加的产品中的全部相同的产品 是否超过总库存 data.maxTakeNum
                // let sumNum = 0;//当前列表中的对应产品总数
                // for(let i = 0;i<data.takeProductList.length;i++){
                //     if(data.oneProductData.id===data.takeProductList[i].productId){
                //         sumNum = sumNum + data.takeProductList[i].takeNum;
                //     }
                // }
                // let newListNum = sumNum;//当前列表中对应产品的数量
                // sumNum = sumNum + data.takeModalData.takeNum;
                // //当前列表的这个产品的总数量是否超过 这个产品的总库存 是的话不给添加
                // if(data.maxTakeNum<sumNum){
                //     return message.warning(data.oneProductData.name + "最多还可以添加 " + (data.maxTakeNum-newListNum) + " "+data.maxTakeUnit);
                // }
                //----------------制单可以超库存制单 ， 主要看审核是否通过， 所以不需要验证货品库存了
                const newData = {
                    key: Date.now()+"",
                    productId: data.oneProductData.id || "",
                    productName: data.oneProductData.name || "",
                    productModel: data.oneProductData.model || "",
                    productSize: data.oneProductData.size || "",
                    productWeight: data.oneProductData.weight || "",
                    productColor: data.oneProductData.color || "",
                    productUnit: data.oneProductData.unit || "",
                    takeNum: data.takeModalData.takeNum,
                    takeTime: data.takeModalData.takeTime,
                    takeRemarks: data.takeModalData.takeRemarks,
                };
                //判断产品是否存在
                for(let i = 0;i<data.takeProductList.length;i++){
                    if(newData.productId===data.takeProductList[i].productId){
                        return message.warning("产品不可重复添加");
                    }
                }
                data.takeProductList.push(newData);
                resetAddTakeTableData();
                //重置表单校验
                takeModalRef.value.resetFields();
            })
        }
        //删除提货产品
        const onDelete = key => {
            data.takeProductList = data.takeProductList.filter(item => item.key !== key);
        };
        //重置添加的数据
        const resetAddTakeTableData = ()=>{
            data.oneProductData = {};
            resetModalData();
        }

        //按钮-制单-确定
        const modalRef = ref();
        const handleModal = () =>{
            modalRef.value.validate().then(() => {
                if(data.takeProductList.length<=0){
                    return message.warning("请添加产品");
                }
                data.isModalLoading = true;
                data.takeProductList = data.takeProductList.map((item, index) => {
                    return {
                        productId: item.productId,
                        productName: item.productName,
                        productModel: item.productModel,
                        productSize: item.productSize,
                        productWeight: item.productWeight,
                        productColor: item.productColor,
                        productUnit: item.productUnit,
                        takeNum: item.takeNum,
                        takeTime: item.takeTime,
                        takeRemarks: item.takeRemarks,
                    }
                })
                add({
                    productList:data.takeProductList,
                    customerName:data.modalData.customer_name,
                    customerAddress:data.modalData.customer_address,
                    customerPhone:data.modalData.customer_phone,
                }).then(res =>{
                    message.success(res.data.sms);
                    handleCloseModal();
                    resetTable();
                }).catch((err) => {
                    data.isModalLoading = false;
                });
            })
        }

        //按钮-制单-取消
        const handleCloseModal = () =>{
            data.modalData.customer_name = "";
            data.modalData.customer_address = "";
            data.modalData.customer_phone = "";
            resetAddTakeTableData();
            data.takeProductList = [];
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
            takeModalRef.value.resetFields();
        }

        //按钮-报废
        const takeDeliveryRemove = ()=>{
            if(data.selectedRowKeys.length==1){
                for(let i = 0;i<data.tableData.length;i++){
                    if(data.tableData[i].id === data.selectedRowKeys[0]){
                        if(data.tableData[i].takeState==4){
                            return message.warning("已执行的提货单不可报废");
                        }
                    }
                }
                Modal.confirm({
                        title: '报废提货单',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定报废提货单',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            remove({id:data.selectedRowKeys[0]}).then(res =>{
                                message.success("报废成功");
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时报废多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-审核
        const takeDeliveryReview = ()=>{
            if(data.selectedRowKeys.length==1){
                for(let i = 0;i<data.tableData.length;i++){
                    if(data.tableData[i].id === data.selectedRowKeys[0]){
                        if(data.tableData[i].takeState==0){
                            data.takeDeliveryId = data.tableData[i].takeDeliveryId;
                            data.isReview = true;
                        }else{
                            message.warning("已审核的提货单不可再次审核");
                        }
                    }
                }
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时审核多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-审核-通过
        const takeDeliveryReviewPass = ()=>{
            review({id:data.selectedRowKeys[0],type:1}).then(res =>{
                message.success("审核成功");
                resetTable();
                data.isReviewLoading = false;
                data.isReview = false;
            })
        }

        //按钮-审核-驳回
        const takeDeliveryReviewReject = ()=>{
            review({id:data.selectedRowKeys[0],type:2}).then(res =>{
                message.success("驳回成功");
                resetTable();
                data.isReviewLoading = false;
                data.isReview = false;
            })
        }

        //按钮-审核-取消
        const handleCloseReview = ()=>{
            data.isReview = false;
            data.takeDeliveryId = "";
            data.isReviewLoading = false;
        }

        //表格-用户详情
        const tableUserDetails = (userId)=>{
            data.isUserDetailsLoading = true;
            userDetails({id:userId}).then(res =>{
                data.isUserDetailsLoading = false;
                data.UserDetailsData.real_name = res.data.list.realName;
                data.UserDetailsData.nick_name = res.data.list.nickName;
                data.UserDetailsData.phone = res.data.list.phone;

                data.isUserDetails = true;
            }).catch((err) => {
                data.isUserDetailsLoading = false;
            });
        }

        //表格-用户详情-取消
        const handleCloseTableUserDetails = ()=>{
            data.isUserDetails = false;
            data.isUserDetailsLoading = false;
            data.UserDetailsData = {
                real_name:"",
                nick_name:"",
                phone:"",
            }
        }

        //表格-提货单详情
        const tableTakeDetails = (takeDeliveryId)=>{
            data.takeDeliveryIdDetails = takeDeliveryId;
            data.isTakeDetails = true;
        }

        //表格-提货单详情-取消
        const handleCloseTakeDetails = ()=>{
            data.takeDeliveryIdDetails = "";
            data.isTakeDetails = false;
        }

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //工具-限制可选择的时间日期 (从今天开始)
        const disabledDate = current => {
            // Can not select days before today and today
            return current && current < moment(new Date()).add(-1, 'days');
        };

        //详情-导出PDF
        const handlePDF = (id,name)=>{
            getPdf(id,name);
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            takeProductColumns,
            getTime,
            moment,
            disabledDate,
            handleCloseTableUserDetails,

            getDownProduct,
            getDownUser,
            // getDownOrder,
            getMaxTakeNum,

            takeModalRef,
            modalRef,
            handleModal,
            handleCloseModal,

            takeDeliveryAdd,
            onAdd,
            onDelete,
            resetAddTakeTableData,
            takeDeliveryRemove,
            takeDeliveryReview,
            takeDeliveryReviewPass,
            takeDeliveryReviewReject,
            handleCloseReview,
            tableUserDetails,
            tableTakeDetails,
            handleCloseTakeDetails,

            handlePDF,
        }
    }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.STYLE7 {
    font-size: 16px; 
    font-family: "宋体";
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTable{
    width: 100%;
}
.qualityProductTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>