import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/rawMaterial/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/rawMaterial/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增 后端使用实体类对象接收
export function add(data){
    return service.request({
        url:"/rawMaterial/add",
        contentType: "application/json",
        method: "post",
        data:data,
    });
}

//修改 后端使用实体类对象接收
export function update(data){
    return service.request({
        url:"/rawMaterial/update",
        contentType: "application/json",
        method: "post",
        data:data,
    });
}

//删除
export function remove(data){
    return service.request({
        url:"/rawMaterial/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}