<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="订单编号">
                    <a-input v-model:value="data.queryData.order_id" placeholder="" style="width:200px;" allow-clear/>
                </a-form-item>
                <a-form-item label="质检编号">
                    <a-input v-model:value="data.queryData.quality_id" placeholder="" style="width:200px;" allow-clear/>
                </a-form-item>
                <a-form-item label="提货单号">
                    <a-input v-model:value="data.queryData.take_delivery_id" placeholder="" style="width:200px;" allow-clear/>
                </a-form-item>
                <a-form-item label="产品名称">
                    <a-select 
                    v-model:value="data.queryData.product_id" 
                    placeholder="" 
                    :loading="data.isProductList" 
                    @select="addOrderProduct" 
                    style="width:200px;" 
                    showSearch 
                    optionFilterProp="label">
                        <template v-for="(item) in data.productList" :key="item" >
                            <a-select-option :value="item.id" :label="item.name">{{item.name}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
                <a-form-item label="是否报废">
                    <a-select allow-clear
                        v-model:value="data.queryData.is_deleted"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <!-- <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="qualityInspectionSee">查看</a-button> -->
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.inAdd" @click="qualityInspectionInAdd">入库质检</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.outAdd" @click="qualityInspectionOutAdd">出库质检</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="qualityInspectionOutRemove">报废</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.correct" @click="qualityInspectionCorrect">件数修正</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="false"
                :toInTable="true"
                toInTableName="quality"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                :toPageSize="10"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                @tableQualityDetails="tableQualityDetails"
                />
        </div>
    </div>

<!-- 入库质检 -->
<a-modal
  title="入库质检"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isModalLoading">
    <a-form
    ref="modalRef"
    :model="data.modalData"
    :rules="data.modalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
    <a-row>
        <a-col :span="6">
            <a-form-item label="质检类型" name="type" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-select :disabled="data.tableProductList.length>0"
                        v-model:value="data.modalData.type"
                        placeholder=""
                        style="width: 100%"
                        @select="getDownOrder"
                    >
                    <a-select-option :value="1">生产入库质检</a-select-option>
                    <a-select-option :value="2">退回入库质检</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="18" v-if="data.modalData.type==1">
            <a-form-item label="订单号" name="order_id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-select :disabled="data.tableProductList.length>0"
                        showSearch 
                        optionFilterProp="label"
                        v-model:value="data.modalData.order_id"
                        :loading="data.downOrderListLoading"
                        placeholder=""
                        style="width: 100%"
                        @select="getOrderListSelect"
                    >
                    <a-select-option :value="item.orderId" v-for="(item,index) in data.downOrderList" :key="index+1" :label="item.orderId+' 制单人:'+item.producer+' 审核人:'+item.factoryName+' 要求:'+item.request">
                        {{item.orderId+' 制单人:'+item.producer+' 审核人:'+item.factoryName+' 要求:'+item.request}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="18" v-if="data.modalData.type==2">
            <a-form-item label="退货单" name="delivery_return_id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-select :disabled="data.modalData.type==2 && data.tableProductList.length>0"
                        showSearch 
                        optionFilterProp="label"
                        v-model:value="data.modalData.delivery_return_id"
                        :loading="data.downDeliveryReturnListLoading"
                        placeholder=""
                        style="width: 100%"
                        @select="getDeliveryReturnListtSelect"
                    >
                    <a-select-option :value="item.id" v-for="(item,index) in data.downDeliveryReturnList" :key="index+1" :label="item.id+' 发货单:'+item.deliveryId+' 产品名称:'+item.productName + ' 还需质检 ' + (item.productNum - item.qualityNum) + ' '+item.productUnit">
                        {{item.id+' 发货单:'+item.deliveryId+' 产品名称:'+item.productName + ' 还需质检 ' + (item.productNum - item.qualityNum) + ' '+item.productUnit}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <a-form
    ref="addQualityModalRef"
    :model="data.addQualityModalData"
    :rules="data.addQualityModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
        <a-row>
            <a-col :span="24" v-if="data.modalData.type==1">
                <!-- 这里因为产品可能是重复的所以不能使用产品的id，这里使用了qualityProduct表的id，然后循环获取出产品id，防止下拉异常 -->
                <a-form-item label="产品名称" name="quality_order_product_Id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select :disabled="data.modalType==='see'"
                            show-search
                            optionFilterProp="label"
                            v-model:value="data.addQualityModalData.quality_order_product_Id"
                            :loading="data.downOrderProductListLoading"
                            placeholder=""
                            style="width: 100%"
                            @select="getMaxQualityNum"
                        >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downOrderProductList" :key="index+1" :label="item.productName">
                            {{item.productName+'  还需合格 '+(item.planQuantities-item.qualityNum)+' '+item.productUnit}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <!-- <a-col :span="24" v-if="data.modalData.type==2">
                <a-form-item label="产品名称" name="quality_order_product_Id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select v-model:value="data.addQualityModalData.quality_order_product_Id" placeholder="" :loading="data.isProductList" @select="addOrderProduct" style="width:170px;" showSearch optionFilterProp="children">
                        <template v-for="(item) in data.productList" :key="item">
                            <a-select-option :value="item.id">{{item.name}}</a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
            </a-col> -->
        </a-row>
        <a-row>
            <a-col :span="5">
                <a-form-item label="生产人员" name="production_personnel" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-input v-model:value="data.addQualityModalData.production_personnel" defaultValue="" autocomplete="off" allow-clear :disabled="data.modalData.type==2"/>
                </a-form-item>
            </a-col>
            <a-col :span="5">
                <a-form-item label="质检数量" name="num" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-input-number style="width:100%" v-model:value="data.addQualityModalData.num" :precision="0" :min="1" :max="9999999" :disabled="data.modalData.type==2 && data.tableProductList.length>0"/>
                </a-form-item>
            </a-col>
            <a-col :span="5">
                <a-form-item label="合格数量" name="pass_num" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-input-number style="width:100%" v-model:value="data.addQualityModalData.pass_num" :precision="0" :min="0" :max="9999999" :disabled="data.modalData.type==2 && data.tableProductList.length>0"/>
                </a-form-item>
            </a-col>
            <a-col :span="7">
                <a-form-item label="检测说明" name="explain" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-input v-model:value="data.addQualityModalData.explain" defaultValue="" autocomplete="off" allow-clear :disabled="data.modalData.type==2 && data.tableProductList.length>0"/>
                </a-form-item>
            </a-col>
            <a-col :span="2" v-if="data.modalType!=='see'" >
                <a-form-item label="" name="" style="text-align: right;" :wrapperCol="{ style: 'width: calc(100% - 0px)' }" :labelCol="{style: 'width: 0px'}">
                    <a-button type="primary" ghost @click="onAdd" :disabled="data.modalData.type==2 && data.tableProductList.length>0">添加</a-button>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
    <div class="addTable">
        <a-table bordered :data-source="data.tableProductList" :columns="addColumns" :pagination="false" rowKey="key" >
                <template #toOperation="{ record }">
                    <a-popconfirm
                        v-if="data.tableProductList.length"
                        title="是否确定移除?"
                        @confirm="onDelete(record.key)"
                    >
                        <a>移除</a>
                    </a-popconfirm>
                </template>
                 <template #inlong="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
                </template>
        </a-table>
    </div>
  </a-form>
</a-spin>
</a-modal>

<!-- 出库质检 -->
<a-modal
  title="出库质检"
  v-model:visible="data.isOutModal"
  :confirm-loading="data.isOutModalLoading"
  @ok="handleOutModal"
  @cancel="handleCloseOutModal"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isOutModalLoading">
    <a-form
    ref="outModalRef"
    :model="data.outModalData"
    :rules="data.outModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
    <a-row>
        <a-col :span="6">
            <a-form-item label="质检类型" name="type" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-select :disabled="data.tableOutProductList.length>0"
                        v-model:value="data.outModalData.type"
                        placeholder=""
                        style="width: 100%"
                        @select="getDownTake"
                    >
                    <a-select-option :value="3">出库质检</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :span="18" v-if="data.outModalData.type==3">
            <a-form-item label="提货单" name="take_delivery_id" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                <a-select :disabled="data.tableOutProductList.length>0"
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.outModalData.take_delivery_id"
                        :loading="data.downTakeListLoading"
                        placeholder=""
                        style="width: 100%"
                        @select="getTakeListSelect"
                    >
                    <a-select-option :value="item.takeDeliveryId" v-for="(item,index) in data.downTakeList" :key="index+1" :label="item.takeDeliveryId+' 制单人:'+item.producerName+' 客户:'+item.customerName">
                        {{item.takeDeliveryId+' 制单人:'+item.producerName+' 客户:'+item.customerName}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    </a-form>
    <a-form
    ref="addOutQualityModalRef"
    :model="data.addOutQualityModalData"
    :rules="data.addOutQualityModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:18}"
    >
        <a-row>
            <a-col :span="12" v-if="data.outModalData.type==3">
                <!-- 这里因为产品可能是重复的所以不能使用产品的id，这里使用了take_product表的id，然后循环获取出产品id，防止下拉异常 -->
                <a-form-item label="产品名称" name="quality_take_product_Id" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-select 
                            show-search
                            optionFilterProp="label"
                            v-model:value="data.addOutQualityModalData.quality_take_product_Id"
                            :loading="data.downTakeProductListLoading"
                            placeholder=""
                            style="width: 100%"
                            @select="getMaxTakeQualityNum"
                        >
                        <a-select-option :value="item.id" v-for="(item,index) in data.downTakeProductList" :key="index+1" :label="item.name">
                            {{item.name+'  还需合格 '+(item.takeNum-item.qualityNum)+' '+item.unit}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12">
                <a-form-item label="入库单" name="io_warehouse_id" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-select 
                            show-search
                            optionFilterProp="label"
                            v-model:value="data.addOutQualityModalData.io_warehouse_id"
                            :loading="data.downIoWarehouseListLoading"
                            placeholder=""
                            style="width: 100%"
                            @select="getMaxIoQualityNum"
                        >
                        <a-select-option :value="item.ioId" v-for="(item,index) in data.downIoWarehouseList" :key="index+1" :label="'入库单:'+item.ioId+'  库存还有 '+(item.num-item.useNum)">
                            {{'入库单:'+item.ioId+'  库存还有 '+(item.num-item.useNum)}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="5">
                <a-form-item label="质检数量" name="num" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-input-number style="width:100%" v-model:value="data.addOutQualityModalData.num" :precision="0" :min="1" :max="9999999" :disabled="data.modalType==='see'"/>
                </a-form-item>
            </a-col>
            <a-col :span="5">
                <a-form-item label="合格数量" name="pass_num" :wrapperCol="{ style: 'width: calc(100% - 82px)' }" :labelCol="{style: 'width: 82px'}">
                    <a-input-number style="width:100%" v-model:value="data.addOutQualityModalData.pass_num" :precision="0" :min="0" :max="9999999" :disabled="data.modalType==='see'"/>
                </a-form-item>
            </a-col>
            <a-col :span="12">
                <a-form-item label="检测说明" name="explain" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-input v-model:value="data.addOutQualityModalData.explain" defaultValue="" autocomplete="off" allow-clear :disabled="data.modalType==='see'"/>
                </a-form-item>
            </a-col>
            <a-col :span="2" v-if="data.modalType!=='see'" >
                <a-form-item label="" name="" style="text-align: right;" :wrapperCol="{ style: 'width: calc(100% - 0px)' }" :labelCol="{style: 'width: 0px'}">
                    <a-button type="primary" ghost @click="onOutAdd">添加</a-button>
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
    <div class="addTable">
        <a-table bordered :data-source="data.tableOutProductList" :columns="addOutColumns" :pagination="false" rowKey="key" >
                <template #toOperation="{ record }">
                    <a-popconfirm
                        v-if="data.tableOutProductList.length"
                        title="是否确定移除?"
                        @confirm="onOutDelete(record.key)"
                    >
                        <a>移除</a>
                    </a-popconfirm>
                </template>
                 <template #inlong="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
                </template>
        </a-table>
    </div>
</a-spin>
</a-modal>

<!-- 质检单详情 -->
<a-modal
  title="质检单详情"
  v-model:visible="data.isQualityDetails"
  width="1100px"
  @cancel="handleCloseQualityDetails"
  :maskClosable="false"
  :footer="footer"
>
    <div id="qualityDetailsPDF">
        <QualityDetails :qualityId="data.qualityIdDetails" v-if="data.isQualityDetails" />
    </div>
    <template #footer>
        <a-button key="cancel" @click="handleCloseQualityDetails">取消</a-button>
        <a-button key="pdf" type="primary" @click="handlePDF('qualityDetailsPDF','质检单详情')">导出PDF</a-button>
    </template>
</a-modal>

<!-- 件数修正 -->
<a-modal
  title="件数修正"
  v-model:visible="data.isCorrect"
  @cancel="handleCloseCorrect"
  :maskClosable="false"
  :confirm-loading="data.isCorrectLoading"
  :footer="footer"
  width="1200px"
>
<a-spin :spinning="data.isCorrectLoading">
    <a-row>
        <a-col :span="24">
            <a-form-item label="订单号" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                <a-select
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.correct_order_id"
                        :loading="data.downOrderAllListLoading"
                        placeholder=""
                        style="width: 100%"
                        @select="getCorrectListSelect"
                    >
                    <a-select-option :value="item.orderId" v-for="(item,index) in data.downOrderAllList" :key="index+1" :label="item.orderId+' 制单人:'+item.producer+' 制单时间:'+item.gmtCreate+' 审核人:'+item.factoryName+' 要求:'+item.request">
                        {{item.orderId+' 制单人:'+item.producer+' 制单时间:'+item.gmtCreate+' 审核人:'+item.factoryName+' 要求:'+item.request}}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <a-table bordered :data-source="data.orderCorrectTableData" :columns="orderColumns" :pagination="false" rowKey="id">
            <template #planQuantitiesData="{ text, record }">
                <div class="editable-cell">
                    <div v-if="data.editableData?data.editableData.id===record.id:false" class="editable-cell-input-wrapper">
                        <a-input-number v-model:value="data.editableData.planQuantities" :precision="0" :min="1" :max="9999999"/>
                        <!-- <a-date-picker v-model:value="data.editableData.planQuantities" value-format="YYYY-MM-DD" format="YYYY-MM-DD" @pressEnter="numSave(record.id)" /> -->
                        <check-outlined class="editable-cell-icon-check" @click="numSave(record.id)" />
                        <close-outlined class="editable-cell-icon-close" @click="numClose()" />
                    </div>
                    <div v-else class="editable-cell-text-wrapper">
                        {{text}}
                        <edit-outlined class="editable-cell-icon" @click="numEdit(record.id)" />
                    </div>
                </div>
            </template>
            <template #long="{ text }">
                    <div class="long">
                        <a-tooltip :title="text">
                            {{text}}
                        </a-tooltip>
                    </div>
            </template>
        </a-table>
    </div>
</a-spin>
    <template #footer>
        <a-button key="back" @click="handleCloseCorrect">取消</a-button>
    </template>
</a-modal>
</template>
<script>
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import { useStore } from 'vuex'
import cTable from "@/components/table"
import { getList,inAdd,outAdd,remove } from "@/api/quality/qualityInspection.js"
import { orderIdTodetails,upOneOrderProductplanQuantities } from "@/api/order/orderAll.js"
import { isOK } from "@/utils/illegal.js"
import { downQualityOrderProduct,downQualityOrder,downQualityOrderAll,downProduct,downQualityTake,downQualityTakeProduct,downProductInWarehouse,downDeliveryReturn } from "@/api/down.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined,CheckOutlined,CloseOutlined, EditOutlined } from '@ant-design/icons-vue';
import QualityDetails from "@/components/QualityDetails"
import { getPdf } from "@/utils/htmlToPdf.js"
import { cloneDeep } from 'lodash-es';
export default {
    name: "QualityInspection",
    components: { cTable,QualityDetails,CheckOutlined,CloseOutlined, EditOutlined },
    setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
    //             {
    //                 title: '序号',
    //                 width: 60,
    //                 align:"center",
    //                 customRender: (text) => {
    //                     return text.index + 1;
    //                 }
    //             },
                {
                    title: '质检编号',
                    width: 240,
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'qualityDetails',
                    },
                },
                {
                    title: '订单编号',
                    width: 200,
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '提货单号',
                    width: 200,
                    dataIndex: 'takeDeliveryId',
                    key: 'take_delivery_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '质检类型',
                    width: 120,
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.type){
                            case 1: v = "生产入库质检"; break;
                            case 2: v = "退货入库质检"; break;
                            case 3: v = "出库质检"; break;
                            default:"";      
                        }
                        return v;
                    }
                },
                {
                    title: '是否执行',
                    width: 120,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_implement' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'isImplement',
                    key: 'is_implement',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isImplement?"已执行":"未执行";
                    }
                },
                {
                    title: '是否报废',
                    width: 120,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_deleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    slots: {
                        customRender: 'isDeleted',
                    },
                },
                {
                    title: '创建日期',
                    width: 200,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '更新日期',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                }
            ];
        });
        //入库质检制单的产品添加的表格列属性
        const addColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 110,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '生产人员',
                    width: 140,
                    dataIndex: 'productionPersonnel',
                    key: 'production_personnel',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 120,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 140,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    width: 140,
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    ellipsis: true,
                    slots: {
                        customRender: 'toOperation',
                    },
                },
            ]
        })
        //出库质检制单的产品添加的表格列属性
        const addOutColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '入库单',
                    width: 100,
                    dataIndex: 'ioWarehouseId',
                    key: 'io_warehouse_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品名称',
                    width: 120,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 110,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 100,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 120,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 140,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    width: 140,
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    ellipsis: true,
                    slots: {
                        customRender: 'toOperation',
                    },
                },
            ]
        })
        //订单产品的表格列属性
        const orderColumns = computed(() => {
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 160,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品型号',
                    width: 90,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '尺寸',
                    width: 100,
                    dataIndex: 'productSize',
                    key: 'product_size',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品重量(kg)',
                    width: 120,
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品颜色',
                    width: 90,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计量单位',
                    width: 90,
                    dataIndex: 'productUnit',
                    key: 'product_unit',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划生产数',
                    width: 140,
                    dataIndex: 'planQuantities',
                    key: 'plan_quantities',
                    ellipsis: true,
                    slots: {
                        customRender: 'planQuantitiesData',
                    },
                },
                {
                    title: '未质检',
                    width: 80,
                    dataIndex: 'qualityNum',
                    key: 'quality_num',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.planQuantities - text.record.qualityNum;
                    },
                },
                {
                    title: '已质检',
                    width: 80,
                    dataIndex: 'qualityNum',
                    key: 'quality_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '计划完成日期',
                    dataIndex: 'planDate',
                    key: 'plan_date',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                }
            ]
        })
        
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.qualityInspection,
            isProductList:false,//加载产品下拉
            downOrderListLoading:false,//加载订单下拉
            downOrderAllListLoading:false,//加载全部订单下拉
            downOrderProductListLoading:false,//加载订单中的产品
            downTakeProductListLoading:false,//加载提货单未质检的产品下拉
            downDeliveryReturnListLoading:false,//加载退货单下拉
            downDeliveryReturnList:[],
            productList:[],//产品下拉
            downOrderList:[],//订单下拉
            downOrderAllList:[],//全部订单下拉
            downOrderProductList:[],//订单产品下拉
            downTakeProductList:[],//提货单未质检的产品下拉
            queryData:{
                order_id:"",
                quality_id:"",
                take_delivery_id:"",
                product_id:"",
                is_deleted:0,
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,
            //以下为质检单详情
            isQualityDetails:false,
            qualityIdDetails:"",

            //以下为入库质检参数
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            modalData:{
                type:"",
                order_id:"",
                delivery_return_id:"",
            },
            modalRules:{
                type:[{ required: true,type:'number', message: '请选择类型', trigger: 'change' }],
                order_id:[{ required: true, message: '请选择订单', trigger: 'change' }],
                delivery_return_id:[{ required: true, message: '请选择订单', trigger: 'change' }],
            },
            selectProduct:{},//选中的单个质量检测的产品数据
            tableProductList:[],//选中的产品table列表
            maxQualityNum:0,
            maxQualityUnit:"",
            qualityProductId:"",//退货入库记录产品id使用
            qualityReturnSelect:{},//选中的退货单数据
            addQualityModalData:{
                quality_order_product_Id:"",
                production_personnel:"",
                num:"",
                pass_num:"",
                explain:"",
            },
            addQualityModalRules:{
                quality_order_product_Id:[{ required: true, message: '请选择产品', trigger: 'change' }],
                production_personnel:[{ required: true, message: '生产人员不能为空', trigger: 'blur' }],
                num:[{ required: true,type:'number', message: '质检数量不能为空', trigger: 'blur' },
                {
                    validator:(rule,value,callback)=>{
                        if(value<data.addQualityModalData.pass_num){
                            callback(new Error('不得小于质检合格数'))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
                pass_num:[{ required: true,type:'number', message: '合格数量不能为空', trigger: 'blur' },{
                    validator:(rule,value,callback)=>{
                        if(value>data.addQualityModalData.num){
                            callback(new Error('不能超过质检总数'));
                        }else if(value>data.maxQualityNum){
                            callback(new Error('最大合格数 '+data.maxQualityNum+' '+data.maxQualityUnit));
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
            },

            //以下为出库质检参数
            isOutModal: false,
            isOutModalLoading: false,
            downTakeListLoading: false,
            outModalData:{
                type:"",
                take_delivery_id:""
            },
            outModalRules:{
                type:[{ required: true,type:'number', message: '请选择类型', trigger: 'change' }],
                take_delivery_id:[{ required: true, message: '请选择提货单', trigger: 'change' }],
            },
            selectOutProduct:{},//选中的单个质量检测的产品数据
            tableOutProductList:[],//选中的产品table列表
            maxOutQualityNum:0,//提货单中的产品所需数量
            maxIoQualityNum:0,//入库单中的最大可使用数量
            maxOutQualityUnit:"",
            downIoWarehouseListLoading:false,
            downIoWarehouseList:[],
            addOutQualityModalData:{
                quality_take_product_Id:"",
                io_warehouse_id:"",
                num:"",
                pass_num:"",
                explain:"",
            },
            addOutQualityModalRules:{
                quality_take_product_Id:[{ required: true, message: '请选择产品', trigger: 'change' }],
                io_warehouse_id:[{ required: true, message: '请选择入库单', trigger: 'change' }],
                num:[{ required: true,type:'number', message: '质检数量不能为空', trigger: 'blur' },
                {
                    validator:(rule,value,callback)=>{
                        if(value>data.maxIoQualityNum){
                            callback(new Error('最多 '+data.maxIoQualityNum+' '+data.maxOutQualityUnit))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
                pass_num:[{ required: true,type:'number', message: '合格数量不能为空', trigger: 'blur' },{
                    validator:(rule,value,callback)=>{
                        if(value>data.addOutQualityModalData.num){
                            callback(new Error('不能超过质检总数'))
                        }else if(value>data.maxOutQualityNum){
                            callback(new Error('最多质检 '+data.maxOutQualityNum+' '+data.maxOutQualityUnit))
                        }else{
                            callback()
                        }
                    },
                    trigger:'blur'
                }],
            },

            //以下为件数修正
            correct_order_id:"",
            isCorrect:false,
            isCorrectLoading:false,
            orderCorrectTableData:[],
            editableData:{},
            orderDetailsTableData:[],
        })

        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                order_id: isOK(cont[0].order_id)?cont[0].order_id:"",
                quality_id: isOK(cont[0].quality_id)?cont[0].quality_id:"",
                take_delivery_id: isOK(cont[0].take_delivery_id)?cont[0].take_delivery_id:"",
                product_id: isOK(cont[0].product_id)?cont[0].product_id:"",
                is_deleted: isOK(cont[0].is_deleted)?cont[0].is_deleted:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.order_id = "";
            data.queryData.quality_id = "";
            data.queryData.take_delivery_id = "";
            data.queryData.product_id = "";
            data.queryData.is_deleted = 0;
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            //加载产品下拉
            getDownProductList();

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                type:"",
                order_id:"",
                delivery_return_id:""
            };
        }

        //获取产品下拉数据
        const getDownProductList = ()=>{
            data.isProductList = true;
            downProduct().then(res =>{
                data.productList = res.data.list;
                data.isProductList = false;
            })
        }

        //新增质量检测单-订单下拉
        const getDownOrder = (value)=>{
            //生产入库
            if(value==1){
                data.downOrderListLoading = true;
                downQualityOrder().then(res =>{
                    data.downOrderList = res.data.list;
                    data.downOrderListLoading = false;
                })
                data.modalData.order_id = "";
                data.addQualityModalData = {
                    quality_order_product_Id:"",
                    production_personnel:"",
                    num:"",
                    pass_num:"",
                    explain:"",
                }
            }
            //退回入库
            if(value==2){
                data.downDeliveryReturnListLoading = true;
                downDeliveryReturn().then(res =>{
                    data.downDeliveryReturnList = res.data.list;
                    data.downDeliveryReturnListLoading = false;
                })
                data.modalData.delivery_return_id = "";
                data.addQualityModalData = {
                    quality_order_product_Id:"",
                    production_personnel:"",
                    num:"",
                    pass_num:"",
                    explain:"",
                }
            }
        }

        //件数修正-全部订单
        const getDownOrderAll = ()=>{
            data.downOrderAllListLoading = true;
            downQualityOrderAll().then(res =>{
                data.downOrderAllList = res.data.list;
                data.downOrderAllListLoading = false;
            })
        }

        //新增质量检测单-订单下拉-选择后的方法
        const getOrderListSelect = (order_id)=>{
            data.downOrderProductListLoading = true;
            downQualityOrderProduct({order_id:order_id}).then(res =>{
                data.downOrderProductList = res.data.list;
                data.addQualityModalData.quality_order_product_Id = "";
                data.downOrderProductListLoading = false;
            })
        }

        //退货入库质检-退货单-选择后的方法
        const getDeliveryReturnListtSelect = (id)=>{
            for(let i = 0;i<data.downDeliveryReturnList.length;i++){
                if(id===data.downDeliveryReturnList[i].id){
                    data.qualityReturnSelect = data.downDeliveryReturnList[i];
                    data.maxQualityNum = data.downDeliveryReturnList[i].productNum - data.downDeliveryReturnList[i].qualityNum;
                    data.maxQualityUnit = data.downDeliveryReturnList[i].productUnit;
                    data.qualityProductId = data.downDeliveryReturnList[i].productId;
                    data.addQualityModalData.num = data.maxQualityNum;
                    data.addQualityModalData.production_personnel = data.downDeliveryReturnList[i].productionPersonnel;
                }
            }
        }

        //新增质量检测单-产品选择后的方法
        const getMaxQualityNum = (id)=>{
            for(let i = 0;i < data.downOrderProductList.length;i++){
                if(data.downOrderProductList[i].id===id){
                    // data.modalData.productId = data.downOrderProductList[i].productId;
                    data.maxQualityNum = data.downOrderProductList[i].planQuantities - data.downOrderProductList[i].qualityNum;
                    data.maxQualityUnit = data.downOrderProductList[i].productUnit;
                    //将选中的数据赋值给selectProduct 表示记录当前选中的
                    data.selectProduct = data.downOrderProductList[i];
                    data.addQualityModalData.num = data.maxQualityNum;
                }
            }
        }

        //按钮-查看
        const modalRef = ref();
        const qualityInspectionSee = ()=>{

        }

        //按钮-新增
        const qualityInspectionInAdd = ()=>{
            data.modalTitle = "入库质检单";
            data.modalType = "add";
            resetModalData();
            data.modalData.type = 1;
            getDownOrder(data.modalData.type);
            data.isModal = true;
        }
        //入库质检添加质检产品
        const addQualityModalRef = ref();
        const onAdd = ()=>{
            addQualityModalRef.value.validate().then(() => {
                if(data.modalData.type==1){
                    const newData = {
                        key: Date.now()+"",
                        orderProductId: data.selectProduct.id,
                        productId: data.selectProduct.productId || "",
                        productName: data.selectProduct.productName || "",
                        productModel: data.selectProduct.productModel || "",
                        productSize: data.selectProduct.productSize || "",
                        productWeight: data.selectProduct.productWeight || "",
                        productColor: data.selectProduct.productColor || "",
                        productUnit: data.selectProduct.productUnit || "",
                        planQuantities: data.selectProduct.planQuantities || "",
                        productionPersonnel: data.addQualityModalData.production_personnel,
                        num:data.addQualityModalData.num,
                        passNum:data.addQualityModalData.pass_num,
                        qualityExplain:data.addQualityModalData.explain,
                    };
                    //判断产品是否存在
                    for(let i = 0;i<data.tableProductList.length;i++){
                        if(newData.productId===data.tableProductList[i].productId){
                            return message.warning("产品不可重复添加");
                        }
                    }
                    data.tableProductList.push(newData);
                    resetAddTableData();
                    //重置表单校验
                    addQualityModalRef.value.resetFields();
                }
                if(data.modalData.type==2){
                    const newData = {
                        key: Date.now()+"",
                        productId: data.qualityReturnSelect.productId || "",
                        productName: data.qualityReturnSelect.productName || "",
                        productModel: data.qualityReturnSelect.productModel || "",
                        productSize: data.qualityReturnSelect.productSize || "",
                        productWeight: data.qualityReturnSelect.productWeight || "",
                        productColor: data.qualityReturnSelect.productColor || "",
                        productUnit: data.qualityReturnSelect.productUnit || "",
                        productionPersonnel: data.addQualityModalData.production_personnel,
                        num:data.addQualityModalData.num,
                        passNum:data.addQualityModalData.pass_num,
                        qualityExplain:data.addQualityModalData.explain,
                    };
                    data.tableProductList.push(newData);
                    
                }
            });
        }
        //入库质检删除质检产品
        const onDelete = key => {
            data.tableProductList = data.tableProductList.filter(item => item.key !== key);
        };
        //入库质检重置添加的数据
        const resetAddTableData = ()=>{
            data.addQualityModalData={
                quality_order_product_Id:"",
                production_personnel:"",
                num:"",
                pass_num:"",
                explain:"",
            }
        }

        //入库质检对话框-确定
        const handleModal = ()=>{
            if(data.tableProductList.length>0){
                data.isModalLoading = true;
                modalRef.value.validate().then(() => {
                    inAdd({
                        qualityProductList:data.tableProductList,
                        order_id:data.modalData.order_id,
                        delivery_return_id:data.modalData.delivery_return_id,
                        type:data.modalData.type,
                    }).then(res =>{
                        message.success("制单成功");
                        handleCloseModal();
                        resetTable();
                    }).catch((err) => {
                        message.warning(res.data.sms);
                        data.isModalLoading = false;
                    });
                })
            }else{
                message.warning("请添加质检产品");
            }
        }

        //入库质检对话框-取消
        const handleCloseModal = ()=>{
            resetAddTableData();
            resetModalData();
            data.tableProductList = [];
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
            //重置表单校验
            addQualityModalRef.value.resetFields();
        }

        //出库质检 获取可用提货单
        const getDownTake = (value)=>{
            //出库质检
            if(value==3){
                data.downTakeListLoading = true;
                downQualityTake().then(res =>{
                    data.downTakeList = res.data.list;
                    data.downTakeListLoading = false;
                })
            }
        }

        //出库质检 选择提货单后 获取提货单中可质检的产品
        const getTakeListSelect = (value)=>{
            data.downTakeProductListLoading = true;
            downQualityTakeProduct({take_delivery_id:value}).then(res =>{
                data.downTakeProductList = res.data.list;
                data.downTakeProductListLoading = false;
            })
        }

        //出库质检-获取入库单下拉数据
        const getDownProductInWarehouse = (product_id)=>{
           data.downIoWarehouseListLoading = true;
           downProductInWarehouse({product_id:product_id}).then(res =>{
               data.downIoWarehouseList = res.data.list;
               data.downIoWarehouseListLoading = false;
           })
        }

        //出库质检-产品选择后的方法
        const getMaxTakeQualityNum = (id)=>{
            let productId = "";
            for(let i = 0;i < data.downTakeProductList.length;i++){
                if(data.downTakeProductList[i].id===id){
                    productId = data.downTakeProductList[i].productId;
                    data.maxOutQualityNum = data.downTakeProductList[i].takeNum - data.downTakeProductList[i].qualityNum;
                    data.maxOutQualityUnit = data.downTakeProductList[i].unit;
                    //将选中的数据赋值给selectProduct 表示记录当前选中的
                    data.selectOutProduct = data.downTakeProductList[i];
                }
            }

            //获取入库单下拉数据
            getDownProductInWarehouse(productId);
        }

        //出库质检-入库单选择后的方法
        const getMaxIoQualityNum = (io_id)=>{
            for(let i = 0;i<data.downIoWarehouseList.length;i++){
                if(io_id===data.downIoWarehouseList[i].ioId){
                    data.maxIoQualityNum = data.downIoWarehouseList[i].num - data.downIoWarehouseList[i].useNum;
                    data.addOutQualityModalData.num = data.maxIoQualityNum;
                    data.addOutQualityModalData.pass_num = "";
                    data.addOutQualityModalData.explain = "";
                }
            }
            
        }

        //出库质检添加质检产品
        const addOutQualityModalRef = ref();
        const onOutAdd = ()=>{
            addOutQualityModalRef.value.validate().then(() => {
                const newData = {
                    key: Date.now()+"",
                    takeProductId: data.selectOutProduct.id,
                    productId: data.selectOutProduct.productId || "",
                    productName: data.selectOutProduct.name || "",
                    productModel: data.selectOutProduct.model || "",
                    productSize: data.selectOutProduct.size || "",
                    productWeight: data.selectOutProduct.weight || "",
                    productColor: data.selectOutProduct.color || "",
                    productUnit: data.selectOutProduct.unit || "",
                    ioWarehouseId : data.addOutQualityModalData.io_warehouse_id,
                    num:data.addOutQualityModalData.num,
                    passNum:data.addOutQualityModalData.pass_num,
                    qualityExplain:data.addOutQualityModalData.explain,
                };
                let maxNum = data.maxOutQualityNum;//当前选中的产品 总质检量
                //判断当前这条记录是否超过了总质检量
                // if(maxNum<newData.passNum){
                //     return message.warning("产品 "+newData.productName+" 超过了质检的总量");
                // }
                let pNum = 0;//记录列表中 当前选中的产品有多少
                for(let i = 0;i<data.tableOutProductList.length;i++){
                    //判断入库单是否已经使用过
                    if(newData.ioWarehouseId===data.tableOutProductList[i].ioWarehouseId){
                        return message.warning("不可重复使用入库单");
                    }
                    //判断该产品是否超过总质检量
                    if(newData.productId===data.tableOutProductList[i].productId){
                        pNum = pNum + data.tableOutProductList[i].passNum;
                    }
                    
                }
                //上面是添加当前列表的数量 下方是添加当前条数量
                pNum = pNum + newData.passNum;
                if(pNum>maxNum){
                    return message.warning("相同产品不得超过提货单所需总数");
                }

                data.tableOutProductList.push(newData);
                resetOutAddTableData();
                //重置表单校验
                addOutQualityModalRef.value.resetFields();
            });
        }
        //出库质检删除质检产品
        const onOutDelete = key => {
            data.tableOutProductList = data.tableOutProductList.filter(item => item.key !== key);
        };
        //出库质检重置添加的数据
        const resetOutAddTableData = ()=>{
            data.addOutQualityModalData={
                quality_take_product_Id:"",
                io_warehouse_id:"",
                num:"",
                pass_num:"",
                explain:"",
            }
        }

        //按钮-出库质检
        const qualityInspectionOutAdd = ()=>{
            resetOutModalData();
            data.isOutModal = true;
            data.outModalData.type = 3;
            getDownTake(data.outModalData.type);
        }

        //按钮-出库质检-确定
        const outModalRef = ref();
        const handleOutModal = ()=>{
            if(data.tableOutProductList.length>0){
                outModalRef.value.validate().then(() => {
                    data.isOutModalLoading = true;
                    outAdd({
                        qualityProductList:data.tableOutProductList,
                        take_delivery_id:data.outModalData.take_delivery_id,
                        type:data.outModalData.type,
                    }).then(res =>{
                        message.success("制单成功");
                        handleCloseOutModal();
                        resetTable();
                    }).catch((err) => {
                        message.warning(res.data.sms);
                        data.isOutModalLoading = false;
                    });
                })
            }else{
                message.warning("请添加质检产品");
            }
        }

        //按钮-出库质检-取消
        const handleCloseOutModal = ()=>{
            resetOutAddTableData();
            resetOutModalData();
            data.tableOutProductList = [];
            data.isOutModal = false;
            data.isOutModalLoading = false;
            //重置表单校验
            outModalRef.value.resetFields();
            //重置表单校验
            addOutQualityModalRef.value.resetFields();
        }

        //重置modal数据
        const resetOutModalData = () =>{
            data.outModalData = {
                type:"",
                take_delivery_id:""
            };
        }

        //按钮-报废
        const qualityInspectionOutRemove = ()=>{
            if(data.selectedRowKeys.length==1){
                let quality_id = "";
                let is_implement = "";
                for(let i = 0;i<data.tableData.length;i++){
                    if(data.tableData[i].id===data.selectedRowKeys[0]){
                        is_implement = data.tableData[i].isImplement;
                        quality_id =  data.tableData[i].qualityId;
                    }
                }
                console.log(is_implement)
                if(is_implement){
                    return message.warning("已执行的质检单无法报废");
                }
                Modal.confirm({
                    title: '报废质检单',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定报废质检单',
                    okText: '确认',
                    cancelText: '取消',
                    //确定
                    onOk() {
                        remove({quality_id:quality_id}).then(res =>{
                            message.success("报废成功");
                            resetTable();
                        })
                    },
                    // 取消
                    onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-订单件数修正
        const qualityInspectionCorrect = ()=>{
            getDownOrderAll();
            data.isCorrect = true;
        }

        //件数修正-查询订单详情
        const getCorrectListSelect = (order_id)=>{
            if(isOK(order_id)){
                orderIdTodetails({order_id:order_id}).then(res =>{
                    //订单产品列表
                    data.orderCorrectTableData = res.data.orderProduct;
                    
                }).catch((err) => {
                    message.warning("数据不存在");
                });
            }else{
                message.warning("数据不存在");
            }
        }

        //关闭-订单件数修正
        const handleCloseCorrect = ()=>{
            data.correct_order_id = "";
            data.isCorrect = false;
            data.isCorrectLoading = false;
            data.orderCorrectTableData = [];
            data.editableData = {};
            data.orderDetailsTableData = [];
            data.downOrderAllList = [];
            data.downOrderAllListLoading = false;
        }

        //订单件数修正-打开修改计划数量
        const numEdit = (id)=>{
            data.editableData = cloneDeep(data.orderCorrectTableData.filter(item => id===item.id)[0]);
        }

        //关闭-开修改计划数量
        const numClose = ()=>{
            data.editableData = {};
        }

        //订单件数修正-提交修改计划数量
        const numSave = (id)=>{
            if(isOK(data.editableData.planQuantities)){
                upOneOrderProductplanQuantities({id:id,planQuantities:parseInt(data.editableData.planQuantities)}).then(res =>{
                    message.success("预计生产数量修改成功");
                    for(let i =0;i<data.orderCorrectTableData.length;i++){
                        if(data.orderCorrectTableData[i].id===id){
                            data.orderCorrectTableData[i].planQuantities = data.editableData.planQuantities;
                        }
                    }
                    data.editableData = {};
                }).catch((err) => {
                    data.editableData = {};
                });
            }else{
                message.warning("预计生产数量不能为空");
            }
        }

        //表格-质检单详情
        const tableQualityDetails = (qualityId)=>{
            data.qualityIdDetails = qualityId;
            data.isQualityDetails = true;
        }

        //表格-质检单详情-取消
        const handleCloseQualityDetails = ()=>{
            data.qualityIdDetails = "";
            data.isQualityDetails = false;
        }

        //详情-导出PDF
        const handlePDF = (id,name)=>{
            getPdf(id,name);
        }

        

        return {
            setSortedInfo,
            columns,
            addColumns,
            addOutColumns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            modalRef,
            getDownProductList,
            getDownOrder,
            getOrderListSelect,
            getDeliveryReturnListtSelect,
            getMaxQualityNum,

            qualityInspectionSee,
            qualityInspectionInAdd,
            addQualityModalRef,
            onAdd,
            onDelete,
            resetAddTableData,
            handleModal,
            handleCloseModal,

            outModalRef,
            addOutQualityModalRef,
            getDownTake,
            getTakeListSelect,
            getDownProductInWarehouse,
            getMaxTakeQualityNum,
            getMaxIoQualityNum,
            qualityInspectionOutAdd,
            onOutAdd,
            onOutDelete,
            resetOutAddTableData,
            resetOutModalData,
            handleOutModal,
            handleCloseOutModal,

            qualityInspectionOutRemove,

            qualityInspectionCorrect,
            getDownOrderAll,
            getCorrectListSelect,
            handleCloseCorrect,
            orderColumns,
            numEdit,
            numClose,
            numSave,

            tableQualityDetails,
            handleCloseQualityDetails,

            handlePDF,
        }
    }
};
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}

.addTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.detailsTable{
    width: 100%;
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check, .editable-cell-icon-close{
    position: absolute;
    right: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: 3px;
  }
  .editable-cell-icon-close{
      right: 0px;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check , .editable-cell-icon-close{
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check, .editable-cell-icon-close:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>