<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="产品名称">
                    <a-select allow-clear
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.queryData.product_id"
                        :loading="data.isIoType"
                        placeholder=""
                        style="width:200px;"
                    >
                        <a-select-option :value="item.id" v-for="(item) in data.productList" :key="item" :label="item.name">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="操作类型">
                    <a-select allow-clear
                        v-model:value="data.queryData.type"
                        placeholder=""
                        :loading="data.isQueryRole"
                        style="width:170px;"
                    >
                        <a-select-option :value="1">生产入库</a-select-option>
                        <a-select-option :value="2">退回入库</a-select-option>
                        <a-select-option :value="3">提货出库</a-select-option>
                        <a-select-option :value="4">报废出库</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.in" @click="ioWarehouseIn">入库</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.out" @click="ioWarehouseOut">出库</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.back" @click="ioWarehouseBack">撤回</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                @ioWarehouseSee="ioWarehouseSee"
                />
        </div>
    </div>
<!-- 入库 -->
<a-modal
  :title="data.modalTitle"
  v-model:visible="data.isModal"
  :confirm-loading="data.isModalLoading"
  @ok="handleModal"
  @cancel="handleCloseModal"
  :ok-button-props="{ disabled: data.isOkButton }"
  :cancel-button-props="{ disabled: data.isCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
>
    <a-spin :spinning="data.isModalLoading">
        <a-form
            ref="modalRef"
            :model="data.modalData"
            :rules="data.modalRules"
            :labelCol="{span:6}"
            :wrapperCol="{span:17}"
        >
        <a-row>
            <a-col :span="5">
                <a-form-item label="入库类型" name="type" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select 
                        v-model:value="data.modalData.type"
                        placeholder=""
                        style="width: 100%"
                        @select="getDownUnImplementQuality"
                    >
                        <a-select-option :value="1">生产入库</a-select-option>
                        <a-select-option :value="2">退回入库</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="19">
                <a-form-item has-feedback label="质检单" name="quality_id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select 
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.modalData.quality_id"
                        placeholder=""
                        style="width: 100%"
                        :loading="data.isDownUnImplementQualityList"
                        @select="getQualityProductList"
                    >
                        <a-select-option :value="item.qualityId" v-for="(item,index) in data.downUnImplementQualityList" :key="index+1" :label="'质检单号:'+item.qualityId+' 订单号:'+item.orderId+' 制单人:'+item.qualityUserName">
                            质检单号:{{item.qualityId}} 订单号:{{item.orderId}} 制单人:{{item.qualityUserName}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <div class="qualityProductTable">
            <a-table bordered :data-source="data.qualityProductList" :columns="qualityProductColumns" :pagination="false" rowKey="productName" >
                    <template #inlong="{ text }">
                        <div class="long">
                            <a-tooltip :title="text">
                                {{text}}
                            </a-tooltip>
                        </div>
                    </template>
            </a-table>
        </div>
        </a-form>
    </a-spin>
</a-modal>
<!-- 出库 -->
<a-modal
  title="出库"
  v-model:visible="data.isOutModal"
  :confirm-loading="data.isOutModalLoading"
  @ok="handleOutModal"
  @cancel="handleOutCloseModal"
  okText = "确认"
  cancelText= "取消"
  width="1100px"
  :maskClosable="false" 
>
    <a-spin :spinning="data.isOutModalLoading">
        <a-form
            ref="outModalRef"
            :model="data.outModalData"
            :rules="data.outModalRules"
            :labelCol="{span:6}"
            :wrapperCol="{span:18}"
        >
        <a-row>
            <a-col :span="5">
                <a-form-item label="出库类型" name="type" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select 
                        v-model:value="data.outModalData.type"
                        placeholder=""
                        style="width: 100%"
                        @select="getOutQuality"
                    >
                        <a-select-option :value="3">提货出库</a-select-option>
                        <!-- <a-select-option :value="4">报废出库</a-select-option> -->
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="19">
                <a-form-item has-feedback label="质检单" name="quality_id" :wrapperCol="{ style: 'width: calc(100% - 78px)' }" :labelCol="{style: 'width: 78px'}">
                    <a-select 
                        show-search
                        optionFilterProp="label"
                        v-model:value="data.outModalData.quality_id"
                        placeholder=""
                        style="width: 100%"
                        :loading="data.isDownOutQualityList"
                        @select="getQualityOutProductList"
                    >
                        <a-select-option :value="item.qualityId" v-for="(item,index) in data.downOutQualityList" :key="index+1" :label="'质检单号:'+item.qualityId+' 提货单号:'+item.takeDeliveryId+' 制单人:'+item.qualityUserName+' 制单时间:'+item.gmtCreate">
                            质检单号:{{item.qualityId}} 提货单号:{{item.takeDeliveryId}} 制单人:{{item.qualityUserName}} 制单时间:{{item.gmtCreate}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <div class="qualityProductTable">
            <a-table bordered :data-source="data.outQualityProductList" :columns="qualityOutProductColumns" :pagination="false" rowKey="productName" >
                    <template #inlong="{ text }">
                        <div class="long">
                            <a-tooltip :title="text">
                                {{text}}
                            </a-tooltip>
                        </div>
                    </template>
            </a-table>
        </div>
        </a-form>
    </a-spin>
</a-modal>
<!-- 产品详情对话框 -->
<a-modal
  :title="data.modalDetailsTitle"
  v-model:visible="data.isDetailsModal"
  :confirm-loading="data.isDetailsModalLoading"
  @cancel="handleDetailsCloseModal"
  :ok-button-props="{ disabled: data.isDetailsOkButton }"
  :cancel-button-props="{ disabled: data.isDetailsCancelButton }"
  okText = "确认"
  cancelText= "取消"
  width="700px"
  :maskClosable="false" 
>
<a-spin :spinning="data.isDetailsModalLoading">
  <a-form
    ref="modalDetailsRef"
    :model="data.modalDetailsData"
    :rules="data.modalDetailsRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:17}"
  >
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品名称">
                <a-input v-model:value="data.modalDetailsData.name" defaultValue="" autocomplete="off" allow-clear disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="产品型号">
                <a-input v-model:value="data.modalDetailsData.model" defaultValue="" autocomplete="off" allow-clear disabled/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品重量">
                <a-input v-model:value="data.modalDetailsData.weight" autocomplete="off" defaultValue="" suffix="kg" allow-clear disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="产品尺寸">
                <a-input v-model:value="data.modalDetailsData.size" autocomplete="off" defaultValue="" suffix="mm" allow-clear disabled/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="产品颜色">
                <a-input style="width:100%" v-model:value="data.modalDetailsData.color" defaultValue="" allow-clear disabled />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="计量单位">
                <a-input v-model:value="data.modalDetailsData.unit" autocomplete="off" defaultValue="张" allow-clear disabled/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="库存数量">
                <a-input-number style="width:100%" v-model:value="data.modalDetailsData.num" defaultValue="100" :min="0" :max="9999999" :step="0" allow-clear disabled/>
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="备注">
                <a-input style="width:100%" v-model:value="data.modalDetailsData.remarks" autocomplete="off" allow-clear disabled/>
            </a-form-item>
        </a-col>
    </a-row>
    <a-row>
        <a-col :span="12">
            <a-form-item has-feedback label="创建日期">
                <a-date-picker style="width:100%" v-model:value="data.modalDetailsData.gmt_create" autocomplete="off" allow-clear disabled />
            </a-form-item>
        </a-col>
        <a-col :span="12">
            <a-form-item has-feedback label="修改日期">
                <a-date-picker style="width:100%" v-model:value="data.modalDetailsData.gmt_modified" autocomplete="off" allow-clear disabled />
            </a-form-item>
        </a-col>
    </a-row>
  </a-form>
</a-spin>
</a-modal>
</template>
<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details,inWarehouse,getQualityProduct,outWarehouse,back } from "@/api/warehouse/ioWarehouse.js"
import { downQualityOrder,downUnImplementQuality,downProduct } from "@/api/down.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
    name: "IoWarehouse",
    components: { cTable },
    setup(){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '出入库单号',
                    width: 220,
                    dataIndex: 'ioId',
                    key: 'io_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '产品名称',
                    width: 300,
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                    slots: {
                        customRender: 'ioWarehouseSee',
                    },
                },
                {
                    title: '产品型号',
                    width: 120,
                    dataIndex: 'model',
                    key: 'model',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                
                {
                    title: '类型',
                    width: 90,
                    dataIndex: 'type',
                    key: 'type',
                    ellipsis: true,
                    customRender: (text) => {
                        switch(text.record.type){
                            case 1:
                                return "生产入库";
                            case 2:
                                return "退回入库";
                            case 3:
                                return "提货出库";
                            case 4:
                                return "报废出库";
                            default:
                                return "";
                        }
                    }
                },
                {
                    title: '数量',
                    width: 100,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'ioWarehouseNum',
                    },
                },
                {
                    title: '质检单号',
                    width: 200,
                    dataIndex: 'qualityId',
                    key: 'quality_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                // {
                //     title: '提货单号',
                //     width: 150,
                //     dataIndex: 'takeDeliveryId',
                //     key: 'take_delivery_id',
                //     ellipsis: true,
                //     slots: {
                //         customRender: 'long',
                //     },
                // },
                // {
                //     title: '仓库名称',
                //     width: 100,
                //     dataIndex: 'warehouse',
                //     key: 'warehouse',
                //     ellipsis: true,
                //     slots: {
                //         customRender: 'long',
                //     },
                // },
                {
                    title: '创建时间',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'iogmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'iogmtCreate',
                    key: 'iogmt_create',
                    ellipsis: true,
                },
                // {
                //     title: '备注',
                //     dataIndex: 'remarks',
                //     key: 'remarks',
                //     width: 160,
                //     ellipsis: true,
                //     slots: {
                //         customRender: 'long',
                //     },
                // },
            ];
        });
        //入库产品列表
        const qualityProductColumns = computed(() =>{
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '产品名称',
                    width: 200,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 110,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '生产人员',
                    width: 100,
                    dataIndex: 'productionPersonnel',
                    key: 'production_personnel',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 90,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 100,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 100,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '入库数量',
                    width: 100,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    width: 100,
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
            ]
        })
        //出库产品列表
        const qualityOutProductColumns = computed(() =>{
            return [
                {
                    title: '序号',
                    width: 70,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '入库单',
                    width: 100,
                    dataIndex: 'ioWarehouseId',
                    key: 'io_warehouse_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品名称',
                    width: 200,
                    dataIndex: 'productName',
                    key: 'product_name',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品型号',
                    width: 100,
                    dataIndex: 'productModel',
                    key: 'product_model',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品颜色',
                    width: 90,
                    dataIndex: 'productColor',
                    key: 'product_color',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '产品重量(KG)',
                    width: 140,
                    dataIndex: 'productWeight',
                    key: 'product_weight',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测总数',
                    width: 100,
                    dataIndex: 'num',
                    key: 'num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '合格数量',
                    width: 100,
                    dataIndex: 'passNum',
                    key: 'pass_num',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
                {
                    title: '检测说明',
                    dataIndex: 'qualityExplain',
                    key: 'quality_explain',
                    ellipsis: true,
                    slots: {
                        customRender: 'inlong',
                    },
                },
            ]
        })
        const data = reactive({
            styleTableHeight:"height:calc(100% - 102px)",
            btnMenu: store.state.user.btnList.ioWarehouse,
            productBtnMenu: store.state.user.btnList.productManagement,
            queryData:{
                product_id:"",
                type:"",
            },
            downProductListLoading:false,
            downProductList:[],
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            isIoType:false,
            productList:[],
            isTakeList:false,
            takeList:[],
            isOrderList:false,
            orderList:[],

            //以下是入库按钮事件参数 
            modalTitle:"",
            modalType:"",
            isModal: false,
            isModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            isDownUnImplementQualityList:false,
            downUnImplementQualityList:[],//未执行的质检单下拉
            qualityProductList:[],//质检单全部产品信息
            modalData:{
                type:"",
                quality_id:"",
            },
            modalRules:{
                type:[{ required: true,type:'number', message: '请选择入库类型', trigger: 'change' }],
                quality_id:[{ required: true, message: '请选择质检单', trigger: 'change' }],
                
            },

            //以下是出库按钮事件参数 
            isOutModal: false,
            isOutModalLoading: false,
            isDownOutQualityList:false,
            downOutQualityList:[],//提货出库未执行的质检单下拉数据
            outQualityProductList:[],//提货出库质检单全部产品信息
            outModalData:{
                type:"",
                quality_id:"",
            },
            outModalRules:{
                type:[{ required: true,type:'number', message: '请选择出库类型', trigger: 'change' }],
                quality_id:[{ required: true, message: '请选择质检单', trigger: 'change' }],
            },

            //以下是产品详情参数
            modalDetailsTitle:"",
            modalDetailsType:"",
            isDetailsModal: false,
            isDetailsModalLoading: false,
            isDetailsOkButton: false,
            isDetailsCancelButton: false,
            modalDetailsData:{
                name:"",
                model:"",
                weight:"",
                size:"",
                color:"",
                unit:"",
                num:"",
                gmt_create:"",
                gmt_modified:"",
                remarks:"",
            },
            modalDetailsRules:{}
        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                product_id: isOK(cont[0].product_id)?cont[0].product_id:"",
                type: isOK(cont[0].type)?cont[0].type:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.product_id = "";
            data.queryData.type = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);
            //获取产品下拉
            getDownProduct();

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //重置modal数据
        const resetModalData = () =>{
            data.modalData = {
                productId:"",
                type:"",
                num:"",
                remarks: "",
            };
        }

        //重置出库参数
        const resetOutModalData = () =>{
            data.outModalData = {
                type:"",
                quality_id:"",
            }
        }

        //未执行的质检单下拉数据
        const getDownUnImplementQuality = (value)=>{
            data.isDownUnImplementQualityList = true;
            downUnImplementQuality({
                type:value
            }).then(res =>{
                data.downUnImplementQualityList = res.data.list;
                data.isDownUnImplementQualityList = false;
            })
        }

        //获取质检单全部产品信息
        const getQualityProductList = (id)=>{
            getQualityProduct({quality_id:id}).then(res =>{
                data.qualityProductList = res.data.list;
            })
        }

        //获取出库未执行的质检单
        const getOutQuality = (value)=>{
            //提货出库
            if(value==3){
                data.isDownOutQualityList = true;
                downUnImplementQuality({type:value}).then(res =>{
                    data.downOutQualityList = res.data.list;
                    data.isDownOutQualityList = false;
                })
            }
        }

        //提货出库 选择质检单后 加载质检单中的产品
        const getQualityOutProductList = (id)=>{
            getQualityProduct({quality_id:id}).then(res =>{
                data.outQualityProductList = res.data.list;
            })
        }

        //获取产品下拉数据
        const getDownProduct = ()=>{
            data.isIoType = true;
            downProduct().then(res =>{
                data.productList = res.data.list;
                data.isIoType = false;
            });
        }

        //获取订单下拉数据
        const getDownOrder = ()=>{
            data.isOrderList = true;
            downQualityOrder().then(res =>{
                data.orderList = res.data.list;
                data.isOrderList = false;
            })
        }

        //产品名称详情
        const ioWarehouseSee = (product_id)=>{
            if(data.productBtnMenu.see){
                data.modalDetailsTitle = "产品详情";
                data.modalDetailsType = "see";
                data.isDetailsOkButton = true;
                data.isDetailsModalLoading = true;
                //判断是否选择一条数据
                details({id:product_id}).then(res =>{
                    data.isDetailsModalLoading = false;
                    data.modalDetailsData.name = res.data.list.name;
                    data.modalDetailsData.model = res.data.list.model;
                    data.modalDetailsData.weight = res.data.list.weight;
                    data.modalDetailsData.size = res.data.list.size;
                    data.modalDetailsData.color = res.data.list.color;
                    data.modalDetailsData.unit = res.data.list.unit;
                    data.modalDetailsData.num = res.data.list.num;
                    data.modalDetailsData.gmt_create = res.data.list.gmtCreate;
                    data.modalDetailsData.gmt_modified = res.data.list.gmtModified;
                    data.modalDetailsData.remarks = res.data.list.remarks;
                    data.isDetailsModal = true;
                }).catch((err) => {
                    data.isDetailsModalLoading = false;
                });
            }else{
                message.warning("权限不足");
            }
        }

        //产品名称详情-取消
        const handleDetailsCloseModal = ()=>{
            data.modalDetailsTitle = "";
            data.modalDetailsType = "";
            data.isDetailsModal = false;
            data.isDetailsModalLoading = false;
            data.isDetailsOkButton = false;
            data.isDetailsCancelButton = false;
            data.modalDetailsData={
                name:"",
                model:"",
                weight:"",
                size:"",
                color:"",
                unit:"",
                num:"",
                gmt_create:"",
                gmt_modified:"",
                remarks:"",
            }
        }

        //按钮-入库
        const modalRef = ref();
        const ioWarehouseIn = ()=>{
            data.modalTitle = "入库";
            data.modalType = "in";
            data.isModal = true;
            // getDownProduct();
        }

        //新增对话框-入库
        const handleModal = ()=>{
            modalRef.value.validate().then(() => {
                data.isModalLoading = true;
                inWarehouse(data.modalData).then(res =>{
                    message.success("入库成功");
                    handleCloseModal();
                    resetTable();
                }).catch((err) => {
                    data.isModalLoading = false;
                });
            })
        }

        //入库对话框-取消
        const handleCloseModal = ()=>{
            data.isDownUnImplementQualityList=false;
            data.downUnImplementQualityList=[];//未执行的质检单下拉
            data.qualityProductList=[];//质检单全部产品信息
            resetModalData();
            data.isModal = false;
            data.isModalLoading = false;
            data.isOkButton = false;
            //重置表单校验
            modalRef.value.resetFields();
        }

        //按钮-出库
        const outModalRef = ref();
        const ioWarehouseOut = ()=>{
            data.outModalData.type = 3;
            getOutQuality(3);
            data.isOutModal = true;
        }

        //出库对话框-确定
        const handleOutModal = ()=>{
            outModalRef.value.validate().then(() => {
                data.isOutModalLoading = true;
                outWarehouse(data.outModalData).then(res =>{
                    message.success("出库成功");
                    handleOutCloseModal();
                    resetTable();
                }).catch((err) => {
                    data.isOutModalLoading = false;
                });
            })
        }
        
         //入库对话框-取消
        const handleOutCloseModal = ()=>{
            resetOutModalData();
            data.isOutModal = false;
            data.isOutModalLoading = false;
            data.isDownOutQualityList = false;
            data.downOutQualityList = [];//提货出库未执行的质检单下拉数据
            data.outQualityProductList = [];//提货出库质检单全部产品信息
            //重置表单校验
            outModalRef.value.resetFields();
        }

        //按钮-退回
        const ioWarehouseBack = ()=>{
            if(data.selectedRowKeys.length==1){
                for(let i = 0;i<data.tableData.length;i++){
                    if(data.tableData[i].id===data.selectedRowKeys[0]){
                        if(data.tableData[i].type==4){
                            return message.warning("报废出库不予退回");
                        }
                    }
                }
                Modal.confirm({
                    title: '退回操作',
                    icon: createVNode(ExclamationCircleOutlined),
                    content: '您是否确定退回操作',
                    okText: '确认',
                    cancelText: '取消',
                    //确定
                    onOk() {
                        back({id:data.selectedRowKeys[0]}).then(res =>{
                            message.success("退回成功");
                            resetTable();
                        })
                    },
                    // 取消
                    onCancel() {},
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        return {
            setSortedInfo,
            columns,
            qualityProductColumns,
            qualityOutProductColumns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,
            resetModalData,

            getDownProduct,
            getDownUnImplementQuality,
            getQualityProductList,

            modalRef,
            ioWarehouseSee,
            handleDetailsCloseModal,
            ioWarehouseIn,
            handleModal,
            handleCloseModal,

            getOutQuality,
            getQualityOutProductList,
            
            outModalRef,
            ioWarehouseOut,
            getDownOrder,
            handleOutModal,
            handleOutCloseModal,

            ioWarehouseBack,
        }
    }
}
</script>
<style lang="scss" scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}

.qualityProductTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.long{
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>