<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="角色名称">
                    <a-input v-model:value="data.queryData.name" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.see" @click="roleSee">查看</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.add" @click="roleAdd">新增</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.update" @click="roleUpdate">修改</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="roleRemove(0)">启用</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.remove" @click="roleRemove(1)">停用</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.authorization" @click="toAuthorization">权限配置</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
            <cTable
            ref="ctableRef"
            :toTableData="data.tableData"
            :toTableY="data.tableHeight"
            :toColumns="columns"
            :toBordered="true"
            toSize="middle"
            :toTableLoading="data.tableLoading"
            toPageStyle="float: right;"
            :toDataTotal="data.dataTotal"
            @tData="tData"
            @setSortedInfo="setSortedInfo"
            @getSelectedRowKeys="getSelectedRowKeys"
            />
        </div>
    </div>

<!-- 查看 新增 修改 对话框 -->
<a-modal
    :title="data.roleTitle"
    v-model:visible="data.isRoleModal"
    :confirm-loading="data.isRoleModalLoading"
    @ok="handleRoleModal"
    @cancel="handleCloseRoleModal"
    :ok-button-props="{ disabled: data.isOkButton }"
    :cancel-button-props="{ disabled: data.isCancelButton }"
    okText = "确认"
    cancelText= "取消"
    width="420px"
    :maskClosable="false"
>
<a-spin :spinning="data.isRoleModalLoading">
  <a-form
    ref="roleModalRef"
    :model="data.roleModalData"
    :rules="data.roleModalRules"
    :labelCol="{span:6}"
    :wrapperCol="{span:16}"
  >

    <a-form-item has-feedback label="角色名称" name="role_name">
        <a-input v-model:value="data.roleModalData.role_name" autocomplete="off" allow-clear :disabled="data.roleType==='see'" />
    </a-form-item>
    <a-form-item has-feedback label="备注">
        <a-input v-model:value="data.roleModalData.remark" autocomplete="off" allow-clear :disabled="data.roleType==='see'" />
    </a-form-item>
  </a-form>
</a-spin>
</a-modal>
<!-- 权限配置 -->
<a-modal
    title="权限配置"
    v-model:visible="data.isAuthorizationModal"
    :confirm-loading="data.isAuthorizationModalLoading"
    @ok="handleAuthorizationModal"
    @cancel="handleCloseAuthorizationModal"
    :ok-button-props="{ disabled: false }"
    :cancel-button-props="{ disabled: false }"
    okText = "确认"
    cancelText= "取消"
    width="320px"
    :maskClosable="false"
>
<a-spin :spinning="data.isAuthorizationModalLoading">
    <div class="authorizationTree">
        <a-tree
            checkable
            :tree-data="data.authorizationData"
            v-model:checkedKeys="data.authorizationListId"
            v-model:selectedKeys="data.authorizationSelectedKeys"
            :onCheck="handleCheckKeys"
            :selectable="false"
        >
            
        </a-tree>
    </div>
</a-spin>
</a-modal>
</template>

<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details,add,update,startOrStop,onePermissionsAll,authorization } from "@/api/admin/role.js"
import { permissionAll } from "@/api/admin/menu.js"
import { getTrees } from "@/utils/tree.js"
import { isOK } from "@/utils/illegal.js"
import { Modal,message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {
    name: "Role",
    components: { cTable },
    setup(props){
        const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '角色名称',
                    width: 180,
                    dataIndex: 'roleName',
                    key: 'role_name',
                    ellipsis: true,
                },
                {
                    title: '是否启用',
                    width: 150,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'is_eleted' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'isDeleted',
                    key: 'is_eleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"停用":text.record.isDeleted==0?"启用":"";
                    }
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '修改时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_modified' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtModified',
                    key: 'gmt_modified',
                    ellipsis: true,
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    key: 'remark',
                    ellipsis: true,
                },
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.role,
            queryData:{
                name:"",
            },
            tableLoading: false,
            tableData:[],
            tableHeight:0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下是增删改查按钮事件参数 
            roleTitle:"",
            roleType:"",
            isRoleModal: false,
            isRoleModalLoading: false,
            isOkButton: false,
            isCancelButton: false,
            roleModalData:{
                role_name:"",
                remark:"",
            },
            roleModalRules:{
                role_name:[{ required: true, message: '角色名称不能为空', trigger: 'blur' }],
            },

            //以下为权限配置按钮参数
            isAuthorizationModal: false,
            isAuthorizationModalLoading: false,
            authorizationData:[],//权限数据列表
            authorizationListId:[],//选中的权限id列表（不带半选 父节点 需要点击事件中的参数）
            authorizationNodeAll:[],//当前选中的全部节点 （带 半选的父节点）
            authorizationSelectedKeys:[],//鼠标当前选择的id列表
        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                name: isOK(cont[0].name)?cont[0].name:"",
                sort: cont[1].sort,
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            //清除勾选
            ctableRef.value.unSelectedRowKeys();
            data.queryData.name = "";
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //按钮-查看
        const roleSee = () =>{
            data.roleTitle = "查看角色";
            data.roleType = "see";
            data.isOkButton = true;
            data.isRoleModalLoading = true;
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isRoleModalLoading = false;
                    data.roleModalData.role_name = res.data.role.roleName;
                    data.roleModalData.remark = res.data.role.remark;
                    data.isRoleModal = true;
                }).catch((err) => {
                    data.isRoleModalLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-新增
        const roleAdd = () =>{
            data.roleTitle = "新增角色";
            data.roleType = "add";
            data.isOkButton = false;
            data.isRoleModal = true;
        }

        //按钮-修改
        const roleUpdate = () =>{
            if(data.selectedRowKeys.length==1){
                data.roleTitle = "修改角色";
                data.roleType = "update";
                data.isRoleModal = true;
                data.isOkButton = false;
                data.isRoleModalLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    if(isOK(res.data.role)){
                        data.roleModalData = {
                            id: data.selectedRowKeys[0],
                            role_name: res.data.role.roleName,
                            remark: res.data.role.remark,
                        }
                    }
                    data.isRoleModalLoading = false;
                })
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时修改多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //获取表单
        const roleModalRef = ref();
        //增改查-确定
        const handleRoleModal = ()=>{
            roleModalRef.value.validate().then(() => {
                if(data.roleType === "add"){
                    data.isRoleModalLoading = true;
                    add(data.roleModalData).then(res =>{
                        message.success("新增成功");
                        handleCloseRoleModal();
                        resetTable();
                    }).catch((err) => {
                        data.isRoleModalLoading = false;
                    });
                }
                if(data.roleType === "update"){
                    data.isRoleModalLoading = true;
                    update(data.roleModalData).then(res =>{
                        message.success("修改成功");
                        handleCloseRoleModal();
                        resetTable();
                    }).catch((err) => {
                        data.isRoleModalLoading = false;
                    });
                }
            })
        }

        //增改查-取消
        const handleCloseRoleModal = () =>{
            //关闭窗口
            data.isRoleModal = false;
            //取消按钮加载状态
            data.isRoleModalLoading = false;
            //重置数据
            data.roleModalData={
                role_name:"",
                remark:"",
            }
            //重置表单校验
            roleModalRef.value.resetFields();
        }

        //按钮-启停 value 0为启用 1为停用
        const roleRemove = (value) =>{
            if(data.selectedRowKeys.length>0){
                if(value==0){
                    Modal.confirm({
                        title: '启用角色',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定启用这些角色',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            startOrStop({idList:data.selectedRowKeys.toString(),type:0}).then(res =>{
                                message.success("启用"+res.data.num+"个角色");
                                ctableRef.value.unSelectedRowKeys();
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
                }
                if(value==1){
                    Modal.confirm({
                        title: '停用角色',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: '您是否确定停用这些角色',
                        okText: '确认',
                        cancelText: '取消',
                        //确定
                        onOk() {
                            startOrStop({idList:data.selectedRowKeys.toString(),type:1}).then(res =>{
                                message.success("停用"+res.data.num+"个角色");
                                ctableRef.value.unSelectedRowKeys();
                                resetTable();
                            })
                        },
                        // 取消
                        onCancel() {},
                    });
                }
            }else{
                message.warning("请选择一条数据");
            }
        }

        //权限配置
        const toAuthorization = () =>{
            if(data.selectedRowKeys.length==1){
                data.isAuthorizationModal = true;
                data.isAuthorizationModalLoading = true;
                data.authorizationData = [];
                data.authorizationListId = [];
                permissionAll().then(res =>{
                    let tree = [];
                    for(let i = 0;i < res.data.list.length;i++){
                        tree.push(
                            {
                                id:res.data.list[i].id,
                                key:res.data.list[i].id,
                                title:res.data.list[i].name,
                                pid:res.data.list[i].pid
                            }
                        );
                    }
                    data.authorizationData = getTrees(tree,0);
                    onePermissionsAll({role_id:data.selectedRowKeys[0]}).then(inres =>{
                        let rolePermissionsList = inres.data.list;//角色的全部菜单数据
                        let rolePermissionsListId = [];//这个角色的菜单id列表
                        let rolePermissionsListPid = [];//这个角色的菜单pid列表
                        for(let i = 0;i<rolePermissionsList.length;i++){
                            rolePermissionsListId.push(rolePermissionsList[i].id);
                            rolePermissionsListPid.push(rolePermissionsList[i].pid);
                        }
                        //如过id不存在pid中 说明这个id是没有下级的 那么它就是子节点
                        let list = [];//用于保存全部子节点
                        for(let a = 0;a<rolePermissionsListId.length;a++){
                            let isE = false;
                            for(let b = 0;b<rolePermissionsListPid.length;b++){
                                if(rolePermissionsListId[a]===rolePermissionsListPid[b]){
                                    isE = true;
                                }
                            }
                            if(!isE){
                                list.push(rolePermissionsListId[a]);
                            }
                        }
                        //这里只赋值 子节点（如果将父节点也放上会出现 全部选中的情况）
                        data.authorizationListId = list;

                        data.isAuthorizationModalLoading = false;
                    }).catch((inerr) => {
                        data.isAuthorizationModalLoading = false;
                    });
                }).catch((err) => {
                    data.isAuthorizationModalLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时配置多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }
        //提取子节点
        const toPermissionsNode = (oneRolePermissionsList) =>{
            if(isOK(oneRolePermissionsList.children)){
                return 
            }
        }
        //权限配置-确定
        const handleAuthorizationModal = () =>{
            data.isAuthorizationModalLoading = true;
            authorization({role_id:data.selectedRowKeys[0],permissionsListId:data.authorizationNodeAll.toString()}).then(res =>{
                toQueryList(false);
                data.isAuthorizationModal = false;
                message.success("配置成功");
                data.isAuthorizationModalLoading = false;
                data.authorizationData = [];
                data.authorizationListId = [];
            }).catch((err) => {
                data.isAuthorizationModalLoading = false;
            });
        }
        //权限配置-取消
        const handleCloseAuthorizationModal = () =>{
            data.isAuthorizationModal = false;
            data.isAuthorizationModalLoading = false;
        }
        //权限配置-点击事件（将√的节点 和 半选节点 结合）
        const handleCheckKeys = (checkedKeys, e) =>{
            data.authorizationNodeAll = [...checkedKeys, ...e.halfCheckedKeys];
            // console.log(e.halfCheckedKeys);//这里是半选 父节点
            // console.log(checkedKeys)//这里是√的节点
        }

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            roleModalRef,
            roleSee,
            roleAdd,
            roleUpdate,
            handleRoleModal,
            handleCloseRoleModal,
            roleRemove,

            toAuthorization,
            toPermissionsNode,
            handleAuthorizationModal,
            handleCloseAuthorizationModal,
            handleCheckKeys,
        }
    }
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.authorizationTree{
    width: auto;
    min-height: 500px;
    height: 500px;
    overflow-x:hidden;
    overflow-y: auto ;
}
/*滚动条样式*/
.authorizationTree::-webkit-scrollbar {/*滚动条整体样式*/
    width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 2px;
}
.authorizationTree::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    /*border-radius: 2px;*/
    -webkit-box-shadow: inset 0 0 0px rgba(125, 127, 131, 0.5);
    background: rgba(125, 127, 131, 0.5);
}
.authorizationTree::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,.1);
    border-radius: 0;
    background: rgba(0,0,0,.1);
}
</style>