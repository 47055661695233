import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/log/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/log/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

export function outExcel(data){
    return service.request({
        url:"/log/outExcel",
        method: "post",
        data:Qs.stringify(data),
        responseType:'blob',
    });
}

// //导出
// export function outExcel(data){
//     return service.request({
//         url:"/log/outExcel?"+Qs.stringify(data),
//         method: "get",
//         responseType:'blob', // 注意这里！！！
//     });
// }
//导出
// export const outExcel = (data) => {
//     return service.request({
//       url: "/log/outExcel?"+Qs.stringify(data),
//       method: 'get',
//       responseType:'blob', // 注意这里！！！
//     });
// };