/**
 * 判断2个list是否一样
 */
export function arrayIsEqual(arr1, arr2) {
    if (!arr1 || !arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        
        if (!arr1[i]===rr2[i]){
          return false;
        }
          
      } else if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
}

//找到数组中最大的值 并输出是第几位
export function getMax(arr){
  let max = arr[0];
  let maxI = 0;
    for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            max = arr[i]
            maxI = i;
        }
    }
  return maxI;
}