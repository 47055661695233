import service from "@/utils/request.js"
import Qs from 'qs'

//公共控件 获取提货单详情
export function takeDetails(data){
    return service.request({
        url:"/takeDelivery/takeDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}