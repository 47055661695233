import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/delivery/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//发货单除去产品的详情
export function details(data){
    return service.request({
        url:"/delivery/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//制单
export function add(data){
    return service.request({
        url:"/delivery/add",
        contentType: "application/json",
        method: "post",
        data:data,
    });
}

//发货单除去产品的修改
export function update(data){
    return service.request({
        url:"/delivery/update",
        method: "post",
        data:Qs.stringify(data),
    });
}

//退回
export function deliveryReturn(data){
    return service.request({
        url:"/delivery/deliveryReturn",
        method: "post",
        data:Qs.stringify(data),
    });
}

//撤回退款
export function withdrawingReturn(data){
    return service.request({
        url:"/delivery/withdrawingReturn",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
export function remove(data){
    return service.request({
        url:"/delivery/remove",
        method: "post",
        data:Qs.stringify(data),
    });
}

//订单详情
export function orderDetails(data){
    return service.request({
        url:"/takeDelivery/orderDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//产品详情
export function productDetails(data){
    return service.request({
        url:"/takeDelivery/productDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//用户详情
export function userDetails(data){
    return service.request({
        url:"/takeDelivery/userDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//导出
export function deliveryExportExcel(data){
    return service.request({
        url:"/exportExcel/deliveryExportExcel",
        method: "get",
        responseType: 'blob' ,
        type: 'application/octer-stream'
    });
}