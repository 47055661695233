import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/order/getFactoryList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/order/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//修改订单产品预计完成时间
export function upOneOrderProductPlanDate(data){
    return service.request({
        url:"/order/upOneOrderProductPlanDate",
        method: "post",
        data:Qs.stringify(data),
    });
}

//通过
export function factoryReview(data){
    return service.request({
        url:"/order/factoryReview",
        method: "post",
        data:Qs.stringify(data),
    });
}

//驳回
export function factoryReject(data){
    return service.request({
        url:"/order/factoryReject",
        method: "post",
        data:Qs.stringify(data),
    });
}

//报废
// export function scrapFactory(data){
//     return service.request({
//         url:"/order/scrapFactory",
//         method: "post",
//         data:Qs.stringify(data),
//     });
// }