import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
// and-design-vue
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
// https://vue-i18n-next.intlify.dev/
//axios
import Axios from "axios";
// import { getToken } from "@/utils/app.js";

// 语言
// import VueI18n from "@/language";
// svg全局组件
import SvgIcon from "@/components/Svgicon";
// svg文件解析
import "@/js/svg";
//全局路由拦截 路由守卫
import "@/router/prtmit.js";

//控件语言 中文配置 //antd控件英文修改为中文显示
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import { ConfigProvider } from 'ant-design-vue';//引用


const app = createApp(App);
//注入Axios
app.config.globalProperties.$axios = Axios;

  app
  .use(store)
  .use(router)
  .use(Antd)
  // .use(VueI18n)
  .component("svg-icon", SvgIcon)
  .component(ConfigProvider.name, ConfigProvider)
  .mount("#app");
  
//使用v-focus获取焦点
app.directive('focus', {
  mounted(el) {
    setTimeout(() => {
      console.log(el.focus())
    }, 800)
  }
})