import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/order/getOrderProductionList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/order/orderProductionDetails",
        method: "post",
        data:Qs.stringify(data),
    });
}