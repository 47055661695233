<template>
    <div class="html" ref="htmlRef">
        <!-- 查询条件 -->
        <div class="query" ref="queryRef">
            <a-form layout="inline" :model="data.queryData">
                <a-form-item label="订单编号">
                    <a-input v-model:value="data.queryData.order_id" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="制单人">
                    <a-input v-model:value="data.queryData.producer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item label="客户名称">
                    <a-input v-model:value="data.queryData.customer" placeholder="" style="width:170px;" allow-clear/>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button type="primary" @click="toQueryList(true,data.queryData)">搜索</a-button>
                </a-form-item>
                <a-form-item :wrapper-col="{span: 14, offset: 4}">
                    <a-button @click="resetTable">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <!-- 按钮 -->
        <div class="btn" ref="btnRef">
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.out" @click="orderOut">导出</a-button>
            <a-button class="btn_style" type="primary" ghost v-if="data.btnMenu.review" @click="orderReview">审核</a-button>
        </div>
        <!-- 表格 -->
        <div class="table" :style="data.styleTableHeight" ref="tableRef">
                <cTable
                ref="ctableRef"
                :toTableData="data.tableData"
                :toTableY="data.tableHeight"
                :toColumns="columns"
                :toBordered="true"
                toSize="middle"
                :toTableLoading="data.tableLoading"
                toPageStyle="float: right;"
                :toDataTotal="data.dataTotal"
                @tData="tData"
                @setSortedInfo="setSortedInfo"
                @getSelectedRowKeys="getSelectedRowKeys"
                />
        </div>
    </div>

<!-- 详情 导出 -->
<a-modal
  title="订单导出"
  v-model:visible="data.isOrderDetails"
  width="1200px"
  @cancel="handleCloseOrderDetailsModal"
  @ok="handlePDF"
  okText = "导出PDF"
  :maskClosable="false"
  :confirm-loading="data.isOrderDetailsLoading"
>
<a-spin :spinning="data.isOrderDetailsLoading">
    <div id="orderDetails">
    <div class="detailsTitle">亿奇新材订单</div>
    <a-row>
        <a-col :span="6"></a-col>
        <a-col :span="6"></a-col>
        <a-col :span="6">
            <div class="detailsCol">
                订单编号：{{data.orderDetailsData.order_id}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                下单日期：{{data.orderDetailsData.gmt_create}}
            </div>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#f0f0f0" style="border-collapse:collapse;">
            <tr>
                <td width="172" height="40"><div align="center" class="STYLE7">产品名称</div></td>
                <td width="106" height="40"><div align="center" class="STYLE7">型号</div></td>
                <td width="146"  height="40"><div align="center" class="STYLE7">重量（±0.5kg）</div></td>
                <td width="136" height="40" ><div align="center"><span class="STYLE7">尺寸（mm）</span></div></td>
                <td width="53" height="40"><div align="center" class="STYLE7">颜色</div></td>
                <td width="107" height="40"><div align="center" class="STYLE7">计量单位</div></td>
                <td width="132" height="40"><div align="center" class="STYLE7">计划生产数</div></td>
                <td width="159" height="40"><div align="center" class="STYLE7">计划完成日期</div></td>
                <td width="169" height="40"><div align="center" class="STYLE7">实际完成日期</div></td>
            </tr>
            <tr class="STYLE8" v-for="(item) in data.orderDetailsTableData" :key="item">
                <td height="32"><div class="STYLE8 td1">{{item.productName}}</div></td>
                <td height="32"><div align="center" class="STYLE8 td2">{{item.productModel}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productWeight}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productSize}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productColor}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productUnit}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.planQuantities}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.planDate)}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.completionDate)}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">生产要求</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderDetailsData.request}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">物流流向（客户）</div></td>
                <td height="40" colspan="4"><div align="center" class="STYLE7">{{data.orderDetailsData.customer}}</div></td>
                <td height="40"><div align="center">联系电话</div></td>
                <td height="40" colspan="3"><div align="center" class="STYLE7">{{data.orderDetailsData.customer_phone}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">地址</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderDetailsData.address}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center" >生产部签发</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.factory_name}}</div></td>
                <td height="40"><div align="center" >董事长签发</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.chairman_name}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">销售部负责人签</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderDetailsData.executive_name}}</div></td>
                <td height="40"><div align="center">制单人</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderDetailsData.producer}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="34"><div align="center" >备注</div></td>
                <td height="34" colspan="8"><div class="td3">{{data.orderDetailsData.remarks}}</div></td>
            </tr>
        </table>
    </div>
    </div>
</a-spin>
</a-modal>

<!-- 审核 -->
<a-modal
  title="销售总管审核"
  v-model:visible="data.isOrderReview"
  width="1200px"
  @cancel="handleCloseReviewModal"
  :maskClosable="false"
  :confirm-loading="data.isOrderReviewLoading"
  :footer="footer"
>
<a-spin :spinning="data.isOrderReviewLoading">
    <div class="detailsTitle">亿奇新材订单</div>
    <a-row>
        <a-col :span="6"></a-col>
        <a-col :span="6"></a-col>
        <a-col :span="6">
            <div class="detailsCol">
                订单编号：{{data.orderReviewData.order_id}}
            </div>
        </a-col>
        <a-col :span="6">
            <div class="detailsCol">
                下单日期：{{data.orderReviewData.gmt_create}}
            </div>
        </a-col>
    </a-row>
    <div class="detailsTable">
        <table width="100%" border="1" cellpadding="0" cellspacing="0" bordercolor="#f0f0f0" style="border-collapse:collapse;">
            <tr>
                <td width="172" height="40"><div align="center" class="STYLE7">产品名称</div></td>
                <td width="106" height="40"><div align="center" class="STYLE7">型号</div></td>
                <td width="146"  height="40"><div align="center" class="STYLE7">重量（±0.5kg）</div></td>
                <td width="136" height="40" ><div align="center"><span class="STYLE7">尺寸（mm）</span></div></td>
                <td width="53" height="40"><div align="center" class="STYLE7">颜色</div></td>
                <td width="107" height="40"><div align="center" class="STYLE7">计量单位</div></td>
                <td width="132" height="40"><div align="center" class="STYLE7">计划生产数</div></td>
                <td width="159" height="40"><div align="center" class="STYLE7">计划完成日期</div></td>
                <td width="169" height="40"><div align="center" class="STYLE7">实际完成日期</div></td>
            </tr>
            <tr class="STYLE8" v-for="(item) in data.orderReviewTableData" :key="item">
                <td height="32"><div class="STYLE8 td1">{{item.productName}}</div></td>
                <td height="32"><div align="center" class="STYLE8 td2">{{item.productModel}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productWeight}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productSize}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productColor}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.productUnit}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{item.planQuantities}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.planDate)}}</div></td>
                <td height="32"><div align="center" class="STYLE8">{{getTime(item.completionDate)}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">生产要求</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderReviewData.request}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">物流流向（客户）</div></td>
                <td height="40" colspan="4"><div align="center" class="STYLE7">{{data.orderReviewData.customer}}</div></td>
                <td height="40"><div align="center">联系电话</div></td>
                <td height="40" colspan="3"><div align="center" class="STYLE7">{{data.orderReviewData.customer_phone}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">地址</div></td>
                <td height="40" colspan="8"><div class="td3">{{data.orderReviewData.address}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center" >生产部签发</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderReviewData.factory_name}}</div></td>
                <td height="40"><div align="center" >董事长签发</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderReviewData.chairman_name}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="40"><div align="center">销售部负责人签</div></td>
                <td height="40" colspan="4"><div align="center">{{data.orderReviewData.executive_name}}</div></td>
                <td height="40"><div align="center">制单人</div></td>
                <td height="40" colspan="3"><div align="center">{{data.orderReviewData.producer}}</div></td>
            </tr>
            <tr class="STYLE7">
                <td height="34"><div align="center" >备注</div></td>
                <td height="34" colspan="8"><div class="td3">{{data.orderReviewData.remarks}}</div></td>
            </tr>
        </table>
    </div>
</a-spin>
    <template #footer>
        <a-button key="back" @click="handleCloseReviewModal">取消</a-button>
        <a-popconfirm
            title="您是否确定报废?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleScrapModal"
        >
            <a-button key="scrap" type="primary" :loading="data.isOrderReviewLoading">报废</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="您是否确定通过?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleReviewModal"
        >
            <a-button key="pass" type="primary" :loading="data.isOrderReviewLoading">通过</a-button>
        </a-popconfirm>
      </template>
</a-modal>
</template>

<script>
import { useStore } from 'vuex'
import { reactive,ref,computed,onMounted,createVNode,watch,nextTick } from 'vue'
import cTable from "@/components/table"
import { getList,details,executiveReview,scrapExecutive } from "@/api/order/orderExecutive.js"
import { isOK } from "@/utils/illegal.js"
import { message } from 'ant-design-vue';
import { getYyyyddmm } from "@/utils/time.js"
import { getPdf } from "@/utils/htmlToPdf.js"
export default {
   name: "OrderExecutive",
   components: { cTable },
   setup(){
      const store = useStore();
        //监听消息栏 是否显示 用于调整表格高度
        watch(
            () => store.state.user.isHeadBar,
            (count, prevCount) => {
                if(count){
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                }else{
                    //表格和翻页区域高度
                    data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                    //表格内容滚动高度
                    data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                }
            }
        )
        //排序样式数据记录
        const sortedInfo = ref();
        //控件传回的排序样式和值
        const setSortedInfo = (value) =>{
            sortedInfo.value = value;
        }
        //页面的表格列属性
        const columns = computed(() => {
            const sorted = sortedInfo.value || {};
            return [
                {
                    title: '序号',
                    width: 60,
                    align:"center",
                    customRender: (text) => {
                        return text.index + 1;
                    }
                },
                {
                    title: '订单编号',
                    width: 160,
                    dataIndex: 'orderId',
                    key: 'order_id',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户名称',
                    width: 200,
                    dataIndex: 'customer',
                    key: 'customer',
                    sorter: true,
                    sortOrder: sorted.columnKey === 'customer' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '客户手机号码',
                    width: 120,
                    dataIndex: 'customerPhone',
                    key: 'customer_phone',
                    ellipsis: true,
                },
                {
                    title: '客户地址',
                    width: 200,
                    dataIndex: 'address',
                    key: 'address',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '制单人',
                    width: 100,
                    dataIndex: 'producer',
                    key: 'producer',
                    ellipsis: true,
                },
                {
                    title: '订单状态',
                    width: 140,
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text) => {
                        let v = "";
                        switch(text.record.status){
                            case 0: v = "制单完成"; break;
                            case 1: v = "总管审核完成"; break;
                            case 2: v = "厂长审核完成"; break;
                            case 3: v = "制货中"; break;
                            case 4: v = "发货中"; break;
                            case 5: v = "完成"; break;
                            default:"";      
                        }
                        return v;
                    },
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
                {
                    title: '创建时间',
                    width: 180,
                    sorter: true,
                    sortOrder: sorted.columnKey === 'gmt_create' && sorted.order,
                    sortDirections: ['descend', 'ascend'],
                    dataIndex: 'gmtCreate',
                    key: 'gmt_create',
                    ellipsis: true,
                },
                {
                    title: '是否报废',
                    width: 80,
                    dataIndex: 'isDeleted',
                    key: 'is_deleted',
                    ellipsis: true,
                    customRender: (text) => {
                        return text.record.isDeleted==1?"报废":text.record.isDeleted==0?"有效":"";
                    }
                },
                {
                    title: '要求或建议',
                    width: 200,
                    dataIndex: 'request',
                    key: 'request',
                    slots: {
                        customRender: 'long',
                    },
                    ellipsis: true,
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    ellipsis: true,
                    slots: {
                        customRender: 'long',
                    },
                },
            ];
        });
        const data = reactive({
            styleTableHeight:"height:calc(100% - 104px)",
            btnMenu: store.state.user.btnList.orderExecutive,
            queryData:{
                order_id:"",
                producer:"",
                customer:"",
            },
            tableLoading: false,
            tableData: [],
            tableHeight: 0,
            selectedRowKeys: [],
            dataTotal:0,

            //以下为订单详情参数 导出显示用
            isOrderDetails:false,
            isOrderDetailsLoading:false,
            orderDetailsData:{
                order_id:"",
                gmt_create:"",
                producer:"",
                customer:"",
                customer_phone:"",
                address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            },
            orderDetailsTableData:[],

            //以下为审核所需参数
            isOrderReview:false,
            isOrderReviewLoading:false,
            orderReviewData:{
                order_id:"",
                gmt_create:"",
                producer:"",
                customer:"",
                customer_phone:"",
                address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            },
            orderReviewTableData:[],
        });
        //获取表格高度（用于滚轮）
        const tableRef = ref();
        const btnRef = ref();
        const queryRef = ref();
        const htmlRef = ref();
        
        //复选的数据id
        const getSelectedRowKeys = (selectedRowKeys) =>{
            data.selectedRowKeys = selectedRowKeys;
        }

        //调用流程 先跳入table公用控件 然后把数据整体输出回来 再进行查询  state为true时说明是控件的回调
        const ctableRef = ref();
        //跳转到table公用控件 status判断是否是新的搜索
        const toQueryList = (status) =>{
            ctableRef.value.queryList(status,data.queryData);//queryList是cTable子组件里的方法
        }
        const tData = (cont) =>{
            data.tableLoading = true;
            getList({
                page: cont[1].page,
                limit: cont[1].limit,
                sort: cont[1].sort,
                order_id: isOK(cont[0].order_id)?cont[0].order_id:"",
                producer: isOK(cont[0].producer)?cont[0].producer:"",
                customer: isOK(cont[0].customer)?cont[0].customer:"",
                desc: cont[1].desc,
            }).then(res =>{
                data.tableLoading = false;
                document.getElementsByClassName('ant-table-body')[0].scrollTop = 0;//搜索后表格回到顶部
                data.tableData = res.data.list;
                data.dataTotal = res.data.total;
            })
        }
        //重置表格数据
        const resetTable = () =>{
            data.queryData.order_id = "";
            data.queryData.producer = "";
            data.queryData.customer = "";
            //清空表格复选框选中状态
            ctableRef.value.unSelectedRowKeys();
            toQueryList(true);
        }

        //渲染后 加载数据
        onMounted(() => {
            //加载表格数据
            toQueryList(true);

            nextTick(()=>{
                //延迟加载
                setTimeout(() =>{
                    if(store.state.user.isHeadBar){
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62;
                    }else{
                        //表格和翻页区域高度
                        data.styleTableHeight = "height:calc(100% - " + (queryRef.value.offsetHeight + btnRef.value.offsetHeight + 30 ) + "px)";
                        //表格内容滚动高度
                        data.tableHeight = queryRef.value.offsetHeight + btnRef.value.offsetHeight + 46 + 40 + 77 + 62 + 60;
                    }
                },1);
            })
        });

        //工具-时间格式化
        const getTime = (time) =>{
            return getYyyyddmm(time);
        }

        //订单详情对话框-取消
        const handleCloseOrderDetailsModal = ()=>{
            data.isOrderDetails = false;
            data.isOrderDetailsLoading = false;
            data.orderDetailsData={
                order_id:"",
                gmt_create:"",
                producer:"",
                customer:"",
                customer_phone:"",
                address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            }
            data.orderDetailsTableData=[]
        }

        //详情-导出PDF
        const handlePDF = ()=>{
            getPdf("orderDetails","订单")
        }

        //按钮-导出
        const orderOut = ()=>{
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                data.isOrderDetailsLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isOrderDetails = true;
                    //订单产品列表
                    data.orderDetailsTableData = res.data.orderProduct;
                    //订单数据
                    data.orderDetailsData.order_id = res.data.order.orderId;
                    data.orderDetailsData.gmt_create = res.data.order.gmtCreate;
                    data.orderDetailsData.producer  = res.data.order.producer || "";
                    data.orderDetailsData.customer = res.data.order.customer || "";
                    data.orderDetailsData.customer_phone = res.data.order.customerPhone || "";
                    data.orderDetailsData.address = res.data.order.address || "";
                    data.orderDetailsData.request = res.data.order.request || "";
                    data.orderDetailsData.remarks = res.data.order.remarks || "";
                    data.orderDetailsData.executive = res.data.order.executive;
                    if(data.orderDetailsData.executive){
                        data.orderDetailsData.executive_id = res.data.order.executiveId || "";
                        data.orderDetailsData.executive_name = res.data.order.executiveName || "";
                        // getSeal("executiveCanvas","福建亿奇新材料有限公司",data.orderDetailsData.executive_name);
                    }
                    
                    data.orderDetailsData.factory = res.data.order.factory;
                    if(data.orderDetailsData.factory){
                        data.orderDetailsData.factory_id = res.data.order.factoryId || "";
                        data.orderDetailsData.factory_name = res.data.order.factoryName || "";
                        // getSeal("factoryCanvas","福建亿奇新材料有限公司",data.orderDetailsData.factory_name);
                    }

                    data.orderDetailsData.chairman = res.data.order.chairman;
                    if(data.orderDetailsData.chairman){
                        data.orderDetailsData.chairman_id = res.data.order.chairmanId || "";
                        data.orderDetailsData.chairman_name = res.data.order.chairmanName || "";
                        // getSeal("factoryCanvas","福建亿奇新材料有限公司",data.orderDetailsData.chairman_name);
                    }
                    
                    data.isOrderDetailsLoading = false;
                }).catch((err) => {
                    data.isOrderDetailsLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //按钮-审核
        const orderReview = ()=>{
            //判断是否选择一条数据
            if(data.selectedRowKeys.length==1){
                data.isOrderReviewLoading = true;
                details({id:data.selectedRowKeys[0]}).then(res =>{
                    data.isOrderReview = true;
                    //订单产品列表
                    data.orderReviewTableData = res.data.orderProduct;
                    //订单数据
                    data.orderReviewData.order_id = res.data.order.orderId;
                    data.orderReviewData.gmt_create = res.data.order.gmtCreate;
                    data.orderReviewData.producer  = res.data.order.producer || "";
                    data.orderReviewData.customer = res.data.order.customer || "";
                    data.orderReviewData.customer_phone = res.data.order.customerPhone || "";
                    data.orderReviewData.address = res.data.order.address || "";
                    data.orderReviewData.request = res.data.order.request || "";
                    data.orderReviewData.remarks = res.data.order.remarks || "";
                    data.orderDetailsData.executive = res.data.order.executive;
                    if(data.orderDetailsData.executive){
                        data.orderDetailsData.executive_id = res.data.order.executiveId || "";
                        data.orderDetailsData.executive_name = res.data.order.executiveName || "";
                        // getSeal("executiveCanvas","福建亿奇新材料有限公司",data.orderDetailsData.executive_name);
                    }
                    
                    data.orderDetailsData.factory = res.data.order.factory;
                    if(data.orderDetailsData.factory){
                        data.orderDetailsData.factory_id = res.data.order.factoryId || "";
                        data.orderDetailsData.factory_name = res.data.order.factoryName || "";
                        // getSeal("factoryCanvas","福建亿奇新材料有限公司",data.orderDetailsData.factory_name);
                    }

                    data.orderDetailsData.chairman = res.data.order.chairman;
                    if(data.orderDetailsData.chairman){
                        data.orderDetailsData.chairman_id = res.data.order.chairmanId || "";
                        data.orderDetailsData.chairman_name = res.data.order.chairmanName || "";
                        // getSeal("factoryCanvas","福建亿奇新材料有限公司",data.orderDetailsData.chairman_name);
                    }
                    
                    data.isOrderReviewLoading = false;
                }).catch((err) => {
                    data.isOrderReviewLoading = false;
                });
            }else if(data.selectedRowKeys.length>1){
                message.warning("不可同时查询多条数据");
            }else{
                message.warning("请选择一条数据");
            }
        }

        //审核对话框-通过
        const handleReviewModal = ()=>{
            executiveReview({id:data.selectedRowKeys[0]}).then(res =>{
                message.success(res.data.sms);
                handleCloseReviewModal();
                resetTable();
            }).catch((err) => {
                data.isOrderReviewLoading = false;
            });
        }

        //审核对话框-报废
        const handleScrapModal = ()=>{
            scrapExecutive({id:data.selectedRowKeys[0]}).then(res =>{
                message.success(res.data.sms);
                handleCloseReviewModal();
                resetTable();
            }).catch((err) => {
                data.isOrderReviewLoading = false;
            });
        }

        //审核对话框-取消
        const handleCloseReviewModal = ()=>{
            data.isOrderReview=false;
            data.isOrderReviewLoading=false;
            data.orderReviewData={
                order_id:"",
                gmt_create:"",
                producer:"",
                customer:"",
                customer_phone:"",
                address:"",
                request:"",
                remarks:"",
                executive:false,
                executive_id:"",
                executive_name:"",
                factory:false,
                factory_id:"",
                factory_name:"",
                chairman:false,
                chairman_id:"",
                chairman_name:"",
            }
            data.orderReviewTableData=[];
        }
        

        return {
            setSortedInfo,
            columns,
            tableRef,
            btnRef,
            queryRef,
            htmlRef,
            ctableRef,
            data,
            getSelectedRowKeys,
            toQueryList,
            tData,
            resetTable,

            getTime,

            orderOut,
            handleCloseOrderDetailsModal,
            handlePDF,

            orderReview,
            handleCloseReviewModal,
            handleReviewModal,
            handleScrapModal,
            
        }
   }
}
</script>
<style lang='scss' scoped>
.html{
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.query{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px 0 20px;
}
.btn{
    width: calc(100% - 40px);
    height: auto;
    margin: 10px 20px;
}
.btn_style{
    margin-right: 12px;
}
.table{
    width: calc(100% - 40px);
    // height: calc(100% - 102px);
    margin-left: 20px;
}
.detailsCol{
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right;
}
.addTable{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.STYLE7 {font-size: 16px; font-family: "宋体"; }
.STYLE8 {font-size: 15px; font-family: "宋体"; }
.td1{
	width:172px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td2{
	width:106px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:center;
}
.td3{
	width:100%;
    font-size: 16px;
    text-align: left;
    padding-left:5px;
	word-wrap:break-word;
	word-break:break-all;
	text-align:left;
}
.detailsTitle{
    width: 100%;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 30px;
}
</style>