/**
 * 判断是否 空 und null
 */
export function isOK(value) {
    if(value!==null && typeof(value)!=="undefined" && value!==""){
        return true;
    }else{
        return false;
    }
}

/**
 * 加密
 * @param {*} value 
 */
 export function isEncryption(value) {
    var jia = ['1980','1981','1990','2000','2002','wawa','bog','abc','!','.','wang','zhang','yu','520','123456','123','12345','__','111','123123','1234','password'];
    var x = parseInt(Math.random()*jia.length);
    var jie = 0;//用于解密 从第几位忽略
    for(var i = 0;i<jia.length;i++){
        if(i==x){
            value = value + jia[i] + jia[i].length;
        }
    }
    return value;
}