<template>
    <div>信息管理</div>
</template>

<script>
export default {
   name: "NewsIndex",
   components: {},
   props: {},
   
}
</script>
<style lang='scss' scoped>
</style>