<template>
    <div class="logo">
        <img src="@/assets/logo.png">
        <h1>资源管理平台</h1>
    </div>
    <a-menu
        class="aside-menu"
        v-model:openKeys="data.openKeys"
        v-model:selectedKeys="data.selectedKeys"
        mode="inline"
        theme="dark"
        @click="selectMenu"
        @openChange="openMenu"
    >
        <template v-for="item in rotuers">
            <template v-if="!item.hidden">
                <!--这里是一级-->
                <a-menu-item v-if="hasOnlyChildren(item)" :key="item.children[0].path">
                    <router-link :to="item.children[0].path">
                        <span class="anticon">
                            <svg-icon :iconName="item.meta && item.meta.icon" className="aside-svg"></svg-icon>
                        </span>
                        <span>{{ item.children[0].meta && item.children[0].meta.title }}</span>
                    </router-link>
                </a-menu-item>
                
                <!--这里是子级-->
                <Menu v-else :menu="item" :key="item.path" />
            </template>
        </template>
    </a-menu>
</template>
<script>
import { reactive,watch } from "vue";
import { useStore } from 'vuex'
// 路由
import { useRouter } from "vue-router";
// 组件
import Menu from "./Menu";
export default {
    name: "Aside",
    components: { Menu },
    props: {
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    setup(){
        const store = useStore();
        const { options } = useRouter();
        // 路由
        const rotuers = options.routes;
        // 数据
        const data = reactive({
            selectedKeys: localStorage.getItem("selectedKeys") ? [localStorage.getItem("selectedKeys")] : [],
            openKeys: localStorage.getItem("openKeys") ? JSON.parse(localStorage.getItem("openKeys")) : [],
        })

        //这里监控 是否点击了tab
        watch(() => store.state.user.tabSelected, (newValue, oldValue) => {
            data.selectedKeys = [newValue];
            localStorage.setItem("selectedKeys", newValue) // 设置
        })

        // 选择菜单
        const selectMenu = ({ item, key, keyPath }) => {
            console.log(key+"123123123")
            data.selectedKeys = [key];
            localStorage.setItem("selectedKeys", key) // 设置
        }
        // 展开/关闭菜单
        const openMenu = (openKeys) => {
            data.openKeys = openKeys;
            localStorage.setItem("openKeys", JSON.stringify(openKeys)) // 设置
        }
        //检测是否只有一个只路由
        const hasOnlyChildren = (data) =>{
            //不存在子级
            if(!data.children){
                return false; 
            }
            const routers = data.children.filter(item => item.hidden ? false : true);
            //判断最终长度
            if(routers.length === 1){
                return true;
            }
        }

        return {
            data,
            rotuers,
            selectMenu,
            openMenu,
            hasOnlyChildren
        }
    }
}
</script>
<style lang="scss" scoped>
// #logo {
//     padding: 24px 0 20px;
//     border-bottom: 1px solid #000;
//     text-align: center;
//     img { display: inline-block; }
// }
.logo{
    height: 60px;
    position: relative;
    line-height: 60px;
    padding-left: 24px;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow: hidden;
    background-color: #002140;
    &.light{
      background-color: #fff;
      h1{
        color: #fff;
      }
    }
    h1{
      color:#fff;
      font-size: 22px;
      margin: 0 0 0 12px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 2px;
    }
    img{
      width: 30px;
      vertical-align: middle;
    }
}
.aside-menu {
    i.icon { 
        margin-right: 5px;
        opacity: .6;
    }
    .ant-menu-item-selected {
        i.icon { opacity: 1; }
    }
}
</style>