<template>
    <div>404</div>
</template>

<script>
export default {
   name: "error404",
   components: {},
   props: {}
}
</script>
<style lang='scss' scoped>
</style>