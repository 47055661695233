import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/order/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//详情
export function details(data){
    return service.request({
        url:"/order/details",
        method: "post",
        data:Qs.stringify(data),
    });
}

//使用订单编号（order_id）获取订单详情
export function orderIdTodetails(data){
    return service.request({
        url:"/order/orderIdTodetails",
        method: "post",
        data:Qs.stringify(data),
    });
}

//制单
export function add(data){
    return service.request({
        url:"/order/add",
        method: "post",
        contentType: "application/json",
        data
    });
}

//报废
export function scrap(data){
    return service.request({
        url:"/order/scrap",
        method: "post",
        data:Qs.stringify(data),
    });
}

//质检是修改订单预计生产数量
export function upOneOrderProductplanQuantities(data){
    return service.request({
        url:"/order/upOneOrderProductplanQuantities",
        method: "post",
        data:Qs.stringify(data),
    });
}