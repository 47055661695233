import service from "@/utils/request.js"
import Qs from 'qs'

//分页查询
export function getList(data){
    return service.request({
        url:"/rawMaterialIoWarehouse/getList",
        method: "post",
        data:Qs.stringify(data),
    });
}

//新增 后端使用实体类对象接收
export function add(data){
    return service.request({
        url:"/rawMaterialIoWarehouse/add",
        contentType: "application/json",
        method: "post",
        data:data,
    });
}

//撤回
export function back(data){
    return service.request({
        url:"/rawMaterialIoWarehouse/back",
        method: "post",
        data:Qs.stringify(data),
    });
}